import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Constants from '../shared/constants';
import { Redirect } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import AuthService from '../services/AuthService';
import Config from '../config';
import CircledInput from '../components/CircledInput';
import { Control, ValidationRule } from '../shared/Control';
import validator from 'validator';
import LoadingOverlay from 'react-loading-overlay';
import BounceLoader from 'react-spinners/BounceLoader';

class SignIn extends Component {
  authService = new AuthService(Config.apiBase);

  state = {
    signInDetails: {
      isValid: false,
    },
    errorMessage: false,
    loading: false,
  };

  controls = {
    signInDetails: [
      new Control({
        name: 'name',
        type: 'email',
        label: <FormattedMessage id="ph_email" />,
        autoFocus: true,
        placeholder: Constants.language.user_name,
        rule: new ValidationRule({
          method: validator.isEmpty,
          validWhen: false,
        }),
      }),
      new Control({
        name: 'password',
        type: 'password',
        label: <FormattedMessage id="user_password" />,
        placeholder: Constants.language.user_password,
        rule: new ValidationRule({
          method: validator.isEmpty,
          validWhen: false,
        }),
      }),
    ],
  };

  handlesignInDetailsChange = (name, value) => {
    this.setState({
      signInDetails: {
        isValid: this.controls.signInDetails.find(obj => obj.rule && !obj.isValid) === undefined,
      },
    });
  };

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleSubmit = async event => {
    //const { errorMessage } = this.state
    event.preventDefault();
    var emailControl = this.controls.signInDetails[0];
    var passwordControl = this.controls.signInDetails[1];

    if (!emailControl.isValid || !passwordControl.isValid) {
      this.setState({
        errorMessage: <FormattedMessage id="home_signin_error_message" />,
      });
      return;
    }

    try {
      this.setState({ loading: true, errorMessage: null });
      const response = await this.authService.signIn({
        email: emailControl.value.toLowerCase(),
        password: passwordControl.value,
      });
      this.setState({ loading: false });
      if (response.status === 200) {
        await this.authService.usersDetails();
        this.props.history.push(Constants.routes.home.url);
      }
    } catch (e) {
      this.setState({
        errorMessage: <FormattedMessage id="home_signin_error_message" />,
      });
      this.setState({ loading: false });
    }
  };
  validateForm = () => {
    return this.state.email.length > 0 && this.state.password.length > 0 && validator.isEmail(this.state.email);
  };

  render() {
    if (this.authService.isAuthenticated()) {
      return <Redirect to="/dashboard" />;
    }

    const { errorMessage, loading } = this.state;
    return (
      <LoadingOverlay
        active={loading}
        spinner={
          <BounceLoader
            color={Constants.LOADER_PROPS.COLOR.white}
            size={Constants.LOADER_PROPS.SIZE}
            sizeUnit={Constants.LOADER_PROPS.SIZE_UNIT}
            loading={loading}
          />
        }
        classNamePrefix={'login-page_'}
        text={<FormattedMessage id="common_loading_overlay" />}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-5">
              <h1 className="text-white fs-60 text-center font-weight-light mb-3 opacity-0-8">
                <FormattedMessage id="common_login" />
              </h1>
              <h6 className="text-white text-center font-weight-normal mb-2 pb-4 opacity-0-8 line-height-28">
                <FormattedMessage id="home_request_password" />
              </h6>
              <form onSubmit={this.handleSubmit} className="d-flex flex-column align-items-center">
                {this.controls.signInDetails.map(control => (
                  <CircledInput
                    key={control.name}
                    options={control}
                    onChange={this.handlesignInDetailsChange}
                    error={errorMessage}
                  />
                ))}
                <div className="text-center mt-2 pt-2 mb-3 pb-4 w-378-max w-100 ">
                  <button type="submit" className="btn btn-primary font-weight-normal h-44">
                    <FormattedMessage id="common_sign_in_header" />
                  </button>
                </div>
                <div className="text-center mt-3 pb-1 mb-5 opacity-0-8 line-height-20">
                  <p className="mb-0">
                    <FormattedMessage id="home_forgot_password" />{' '}
                    <u>
                      <Link to={Constants.routes.forgotPassword}>
                        <FormattedMessage id="home_get_new_password" />
                      </Link>
                    </u>
                  </p>
                </div>
              </form>
            </div>
          </div>
          <div className="justify-content-center row opacity-0-8">
            <div className="text-center mt-4 pt-1 pb-1 col-6">
              <p className="mb-0 line-height-20">
                <FormattedMessage id="ph_create_new_user_info" />
              </p>
            </div>
            <div className="col-12">
              <div className="text-center mt-3">
                <p className="line-height-20">
                  <span className="px-1">
                    <FormattedMessage id="home_become_reseller" />{' '}
                  </span>
                  <a href="https://rackit.no/kontakt" target="_blank" rel="noopener noreferrer" className="dashbtn">
                    <u>
                      <FormattedMessage id="home_contact_us" />
                    </u>
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </LoadingOverlay>
    );
  }
}

export default SignIn;
