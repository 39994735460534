import React, { Component, useState } from 'react';
import moment from 'moment';
import Slider from 'rc-slider';
import PropTypes from 'prop-types';
import 'rc-slider/assets/index.css';
import { FormattedMessage } from 'react-intl';
import { CheckBox } from '../CheckBox/CheckBox';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import { HeadCaps } from '../HeadCaps/HeadCaps';
import Constants from '../../shared/constants';

// const { createSliderWithTooltip } = Slider;
// const Range = createSliderWithTooltip(Slider.Range);
const Range = Slider.Range;

class RangeSlider extends Component {
  constructor(props) {
    super(props);
    const { placeholderValues, defaultValues } = this.props.option;
    this.state = { values: defaultValues.length > 0 ? defaultValues : placeholderValues };
  }

  handleSliderChange = value => {
    this.setState({ values: value });
  };

  handleAfterChange = value => {
    const { onSliderUpdated } = this.props;
    onSliderUpdated(value);
  };

  render() {
    const { min, max } = this.props.option;
    const { values } = this.state;
    return (
      <Range
        value={values}
        min={min}
        max={max}
        railStyle={{ backgroundColor: '#f2f2f2' }}
        trackStyle={{ backgroundColor: '#00897b' }}
        className="order-filter-class"
        dotStyle={{
          backgroundColor: '#ffffff',
          borderColor: 'rgba(0, 0, 0, 0.2)',
        }}
        onChange={this.handleSliderChange}
        onAfterChange={this.handleAfterChange}
        allowCross={false}
      />
    );
  }
}

export class FilterPopOver extends Component {
  constructor(props) {
    super(props);
    this.state = {
      optionsData: JSON.parse(JSON.stringify(props.options)),
      optionsDataCopy: JSON.parse(JSON.stringify(props.options)),
      selectedData: [],
      toDateErr: true,
      fromDateErr: true,
      fromActDateErr: true,
      toActDateErr: true,
      dateName: {
        date: 'date',
        registereddate: 'registeredDate',
        activatedate: 'activationDate',
        ActivationDateLabel: 'Activation Date',
        AktiveringsdatoLabel: 'Aktiveringsdato',
        RegisteredDateLabel: 'Registered Date',
        RegdatoLabel: 'Reg.dato',
        OrderDateLabel: 'Order Date',
        BestillingsdatoLabel: 'Bestillingsdato',
        datePicker: 'datePicker',
        rangeSlider: 'rangeSlider',
      },
    };
    this.defaultValue = props.options;

    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    let { optionsData, selectedData } = this.state;

    selectedData = this.onFilterSelectedData({ optionsData });
    this.setState({ selectedData });
    document.addEventListener('mousedown', this.handleClickOutside);
  }
  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }
  /**
   * Alert if clicked on outside of element
   */
  handleClickOutside(event) {
    var filterPopOver = this.findClosest(event.target, function(el) {
      return el.id == 'filter-pop-over-feature';
    });
    var filterButton = this.findClosest(event.target, function(el) {
      return el.id == 'filter-button';
    });

    if (filterPopOver === undefined && this.props.isOpen && filterButton === undefined) {
      this.handleOnCancel();
    }
  }
  findClosest(element, fn) {
    if (!element) return undefined;
    return fn(element) ? element : this.findClosest(element.parentElement, fn);
  }
  componentWillReceiveProps(nextProps) {
    let { selectedData } = this.state;
    const { searchData, options } = this.props;

    if (nextProps.options && options !== nextProps.options) {
      this.setState({
        optionsData: JSON.parse(JSON.stringify(nextProps.options)),
        optionsDataCopy: JSON.parse(JSON.stringify(nextProps.options)),
      });
      let optionsData = JSON.parse(JSON.stringify(nextProps.options));
      selectedData = this.onFilterSelectedData({ optionsData });
      this.setState({ selectedData });
    }
    if (nextProps.searchData && nextProps.searchData.value !== searchData.value) {
      if (nextProps.searchData) {
        let filterSearch = nextProps.searchData;

        selectedData = selectedData.filter(obj => obj.component !== filterSearch.component);
        if (filterSearch.value.length > 0) {
          selectedData.push({
            id: filterSearch.id,
            value: filterSearch.value,
            component: filterSearch.component,
          });
        }
        this.setState({ selectedData });
      }
    }
  }
  onFilterSelectedData = ({ optionsData }) => {
    const selectedData = [];
    optionsData &&
      optionsData.map(item => {
        if (
          item.component !== Constants.filterOptions.components.checkBox &&
          item.component !== Constants.filterOptions.components.datePicker
        ) {
          item.defaultValues.map(obj => {
            selectedData.push({
              id: item.id,
              value: obj,
              component: item.component,
              label: item.label,
            });
            return obj;
          });
        } else if (item.component === Constants.filterOptions.components.checkBox) {
          item.groups.map(group => {
            group.defaultValues.map(value => {
              if (value !== Constants.language.filter_select_all) {
                selectedData.push({
                  id: item.id,
                  value: value,
                  component: item.component,
                });
              }
              return value;
            });
            return group;
          });
        } else if (item.component === Constants.filterOptions.components.datePicker) {
          if (item.fromDate.defaultDate) {
            selectedData.push({
              id: item.id,
              value: moment(item.fromDate.defaultDate).format('YYYY-MM-DD'),
              component: item.component,
            });
          }
          if (item.toDate.defaultDate) {
            selectedData.push({
              id: item.id,
              value: moment(item.toDate.defaultDate).format('YYYY-MM-DD'),
              component: item.component,
            });
          }
        }
        return item;
      });
    return selectedData;
  };

  handleLetterSelect = ({ id, value }, e) => {
    const { optionsData } = this.state;
    optionsData.map(option => {
      if (option.id === id) {
        const index = option.defaultValues.indexOf(value);
        if (index > -1) {
          option.defaultValues.splice(index, 1);
        } else {
          option.defaultValues.push(value);
        }
      }
      return option;
    });
    this.setState({ optionsData });
  };

  handleCheckBoxValues = (id, value) => {
    const { optionsData } = this.state;
    optionsData.map(option => {
      if (option.component === Constants.filterOptions.components.checkBox) {
        option.groups.map(group => {
          if (group.id === id) {
            if (value === Constants.language.filter_select_all) {
              if (group.defaultValues.length > 0) {
                group.defaultValues = [];
              } else {
                group.defaultValues = [...group.values];
              }
            } else {
              if (group.defaultValues.includes(Constants.language.filter_select_all)) {
                group.defaultValues.splice(0, 1);
              }

              const index = group.defaultValues.indexOf(value);
              if (index > -1) {
                group.defaultValues.splice(index, 1);
              } else {
                group.defaultValues.push(value);
              }
            }
          }
          return group;
        });
      }
      return option;
    });
    this.setState({ optionsData });
  };

  handleOnStartDate = ({ id }, date) => {
    const { optionsData, fromDateErr, toDateErr, dateName, toActDateErr, fromActDateErr } = this.state;
    optionsData.map(option => {
      if (option.component === Constants.filterOptions.components.datePicker && option.id === id) {
        option.fromDate.defaultDate = date;
      }
      return option;
    });
    this.setState({ optionsData });
  };

  handleOnEndDate = ({ id }, date) => {
    const { optionsData, dateName, toDateErr, fromDateErr, fromActDateErr, toActDateErr } = this.state;
    optionsData.map(option => {
      if (option.component === Constants.filterOptions.components.datePicker && option.id === id) {
        option.toDate.defaultDate = date;
      }
      return option;
    });
    this.setState({ optionsData });
  };
  handletoDateError() {
    const { optionsData, fromDateErr, toDateErr, dateName, toActDateErr, fromActDateErr } = this.state;
    return optionsData
      .filter(obj => obj.id === dateName.date || obj.id === dateName.registereddate)
      .map(item => {
        if (item.toDate && item.toDate.defaultDate) {
          if (item.fromDate && item.fromDate.defaultDate) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      });
  }
  handleFromDateError() {
    const { optionsData, fromDateErr, toDateErr, dateName, toActDateErr, fromActDateErr } = this.state;
    return optionsData
      .filter(obj => obj.id === dateName.date || obj.id === dateName.registereddate)
      .map(item => {
        if (item.fromDate && item.fromDate.defaultDate) {
          if (item.toDate && item.toDate.defaultDate) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      });
  }
  handleFromActivationDateError() {
    const { optionsData, fromDateErr, toDateErr, dateName, toActDateErr, fromActDateErr } = this.state;
    return optionsData
      .filter(obj => obj.id === dateName.activatedate)
      .map(item => {
        if (item.fromDate && item.fromDate.defaultDate) {
          if (item.toDate && item.toDate.defaultDate) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      });
  }
  handleToActivationDateError() {
    const { optionsData, fromDateErr, toDateErr, dateName, toActDateErr, fromActDateErr } = this.state;
    return optionsData
      .filter(obj => obj.id === dateName.activatedate)
      .map(item => {
        if (item.toDate && item.toDate.defaultDate) {
          if (item.fromDate && item.fromDate.defaultDate) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      });
  }
  handleOnClearFilters = () => {
    this.handleRemoveFilter({ value: {} });
  };

  handleOnApplyFilters = () => {
    let { optionsData, selectedData, dateName, toDateErr, fromDateErr, fromActDateErr, toActDateErr } = this.state;
    const { onApply, searchData } = this.props;
    const currentData = JSON.parse(JSON.stringify(optionsData));
    selectedData = this.onFilterSelectedData({ optionsData });
    selectedData.filter(obj => obj.component !== searchData.component);
    if (searchData.value.length > 0) {
      selectedData.push({
        id: searchData.id,
        value: searchData.value,
        component: searchData.component,
      });
    }
    this.setState({ selectedData, optionsDataCopy: currentData });
    this.handletoDateError();
    this.handleFromDateError();
    toDateErr = this.handleFromDateError()[0];
    fromDateErr = this.handletoDateError()[0];
    fromActDateErr = this.handleToActivationDateError()[0];
    if (fromDateErr === undefined) {
      fromDateErr = true;
    }
    if (toDateErr === undefined) {
      toDateErr = true;
    }
    if (fromActDateErr === undefined) {
      fromActDateErr = true;
    }
    toActDateErr = this.handleFromActivationDateError()[0];
    if (toActDateErr === undefined) {
      toActDateErr = true;
    }
    this.setState({ toDateErr, fromDateErr, toActDateErr, fromActDateErr });
    if (toDateErr && fromDateErr && toActDateErr && fromActDateErr) {
      onApply(selectedData);
      this.setState({ toDateErr: true, fromDateErr: true, toActDateErr: true, fromActDateErr: true });
    }
  };

  handleRemoveFilter = ({ value: { value, id, component } }) => {
    const { selectedData, optionsData, fromActDateErr, toActDateErr, toDateErr, fromDateErr, dateName } = this.state;
    const { onApply, onClearSearch, searchQuery } = this.props;
    let filterData;
    if (value) {
      if (
        component === Constants.filterOptions.components.datePicker ||
        component === Constants.filterOptions.components.rangeSlider
      ) {
        filterData = selectedData.filter(obj => obj.id !== id);
      } else {
        filterData = selectedData.filter(obj => obj.value !== value);
      }
    }
    if (component === Constants.filterOptions.components.search) {
      onClearSearch();
      return;
    }
    optionsData.map(item => {
      if (
        item.component !== Constants.filterOptions.components.checkBox &&
        item.component !== Constants.filterOptions.components.datePicker &&
        item.component !== Constants.filterOptions.components.rangeSlider
      ) {
        if (value) {
          const index = item.defaultValues.indexOf(value);
          if (index !== -1) {
            item.defaultValues.splice(index, 1);
          }
        } else {
          item.defaultValues = [];
        }
      } else if (item.component === Constants.filterOptions.components.checkBox) {
        item.groups.map(group => {
          if (value) {
            const index = group.defaultValues.indexOf(value);
            if (index !== -1) {
              group.defaultValues.splice(index, 1);
            }
          } else {
            group.defaultValues = [];
          }
          return group;
        });
      } else if (item.component === Constants.filterOptions.components.datePicker && item.id === id) {
        item.fromDate.defaultDate = '';
        item.toDate.defaultDate = '';
      } else if (!id && item.component === Constants.filterOptions.components.datePicker) {
        item.fromDate.defaultDate = '';
        item.toDate.defaultDate = '';
      } else if (item.component === Constants.filterOptions.components.rangeSlider) {
        if (!value) {
          item.defaultValues = [];
        }
      }
      return item;
    });

    const rangeSliderIndex = optionsData.findIndex(
      obj => obj.component === Constants.filterOptions.components.rangeSlider
    );
    if (rangeSliderIndex > 0) {
      optionsData[rangeSliderIndex].defaultValues.length = 0;
    }

    if (value) {
      this.setState({
        selectedData: filterData,
        optionsData,
      });
      onApply(filterData);
    } else {
      this.setState({ optionsData });
    }
    this.setState({ toDateErr: true, fromDateErr: true, fromActDateErr: true, toActDateErr: true });
  };

  handleOnCancel = () => {
    const { optionsDataCopy } = this.state;
    const previousData = JSON.parse(JSON.stringify(optionsDataCopy));
    this.setState({
      optionsData: previousData,
      toDateErr: true,
      fromDateErr: true,
      toActDateErr: true,
      fromActDateErr: true,
    });
    this.props.onCancel();
  };

  renderLetterSelect({ option }) {
    return option.values.map((value, index) => (
      <span
        key={index}
        className={
          'filter-cust-letter ' +
          (index === 0 && 'ml-0 ') +
          (option.defaultValues.includes(value) && ' filter-cust-highlight')
        }
        onClick={this.handleLetterSelect.bind(this, { id: option.id, value })}
      >
        {value}
      </span>
    ));
  }

  renderCheckBoxes({ option }) {
    return option.groups.map(group => (
      <React.Fragment key={group.label + Math.random() * 100}>
        <div className="col-2">
          <div className="new-order-filter-head mb-0">{group.label}</div>
        </div>
        <div className="col">
          <div className="row">
            {group.values.map(value => (
              <div className="col-3" key={value + Math.random() * 100}>
                <div className="row align-items-center mb-1">
                  <div className="col-2">
                    <CheckBox
                      isSelected={group.defaultValues.includes(value)}
                      onClick={this.handleCheckBoxValues.bind(this, group.id, value)}
                    />
                  </div>
                  <div className="col">
                    <span className="new-order-filter-item">{Constants.filterOptions.label[value] || value}</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </React.Fragment>
    ));
  }

  renderCheckBoxMulti({ option }) {
    return option.groups.map(group => (
      <div className="col-3" key={group.label + Math.random() * 100}>
        <div className="new-order-filter-head">{group.label}</div>
        <div className="col">
          {group.values.map(value => (
            <div className="row align-items-center mb-1" key={Math.random() * 100}>
              <div className="col-2 pl-0">
                <CheckBox
                  isSelected={group.defaultValues.includes(value)}
                  onClick={this.handleCheckBoxValues.bind(this, group.id, value)}
                />
              </div>
              <div className="col pl-0">
                <span className="new-order-filter-item">{value}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    ));
  }

  renderHeadCaps = ({ selectedData }) => {
    const { optionsDataCopy, dateName } = this.state;
    const rangeSliderOptions = optionsDataCopy.filter(obj => obj.component === dateName.rangeSlider);
    let headCapsData = [...selectedData];

    const rangeSliderList = headCapsData.filter(obj => obj.component === dateName.rangeSlider);
    const datePicker = headCapsData.filter(obj => obj.component === dateName.datePicker);

    const others = headCapsData.filter(
      obj => obj.component !== dateName.rangeSlider && obj.component !== dateName.datePicker
    );
    if (rangeSliderList && rangeSliderList.length > 0) {
      if (rangeSliderList[1].value === rangeSliderOptions[0].max) {
        others.push({
          ...rangeSliderList[0],
          value: rangeSliderList[0].value + '+',
        });
      } else {
        others.push({
          ...rangeSliderList[0],
          value: rangeSliderList
            .map(obj => (obj.value === rangeSliderOptions[0].max ? rangeSliderOptions[0].max + '+' : obj.value))
            .join(' - '),
        });
      }
    }
    if (datePicker && datePicker.length > 0) {
      if (datePicker && datePicker.length > 3) {
        others.push({ ...datePicker[0], value: datePicker[0].value + '-' + datePicker[1].value });
        others.push({ ...datePicker[2], value: datePicker[2].value + '-' + datePicker[3].value });
      } else if (datePicker && datePicker.length > 1) {
        others.push({ ...datePicker[0], value: datePicker.map(obj => obj.value).join(' - ') });
      } else {
      }
    }
    return others.map(obj => (
      <HeadCaps
        key={obj.value}
        text={Constants.filterOptions.label[obj.value] || obj.value}
        onClickIcon={this.handleRemoveFilter.bind(this, { value: obj })}
      />
    ));
  };

  handlePriceChange = (index, price) => {
    let { optionsData } = this.state;
    optionsData[index].defaultValues = price;
    this.setState({ optionsData });
  };

  render() {
    const { optionsData, selectedData, dateName } = this.state;
    const { isOpen, containerClass, forBasket, searchQuery } = this.props;
    return (
      <>
        {isOpen && (
          <div className={'filter-pop-over-container ' + containerClass} id="filter-pop-over-feature" tabIndex="-1">
            {optionsData.map((option, index) => (
              <React.Fragment key={Math.random() * 100}>
                {option.component === 'letterSelect' && (
                  <div className="row pb-3 mb-2 border-bottom">
                    <div className="col-2">
                      <div className="new-order-filter-head mb-0">{option.label}</div>
                    </div>
                    <div className="col">{this.renderLetterSelect({ option })}</div>
                  </div>
                )}
                {option.component === Constants.filterOptions.components.checkBox && !option.multiGroup && (
                  <>
                    <div className="row pb-3 mb-2 border-bottom">{this.renderCheckBoxes({ option })}</div>
                  </>
                )}
                {option.component === Constants.filterOptions.components.checkBox && option.multiGroup && (
                  <div className="row pb-4 border-bottom">{this.renderCheckBoxMulti({ option })}</div>
                )}
                {option.component === Constants.filterOptions.components.datePicker && (
                  <>
                    <div className="row pb-3 mb-2 border-bottom">
                      <div className="col-2 pr-0">
                        <div className="new-order-filter-head mb-0">{option.label}</div>
                      </div>
                      <div className="row align-items-center">
                        {option.fromDate && (
                          <div className="col mx-3">
                            <div className="row d-inline-block date-picker-holder">
                              <label className="mb-0 filter-date-picker">
                                <DatePicker
                                  onChange={this.handleOnStartDate.bind(this, {
                                    id: option.id,
                                  })}
                                  maxDate={new Date()}
                                  dateFormat="dd/MM/yyyy"
                                  selected={
                                    option.fromDate.defaultDate === ''
                                      ? ''
                                      : typeof option.fromDate.defaultDate === 'string'
                                      ? moment(option.fromDate.defaultDate, 'YYYY-MM-DD').toDate()
                                      : option.fromDate.defaultDate
                                  }
                                  popperPlacement="top-start"
                                  popperModifiers={{
                                    flip: { enabled: false },
                                    preventOverflow: { enabled: true, escapeWithReference: false },
                                  }}
                                />
                                <i className="ml-2 fa fa-calendar filter-date-picker-icon" aria-hidden="true" />
                              </label>
                            </div>
                            {!this.state.fromDateErr &&
                              (option.label === dateName.RegdatoLabel ||
                                option.label === dateName.BestillingsdatoLabel ||
                                option.label === dateName.RegisteredDateLabel ||
                                option.label === dateName.OrderDateLabel) && (
                                <div className="row col p-0 text-danger error-message small mt-0">
                                  <FormattedMessage id="filter_warning_date" />
                                </div>
                              )}
                            {!this.state.fromActDateErr &&
                              (option.label === dateName.AktiveringsdatoLabel ||
                                option.label === dateName.ActivationDateLabel) && (
                                <div className="row col p-0 text-danger error-message small mt-0">
                                  <FormattedMessage id="filter_warning_date" />
                                </div>
                              )}
                            {!this.state.toDateErr &&
                              option.label !== dateName.AktiveringsdatoLabel &&
                              option.label !== dateName.ActivationDateLabel && <div className="row pt-4 mt-1" />}
                            {!this.state.toActDateErr &&
                              (option.label === dateName.AktiveringsdatoLabel ||
                                option.label === dateName.ActivationDateLabel) && <div className="row pt-4 mt-1" />}
                          </div>
                        )}
                        {option.toDate && (
                          <div className="col">
                            <div className="row d-inline-block date-picker-holder">
                              <label className="mb-0 filter-date-picker">
                                <DatePicker
                                  onChange={this.handleOnEndDate.bind(this, {
                                    id: option.id,
                                  })}
                                  minDate={option.fromDate.defaultDate}
                                  maxDate={new Date()}
                                  dateFormat="dd/MM/yyyy"
                                  selected={
                                    option.toDate.defaultDate === ''
                                      ? ''
                                      : typeof option.toDate.defaultDate === 'string'
                                      ? moment(option.toDate.defaultDate, 'YYYY-MM-DD').toDate()
                                      : option.toDate.defaultDate
                                  }
                                  popperPlacement="top-start"
                                  popperModifiers={{
                                    flip: { enabled: false },
                                    preventOverflow: { enabled: true, escapeWithReference: false },
                                  }}
                                />
                                <i className="ml-2 fa fa-calendar filter-date-picker-icon" aria-hidden="true" />
                              </label>
                            </div>
                            {!this.state.toDateErr &&
                              (option.label === dateName.RegdatoLabel ||
                                option.label === dateName.BestillingsdatoLabel ||
                                option.label === dateName.OrderDateLabel ||
                                option.label === dateName.RegisteredDateLabel) && (
                                <div className="row col p-0 text-danger error-message small mt-0">
                                  <FormattedMessage id="filter_warning_date" />
                                </div>
                              )}
                            {!this.state.toActDateErr &&
                              (option.label === dateName.AktiveringsdatoLabel ||
                                option.label === dateName.ActivationDateLabel) && (
                                <div className="row col p-0 text-danger error-message small mt-0">
                                  <FormattedMessage id="filter_warning_date" />
                                </div>
                              )}
                            {!this.state.fromDateErr &&
                              (option.label !== dateName.AktiveringsdatoLabel &&
                                option.label !== dateName.ActivationDateLabel) && <div className="row pt-4 mt-1" />}
                            {!this.state.fromActDateErr &&
                              (option.label === dateName.AktiveringsdatoLabel ||
                                option.label === dateName.ActivationDateLabel) && <div className="row pt-4 mt-1" />}
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                )}
                {option.component === Constants.filterOptions.components.rangeSlider && (
                  <div className="row pb-3 mb-2 border-bottom range-filter-container">
                    <div className="col-2">
                      <div className="new-order-filter-head mb-0">{option.label}</div>
                    </div>
                    <div className="col-4">
                      <span className="tootltip-value-1">{option.defaultValues[0] || option.placeholderValues[0]}</span>
                      <RangeSlider option={option} onSliderUpdated={this.handlePriceChange.bind(this, index)} />
                      <span className="tootltip-value-2">
                        {option.defaultValues[1] === option.max
                          ? option.max + '+'
                          : option.defaultValues[1] || option.max + '+'}
                      </span>
                    </div>
                  </div>
                )}
              </React.Fragment>
            ))}
            <div className="pt-3">
              <div className="row justify-content-between">
                <div className="clear-filter-text">
                  <span onClick={this.handleOnClearFilters}>
                    <FormattedMessage id="filter_clear_all" />
                  </span>
                </div>
                <div>
                  <span className="action-text-filter cancel-text-color mr-4" onClick={this.handleOnCancel}>
                    <FormattedMessage id="contract_button_cancel_service" />
                  </span>
                  <span className="action-text-filter" onClick={this.handleOnApplyFilters}>
                    <FormattedMessage id="common_apply" />
                  </span>
                </div>
              </div>
            </div>
          </div>
        )}
        {selectedData.length > 0 && (
          <div className={'row filter-buttons-tabs mb-4 mx-0' + (forBasket ? ' ml-0' : '')}>
            {this.renderHeadCaps({ selectedData })}
          </div>
        )}
      </>
    );
  }
}

FilterPopOver.propTypes = {
  isOpen: PropTypes.bool,
  containerClass: PropTypes.string,
  onCancel: PropTypes.func,
  onApply: PropTypes.func,
};
