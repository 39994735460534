import React from 'react';
import LoadingOverlay from 'react-loading-overlay';
import BounceLoader from 'react-spinners/BounceLoader';
import validator from 'validator';
import { FormattedMessage } from 'react-intl';

import { connect } from 'react-redux';

import moment from 'moment';

import Constants from '../../shared/constants';
import ContractDetailsHead from './Components/ContractDetailsHead';
import ContractDetailsTab from './Components/ContractDetailsTab';
import ContractsActionButtons from './Components/ContractsActionButtons';
import ContractServicesCard from './Components/ContractServicesCard';
import { Control, ValidationRule } from '../../shared/Control';
import ServiceOrders from './Tabs/ServiceOrders';
import ServiceInvoice from './Tabs/ServiceInvoice';
import ServiceActivityLog from './Tabs/ServiceActivityLog';
import EditContractDetailsModal from './Components/ModalView/EditContractDetailsModal';
import BaseComponent from '../BaseComponent';
import ContractService from '../../services/ContractService';
import CommonModalView from './Components/ModalView/CommonModalView';
import CustomerService from '../../services/CustomerService';
import Config from '../../config';
import AuthService from '../../services/AuthService';
import Toast from '../../components/Toast/index';

import {
  contractActionLoader,
  contractActionError,
  contractModalTypeSelection,
  contractSubscriptionUpdate,
  contractModalDateChange,
  contractModalSuccesModalType,
} from '../../state/actions/ContractActions';

class ContractDetails extends BaseComponent {
  contractService = new ContractService(Config.apiBase);
  customerService = new CustomerService(Config.apiBase);
  authService = new AuthService(Config.apiBase);

  state = {
    tab: 'Services',
    customerSection: {
      isValid: true,
      isFocus: false,
    },
    resellerSection: {
      isValid: true,
      isFocus: false,
    },
    addressSection: {
      isValid: true,
      isFocus: false,
    },
    contactSection: {
      isValid: true,
      isFocus: false,
    },
    installationEdit: false,
    // selectedContractDate: new Date(),
    selectedContractDate: undefined,
    selectedServicesData: [],
    multipleServicesAdded: {
      status: false,
      numberOfServices: '',
    },
    handleActivateAllToggle: 0,
    allServicesTerminated: false,
    allServicesTerminatedDate: new Date(),
    showToast: false,
    toastData: {},
    openButtonMenu: { open: false, inventoryNumber: undefined },
  };

  constructor(props) {
    super(props);
    this.controls = {
      customerSection: [
        new Control({
          name: 'customer_id',
          type: 'string',
          label: <FormattedMessage id="common_customer_ID" />,
          disabled: true,
          rule: new ValidationRule({
            method: validator.isEmpty,
            validWhen: false,
            message: <FormattedMessage id="warning_contact_person" />,
          }),
          group: 'soShippingContact',
          id: 'shippingAttention',
          formGroup: 0,
          onBlur: this.handleCustomerSectionBlur,
          onFocus: this.handleCustomerSectionFocus,
          onChange: this.handleCustomerSectionChange,
        }),

        /**
         * Changed the type to hidden so that user will not see this field
         * But the field is still used for validation.
         */
        new Control({
          name: 'customer_name',
          type: 'hidden',
          label: '',
          disabled: true,
          rule: new ValidationRule({
            method: validator.isEmpty,
            validWhen: false,
            message: <FormattedMessage id="warning_contact_person" />,
          }),
          group: 'soShippingContact',
          id: 'customer_name_d_none',
          formGroup: 0,
          onBlur: this.handleCustomerSectionBlur,
          onFocus: this.handleShippingDetailsFocus,
          onChange: this.handleShippingAddressChange,
        }),
        new Control({
          name: 'customer_internal_id',
          type: 'hidden',
          label: '',
          disabled: true,
          group: 'soShippingContact',
          id: 'customer_name_d_none',
          formGroup: 0,
          onBlur: this.handleCustomerSectionBlur,
          onFocus: this.handleShippingDetailsFocus,
          onChange: this.handleShippingAddressChange,
        }),
      ],
      alarmSection: [
        new Control({
          name: 'alarmId',
          type: 'string',
          label: 'Alarm.com ID',
          disabled: false,
          group: 'soShippingContact',
          id: 'alarm_id',
          formGroup: 0,
          onBlur: this.handleCustomerSectionBlur,
          onFocus: this.handleShippingDetailsFocus,
          onChange: this.handleShippingAddressChange,
        }),
      ],
      resellerSection: [
        new Control({
          name: 'reseller_id',
          type: 'string',
          label: <FormattedMessage id="common_reseller_ID" />,
          disabled: true,
          group: 'soShippingContact',
          id: 'shippingAttention',
          formGroup: 0,
          onBlur: this.handleResellerSectionBlur,
          onFocus: this.handleResellerSectionFocus,
          onChange: this.handleResellerSectionChange,
        }),
        new Control({
          name: 'reseller_name',
          type: 'string',
          label: <FormattedMessage id="all_contracts_reseller" />,
          disabled: true,
          group: 'soShippingContact',
          id: 'shippingAttention',
          formGroup: 0,
          onBlur: this.handleResellerSectionBlur,
          onFocus: this.handleResellerSectionFocus,
          onChange: this.handleResellerSectionChange,
        }),
      ],
      addressSection: [
        new Control({
          name: 'location_name',
          type: 'string',
          label: <FormattedMessage id="ph_location_name" />,
          group: 'soShippingContact',
          id: 'locationName',
          formGroup: 0,
          onBlur: this.handleAddressSectionBlur,
          onFocus: this.handleAddressSectionFocus,
          onChange: this.handleAddressSectionChange,
        }),

        new Control({
          name: 'addressLine1',
          type: 'string',
          label: <FormattedMessage id="ph_address_info" />,
          rule: new ValidationRule({
            method: validator.isEmpty,
            validWhen: false,
            message: <FormattedMessage id="warning_address" />,
          }),
          group: 'mainAddress',
          id: 'customerAddressLine1',
          formGroup: 1,
          onBlur: this.handleAddressSectionBlur,
          onFocus: this.handleAddressSectionFocus,
          onChange: this.handleAddressSectionChange,
        }),
        new Control({
          name: 'postalCode',
          type: 'number',
          label: <FormattedMessage id="ph_postal_code" />,
          rule: new ValidationRule({
            method: validator.isLength,
            validWhen: true,
            args: [{ min: 0, max: 4 }],
            message: <FormattedMessage id="warning_postal_code" />,
          }),
          group: 'soShippingAddress',
          id: 'shippingPostalCode',
          formGroup: 1,
          onBlur: this.handleAddressSectionBlur,
          onFocus: this.handleAddressSectionFocus,
          onChange: this.handleAddressSectionChange,
        }),
        new Control({
          name: 'customerOrder',
          type: 'string',
          label: <FormattedMessage id="ph_billing_reference" defaultMessage="Billing refrence" />,
          group: 'soShippingContact',
          id: 'customerOrder',
          formGroup: 0,
          onBlur: this.handleAddressSectionBlur,
          onFocus: this.handleAddressSectionFocus,
          onChange: this.handleAddressSectionChange,
        }),
      ],
      contactSection: [
        new Control({
          name: 'contact_name',
          type: 'string',
          label: <FormattedMessage id="common_contact_name" />,
          rule: new ValidationRule({
            method: validator.isEmpty,
            validWhen: false,
            message: <FormattedMessage id="warning_contact_person" />,
          }),
          group: 'soShippingContact',
          id: 'contactName',
          formGroup: 0,
          onBlur: this.handleContactSectionBlur,
          onFocus: this.handleContactSectionFocus,
          onChange: this.handleContactSectionChange,
        }),
        new Control({
          name: 'contact_email',
          type: 'email',
          label: <FormattedMessage id="contact_email" />,
          rule: new ValidationRule({
            method: validator.isEmail,
            validWhen: true,
            message: <FormattedMessage id="warning_contact_person" />,
          }),
          group: 'soShippingContact',
          id: 'contactEmail',
          formGroup: 0,
          onBlur: this.handleContactSectionBlur,
          onFocus: this.handleContactSectionFocus,
          onChange: this.handleContactSectionChange,
        }),
        new Control({
          name: 'contact_phone',
          type: 'phone',
          label: <FormattedMessage id="ph_phone" />,
          rule: new ValidationRule({
            method: validator.isLength,
            validWhen: true,
            args: [{ min: 8, max: 8 }],
            message: <FormattedMessage id="warning_phone_number" />,
          }),
          group: 'soBillingContact',
          id: 'contactPhone',
          formGroup: 1,
          onBlur: this.handleContactSectionBlur,
          onFocus: this.handleContactSectionFocus,
          onChange: this.handleContactSectionChange,
        }),
      ],
    };
  }

  /**
   * @param {response.data should contains the contractDetails and subscriptions[]}
   * This function should call whenever the new state is changing or new backend change.
   */
  contractDetailsStateUpdate = response => {
    this.setState({ contractDetails: response.data });

    let subscriptions = response.data.subscriptions;
    let handleActivateAllToggle = 0;

    subscriptions.map(obj => {
      if (obj.parent) {
        subscriptions.map(item => {
          if (item.inventoryNumber === obj.parent.inventoryNumber) {
            item.childrens = [];
          }
          return item;
        });
      }

      if (obj.status !== Constants.orderItemStatus.registered) {
        handleActivateAllToggle++;
      }

      return obj;
    });

    subscriptions.map(obj => {
      if (obj.parent) {
        subscriptions.map(item => {
          if (item.inventoryNumber === obj.parent.inventoryNumber) {
            if (item.childrens) {
              item.childrens.push(obj);
            }
          }
          return item;
        });
      }
      return obj;
    });
    subscriptions = subscriptions.filter(obj => obj.isChild !== true);
    this.setState({ subscriptions });
    this.setState({ handleActivateAllToggle });
    this.props.contractSubscriptionUpdate({ subscriptions });
  };

  async componentDidMount() {
    this.setState({ loading: true });

    try {
      let response;
      const contractID = this.props.match.params.id;
      if (contractID) {
        this.setState({ loading: true });
        response = await this.contractService.getContractDetails(contractID);
        if (response) {
          this.contractDetailsStateUpdate(response);
          this.setState({ loading: false });
        }
      }
    } catch (error) {
      console.log(error);
    }
    this.setState({ loading: false });
  }

  validate(controls) {
    return controls.find(obj => obj.rule && !obj.isValid && !obj.donotCheck) === undefined;
  }
  handleCustomerSectionBlur = () => {
    let { customerSection } = this.state;
    customerSection.isFocus = false;
    this.setState({ customerSection });
  };
  handleResellerSectionBlur = () => {
    let { resellerSection } = this.state;
    resellerSection.isFocus = false;
    this.setState({ resellerSection });
  };
  handleAddressSectionBlur = () => {
    let { addressSection } = this.state;
    addressSection.isFocus = false;
    this.setState({ addressSection });
  };
  handleContactSectionBlur = () => {
    let { contactSection } = this.state;
    contactSection.isFocus = false;
    this.setState({ contactSection });
  };

  handleCustomerSectionFocus = () => {
    let { customerSection } = this.state;
    customerSection.isFocus = true;
    customerSection.isValid = this.validate(this.controls.customerSection);
  };

  handleResellerSectionFocus = () => {
    let { resellerSection } = this.state;
    resellerSection.isFocus = true;
    resellerSection.isValid = this.validate(this.controls.customerSection);
  };
  handleAddressSectionFocus = () => {
    let { addressSection } = this.state;
    addressSection.isFocus = true;
    addressSection.isValid = this.validate(this.controls.customerSection);
  };
  handleContactSectionFocus = () => {
    let { contactSection } = this.state;
    contactSection.isFocus = true;
    contactSection.isValid = this.validate(this.controls.customerSection);
  };

  handleCustomerSectionChange = async (name, value) => {
    let { customerSection } = this.state;
    customerSection.isValid = this.validate(this.controls.customerSection);
    this.setState({ customerSection, shippingIsSameAsMain: false });
  };
  handleResellerSectionChange = async (name, value) => {
    let { resellerSection } = this.state;
    resellerSection.isValid = this.validate(this.controls.resellerSection);
    this.setState({ resellerSection, shippingIsSameAsMain: false });
  };
  handleAddressSectionChange = async (name, value) => {
    let { addressSection } = this.state;
    addressSection.isValid = this.validate(this.controls.addressSection);
    this.setState({ addressSection, shippingIsSameAsMain: false });
  };
  handleContactSectionChange = async (name, value) => {
    let { contactSection } = this.state;
    contactSection.isValid = this.validate(this.controls.contactSection);
    this.setState({ contactSection, shippingIsSameAsMain: false });
  };

  handleOnDateChange = date => {
    this.setState({ selectedContractDate: date });
  };

  handleOnChangeRawDate = e => {
    e.preventDefault();
  };

  handleItemClick = data => {
    let { chooseServiceData } = this.state;
    chooseServiceData.map(obj => {
      if (obj.id === data.id) {
        if (obj.selected) obj.selected = false;
        else obj.selected = true;
      } else {
        obj.childrens.map(item => {
          if (item.id === data.id) {
            if (item.selected) item.selected = false;
            else item.selected = true;
          }
          return item;
        });
      }
      return obj;
    });
    const selectedServicesData = [];

    chooseServiceData.map(obj => {
      if (obj.selected) {
        selectedServicesData.push(obj);
      }
      if (obj.childrens) {
        obj.childrens.map(child => {
          if (child.selected) {
            selectedServicesData.push(child);
          }
          return child;
        });
      }
      return obj;
    });

    this.setState({ chooseServiceData, selectedServicesData });
  };

  handleOnAddService = () => {
    let { chooseServiceData } = this.state;
    chooseServiceData.map(obj => {
      if (obj.selected) {
        obj.selected = false;
      }
      if (obj.childrens) {
        obj.childrens.map(child => child.selected && (child.selected = false));
      }
      return obj;
    });
    this.setState({ selectedServicesData: [], chooseServiceData });
  };

  /**
   * After clicking the edit details icon this code will start to
   * run It will fill the control values with existing values
   */
  handleEditItems = () => {
    let { customerSection, resellerSection, addressSection, contactSection, alarmSection } = this.controls;
    const { contractDetails } = this.state;

    this.openElement.click();
    customerSection[0].setValue(String(contractDetails.customer.number));
    customerSection[1].setValue(contractDetails.customer.name);
    customerSection[2].setValue(contractDetails.customer.internalId);
    alarmSection[0].setValue(contractDetails.alarmId);
    if (contractDetails.reseller) {
      resellerSection[0].setValue(contractDetails.reseller.id);
      resellerSection[1].setValue(contractDetails.reseller.name);
    } else {
      resellerSection[0].setValue(Config.vismaId);
      resellerSection[1].setValue('Rackit AS');
    }

    addressSection[0].setValue(contractDetails.soInstallationAddress.locationName);
    addressSection[1].setValue(contractDetails.soInstallationAddress.addressLine1);
    addressSection[2].setValue(contractDetails.soInstallationAddress.postalCode);
    addressSection[3].setValue(contractDetails.customerOrder.value);

    addressSection[2].city = 'OSLO';
    contactSection[0].setValue(contractDetails.soInstallationContact.attention);
    contactSection[1].setValue(contractDetails.soInstallationContact.email);
    contactSection[2].setValue(contractDetails.soInstallationContact.phone1);
    this.setState({ installationEdit: true });
    this.changeIsValidOnState();
  };

  changeIsValidOnState = () => {
    const { customerSection, resellerSection, addressSection, contactSection } = this.state;

    customerSection.isValid = true;
    addressSection.isValid = true;
    contactSection.isValid = true;
    resellerSection.isValid = true;

    this.setState({
      customerSection,
      resellerSection,
      addressSection,
      contactSection,
    });
  };

  handleChangePriceOfService = id => {
    let { serviceData } = this.state;
    serviceData.map(service => {
      if (service.id === id) {
        if (service.priceChage) {
          service.priceChage = false;
        } else {
          service.priceChage = true;
        }
      }
      if (service.children) {
        service.children.map(item => {
          if (item.id === id) {
            if (item.priceChage) {
              item.priceChage = false;
            } else {
              item.priceChage = true;
            }
          }

          return item;
        });
      }
      return service;
    });
    this.setState({ serviceData });
    return;
  };

  currentTab = async selectedtab => {
    if (selectedtab === 'Services' && this.state.tab !== 'Services') {
      this.setState({ tab: selectedtab });
      this.setState({ serviceLoading: true });
      try {
        let response;
        const contractID = this.props.match.params.id;
        if (contractID) {
          response = await this.contractService.getContractDetails(contractID);
          if (response) {
            this.contractDetailsStateUpdate(response);
          }
        }
      } catch (error) {
        console.log(error);
      }
      this.setState({ serviceLoading: false });
    }
    this.setState({ tab: selectedtab });
  };

  handleOnChangeCurrentDate = () => {
    if (this.state.isChangedCurrentDate) {
      this.setState({ isChangedCurrentDate: false });
    } else {
      this.setState({ isChangedCurrentDate: true });
    }
  };

  handleTerminateAll = (terminationDate, isDateChosen) => {
    this.setState({ allServicesTerminated: isDateChosen });
    this.setState({ allServicesTerminatedDate: terminationDate });
  };

  handleOnClickActionsButtons = async () => {
    const {
      contractModalDateChange,
      contractModalSuccesModalType,
      contractActionError,
      contractActionLoader,
    } = this.props;
    this.setState({
      selectedContractDate: undefined,
      isChangedCurrentDate: false,
      modalType: '',
    });
    contractModalDateChange({
      selectedContractDate: undefined,
      nextBillingDate: undefined,
    });
    contractModalSuccesModalType({
      modalType: undefined,
    });
    contractActionError({ isActionSubmissionError: false });
    contractActionLoader({ isSubmitActionLoading: false });
  };

  handleAdditionOfServices = data => {
    this.setState({
      multipleServicesAdded: {
        status: true,
        numberOfServices: data,
      },
    });
  };

  /**
   * The submitting event not require any params
   * it will take the existing control value and send the payload to the backend
   *
   */
  handleOnSubmit = async () => {
    this.controls.customerSection.map(e => {
      if ((e.value.length === 0 || e.value.length === null) && e.rule !== undefined) {
        e.isValid = false;
        e.showError = true;
      }
    });
    this.handleCustomerSectionFocus();
    this.controls.resellerSection.map(e => {
      if ((e.value.length === 0 || e.value.length === null) && e.rule !== undefined) {
        e.isValid = false;
        e.showError = true;
      }
    });
    this.handleResellerSectionFocus();
    // this.controls.addressSection.map(e => {
    //   if ((e.value.length === 0 || e.value.length === null) && e.rule !== undefined) {
    //     e.isValid = false;
    //     e.showError = true;
    //   }
    // });
    this.handleAddressSectionFocus();
    this.controls.contactSection.map(e => {
      if ((e.value.length === 0 || e.value.length === null) && e.rule !== undefined) {
        e.isValid = false;
        e.showError = true;
      }
    });
    this.handleContactSectionFocus();
    //trigger State ToCall InputComponent;
    this.setState({ triigerState: true });
    let { customerSection, resellerSection, addressSection, contactSection, alarmSection } = this.controls;
    const { contractDetails } = this.state;
    if (
      this.validate(this.controls.contactSection) &&
      this.validate(this.controls.addressSection) &&
      this.validate(this.controls.customerSection) &&
      this.validate(this.controls.resellerSection)
    ) {
      contractDetails.customer.number = String(customerSection[0].value);
      contractDetails.customer.name = String(customerSection[1].value);
      contractDetails.customer.internalId = customerSection[2].value;
      contractDetails.alarmId = alarmSection[0].value;
      contractDetails.reseller.id = resellerSection[0].value;
      contractDetails.reseller.name = resellerSection[1].value;
      contractDetails.soInstallationAddress.locationName = addressSection[0].value;
      contractDetails.soInstallationAddress.addressLine1 = addressSection[1].value;
      contractDetails.soInstallationAddress.postalCode = String(addressSection[2].value);
      contractDetails.customerOrder.value = addressSection[3].value;
      contractDetails.soInstallationContact.attention = contactSection[0].value;
      contractDetails.soInstallationContact.email = contactSection[1].value;
      contractDetails.soInstallationContact.phone1 = contactSection[2].value;

      let payload = {
        customer: {
          number: String(customerSection[0].value),
          name: String(customerSection[1].value),
          internalId: customerSection[2].value,
        },
        alarmId: alarmSection[0].value,
        reseller: { id: resellerSection[0].value, name: resellerSection[1].value },
        soInstallationAddress: {
          locationName: addressSection[0].value,
          addressLine1: addressSection[1].value,
          postalCode: String(addressSection[2].value),
          city: addressSection[2].city,
        },
        soInstallationContact: {
          attention: contactSection[0].value,
          email: contactSection[1].value,
          phone1: contactSection[2].value,
        },
        alarmId: alarmSection[0].value,
        customerOrder: {
          value: addressSection[3].value,
        }
      };

      const contractID = this.props.match.params.id;

      try {
        this.setState({ editContractLoader: true });
        let response = await this.contractService.editContractDetails(payload, contractID);
        this.setState({ editContractLoader: false });
        this.contractDetailsStateUpdate(response.data);
        if (response.status === 200) {
          this.setState({ installationEdit: false });
          this.setState({ contractDetails });
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  /**
   * @author {Karthikeyan U}
   * This function is common for all action includes (renew, activate, terminate, cancel, edit-price)
   * @param {id=> inventoryNumber, type => type of the action, price=> price if the action is edit-price}
   *
   */
  handleOnSaveActionDate = async (id, type, price, applyToAllServices = false) => {
    const { subscriptions, contractDetails } = this.state;
    const {
      contract: { selectedContractDate, nextBillingDate },
      contractActionLoader: contractActionLoaderProps,
      contractActionError: contractActionErrorProps,
      contractModalSuccesModalType: contractModalSuccesModalTypeProps,
      match,
    } = this.props;
    let futureDate = false;
    if (selectedContractDate && selectedContractDate.getTime() > new Date().getTime()) {
      futureDate = true;
    }
    let payload,
      response,
      actionItems,
      tempArrray = [];
    const dateObj = new Date();
    const currentDate =
      String(dateObj.getUTCHours()).padStart(2, '0') +
      ':' +
      String(dateObj.getUTCMinutes()).padStart(2, '0') +
      ':' +
      String(dateObj.getUTCSeconds()).padStart(2, '0');
    const contractID = match.params.id;
    switch (type) {
      case Constants.contractModalType.renew:
        actionItems = [];
        subscriptions.map(obj => {
          if (obj.inventoryNumber === id) {
            obj.activationDate =
              moment(String(selectedContractDate || new Date())).format('YYYY-MM-DD') +
              ' T ' +
              (futureDate ? '00:00:00' : currentDate);
            actionItems.push(obj);
          }
          obj.childrens &&
            obj.childrens.map(item => {
              if (item.inventoryNumber === id) {
                item.activationDate =
                  moment(String(selectedContractDate || new Date())).format('YYYY-MM-DD') +
                  ' T ' +
                  (futureDate ? '00:00:00' : currentDate);
                actionItems.push(item);
              }
              return item;
            });
          return obj;
        });

        payload = {
          contractNo: contractDetails.contractNo,
          subscription: {
            activationDate: actionItems[0].activationDate,
            inventoryNumber: actionItems[0].inventoryNumber,
          },
        };
        contractActionLoaderProps({ isSubmitActionLoading: true });
        try {
          await this.contractService.renewSubscriptions(payload).then(data => {
            this.contractDetailsStateUpdate(data.data);

            contractModalSuccesModalTypeProps({
              modalType: Constants.contractModalType.renew,
            });
            this.handleToast(<FormattedMessage id="contract_operation_message_renew" />, Constants.toast.SUCCESS);
          });
        } catch (error) {
          contractActionErrorProps({ isActionSubmissionError: true });
          this.handleToast(<FormattedMessage id="contract_action_error_message" />, Constants.toast.ERROR);
        }
        contractActionLoaderProps({ isSubmitActionLoading: false });
        break;
      case Constants.contractModalType.terminate:
        actionItems = [];
        subscriptions.map(obj => {
          if (obj.inventoryNumber === id) {
            obj.terminationDate =
              moment(String(selectedContractDate || new Date())).format('YYYY-MM-DD') +
              ' T ' +
              (futureDate ? '00:00:00' : currentDate);
            actionItems.push(obj);
          }
          obj.childrens &&
            obj.childrens.map(item => {
              if (item.inventoryNumber === id) {
                item.terminationDate =
                  moment(String(selectedContractDate || new Date())).format('YYYY-MM-DD') +
                  ' T ' +
                  (futureDate ? '00:00:00' : currentDate);
                actionItems.push(item);
              }
              return item;
            });
          return obj;
        });
        actionItems.map(obj =>
          tempArrray.push({
            inventoryNumber: obj.inventoryNumber,
            terminationDate: obj.terminationDate,
          })
        );
        payload = {
          contractNo: contractDetails.contractNo,
          subscriptions: tempArrray,
        };
        contractActionLoaderProps({ isSubmitActionLoading: true });
        try {
          await this.contractService.terminateSubscriptionItem(payload).then(data => {
            this.contractDetailsStateUpdate(data.data);
            this.setState({
              modalType: Constants.contractModalType.terminate,
            });
            contractModalSuccesModalTypeProps({
              modalType: Constants.contractModalType.terminate,
            });
            this.handleToast(<FormattedMessage id="contract_operation_message_terminate" />, Constants.toast.SUCCESS);
          });
        } catch (error) {
          contractActionErrorProps({ isActionSubmissionError: true });
          this.handleToast(<FormattedMessage id="contract_action_error_message" />, Constants.toast.ERROR);
        }
        contractActionLoaderProps({ isSubmitActionLoading: false });
        break;

      case Constants.contractModalType.activate:
        actionItems = [];
        subscriptions.map(obj => {
          if (obj.inventoryNumber === id) {
            obj.activationDate =
              moment(String(selectedContractDate || new Date())).format('YYYY-MM-DD') +
              ' T ' +
              (futureDate ? '00:00:00' : currentDate);
            actionItems.push(obj);
          }
          obj.childrens &&
            obj.childrens.map(item => {
              if (item.inventoryNumber === id) {
                item.activationDate =
                  moment(String(selectedContractDate || new Date())).format('YYYY-MM-DD') +
                  ' T ' +
                  (futureDate ? '00:00:00' : currentDate);
                actionItems.push(item);
              }
              return item;
            });
          return obj;
        });

        actionItems.map(obj => {
          tempArrray.push({
            id: obj.inventoryNumber,
            date: obj.activationDate,
          });
          return obj;
        });

        payload = {
          contractNo: contractDetails.contractNo,
          subscriptions: tempArrray,
          nextBillingDate: nextBillingDate
            ? moment(String(nextBillingDate)).format('YYYY-MM-DD') + ' T ' + (futureDate ? '00:00:00' : currentDate)
            : undefined,
        };
        contractActionLoaderProps({ isSubmitActionLoading: true });
        try {
          await this.contractService.activateSubscriptions(payload).then(data => {
            this.contractDetailsStateUpdate(data.data);
            this.setState({
              modalType: Constants.contractModalType.activate,
            });
            contractModalSuccesModalTypeProps({
              modalType: Constants.contractModalType.activate,
            });
            this.handleToast(<FormattedMessage id="contract_operation_message_activation" />, Constants.toast.SUCCESS);
          });
        } catch (error) {
          contractActionErrorProps({ isActionSubmissionError: true });
          this.handleToast(<FormattedMessage id="contract_action_error_message" />, Constants.toast.ERROR);
        }
        contractActionLoaderProps({ isSubmitActionLoading: false });

        break;
      case Constants.contractModalType.cancel:
        payload = {
          contractNo: contractDetails.contractNo,
          subscription: {
            inventoryNumber: id,
          },
        };
        contractActionLoaderProps({ isSubmitActionLoading: true });
        try {
          await this.contractService.cancelSubscriptions(payload).then(data => {
            this.contractDetailsStateUpdate(data.data);
            this.setState({
              modalType: Constants.contractModalType.cancel,
            });
            contractModalSuccesModalTypeProps({
              modalType: Constants.contractModalType.cancel,
            });
            this.handleToast(<FormattedMessage id="contract_operation_message_cancel" />, Constants.toast.SUCCESS);
          });
        } catch (error) {
          contractActionErrorProps({ isActionSubmissionError: true });
          this.handleToast(<FormattedMessage id="contract_action_error_message" />, Constants.toast.ERROR);
        }
        contractActionLoaderProps({ isSubmitActionLoading: false });

        break;
      case Constants.contractModalType.editPrice:
        payload = {
          contractNo: contractDetails.contractNo,
          subscription: {
            inventoryNumber: id,
            price: Number(price),
          },
        };
        contractActionLoaderProps({ isSubmitActionLoading: true });
        try {
          await this.contractService.itemPriceEdit(payload, contractID).then(data => {
            this.contractDetailsStateUpdate(data.data);
            this.setState({
              modalType: Constants.contractModalType.editPrice,
            });
            contractModalSuccesModalTypeProps({
              modalType: Constants.contractModalType.editPrice,
            });
            this.handleToast(<FormattedMessage id="contract_operation_message_edit_price" />, Constants.toast.SUCCESS);
          });
        } catch (error) {
          this.handleToast(<FormattedMessage id="contract_action_error_message" />, Constants.toast.ERROR);
          contractActionErrorProps({ isActionSubmissionError: true });
        }
        contractActionLoaderProps({ isSubmitActionLoading: false });
        break;
      case Constants.contractModalType.editBillingPeriod:
        payload = {
          id,
          nextBillingDate: nextBillingDate
            ? moment(String(nextBillingDate)).format('YYYY-MM-DD') + ' T ' + (futureDate ? '00:00:00' : currentDate)
            : undefined,
          applyToAll: applyToAllServices,
        };
        contractActionLoaderProps({ isSubmitActionLoading: true });
        this.setState({ serviceLoading: true });
        try {
          await this.contractService.editBillingPeriod(payload, contractID).then(data => {
            this.contractDetailsStateUpdate(data.data);
            this.setState({
              modalType: Constants.contractModalType.editBillingPeriod,
              serviceLoading: false,
            });
            contractModalSuccesModalTypeProps({
              modalType: Constants.contractModalType.editBillingPeriod,
            });
            this.handleToast(
              <FormattedMessage id="contract_operation_message_edit_billing_period" />,
              Constants.toast.SUCCESS
            );
          });
        } catch (error) {
          this.setState({ serviceLoading: false });
          this.handleToast(<FormattedMessage id="contract_action_error_message" />, Constants.toast.ERROR);
          contractActionErrorProps({ isActionSubmissionError: true });
        }
        contractActionLoaderProps({ isSubmitActionLoading: false });
        break;
      case Constants.contractModalType.terminateAll:
        actionItems = [];

        subscriptions.map(obj => {
          if (
            obj.status === Constants.orderItemStatus.activated ||
            obj.status === Constants.orderItemStatus.registered
          ) {
            actionItems.push(obj);
            obj.terminationDate =
              moment(String(selectedContractDate || new Date())).format('YYYY-MM-DD') +
              ' T ' +
              (futureDate ? '00:00:00' : currentDate);
          }
          return obj;
        });

        actionItems.map(obj =>
          tempArrray.push({
            inventoryNumber: obj.inventoryNumber,
            terminationDate: obj.terminationDate,
          })
        );
        payload = {
          contractNo: contractDetails.contractNo,
          subscriptions: tempArrray,
          terminateAllIndex: true,
        };

        contractActionLoaderProps({ isSubmitActionLoading: true });
        try {
          response = await this.contractService.terminateSubscriptions(payload);

          if (response.data.data) {
            this.contractDetailsStateUpdate(response.data);
            this.setState({
              modalType: Constants.contractModalType.terminateAll,
            });
            contractModalSuccesModalTypeProps({
              modalType: Constants.contractModalType.terminateAll,
            });
            this.handleToast(<FormattedMessage id="contract_operation_message_terminate" />, Constants.toast.SUCCESS);
            contractActionLoaderProps({ isSubmitActionLoading: false });
          } else {
            this.handleToast(<FormattedMessage id="contract_action_error_message" />, Constants.toast.ERROR);
            contractActionErrorProps({ isActionSubmissionError: true });
            contractActionLoaderProps({ isSubmitActionLoading: false });
          }
        } catch (error) {
          this.handleToast(<FormattedMessage id="contract_action_error_message" />, Constants.toast.ERROR);
          contractActionErrorProps({ isActionSubmissionError: true });
          contractActionLoaderProps({ isSubmitActionLoading: false });
          console.log(error, 'ERROR');
        }
        break;

      case Constants.contractModalType.activateAll:
        actionItems = [];

        subscriptions.map(obj => {
          if (obj.status === Constants.orderItemStatus.registered) {
            obj.activationDate =
              moment(String(selectedContractDate || new Date())).format('YYYY-MM-DD') +
              ' T ' +
              (futureDate ? '00:00:00' : currentDate);
            actionItems.push(obj);
          }
          if (obj.childrens) {
            obj.childrens.map(child => {
              if (child.status === Constants.orderItemStatus.registered) {
                child.activationDate =
                  moment(String(selectedContractDate || new Date())).format('YYYY-MM-DD') +
                  ' T ' +
                  (futureDate ? '00:00:00' : currentDate);
                actionItems.push(child);
              }
              return child;
            });
          }
          return obj;
        });

        actionItems.map(obj =>
          tempArrray.push({
            id: obj.inventoryNumber,
            date: obj.activationDate,
          })
        );
        payload = {
          contractNo: contractDetails.contractNo,
          subscriptions: tempArrray,
          nextBillingDate: nextBillingDate
            ? moment(String(nextBillingDate)).format('YYYY-MM-DD') + ' T ' + (futureDate ? '00:00:00' : currentDate)
            : undefined,
        };

        contractActionLoaderProps({ isSubmitActionLoading: true });
        try {
          response = await this.contractService.activateSubscriptions(payload);

          if (response.data.data) {
            this.contractDetailsStateUpdate(response.data);
            this.setState({
              modalType: Constants.contractModalType.activateAll,
            });
            contractModalSuccesModalTypeProps({
              modalType: Constants.contractModalType.activateAll,
            });
            this.handleToast(<FormattedMessage id="contract_operation_message_activation" />, Constants.toast.SUCCESS);
            contractActionLoaderProps({ isSubmitActionLoading: false });
          } else {
            this.handleToast(<FormattedMessage id="contract_action_error_message" />, Constants.toast.ERROR);
            contractActionErrorProps({ isActionSubmissionError: true });
            contractActionLoaderProps({ isSubmitActionLoading: false });
          }
        } catch (error) {
          this.handleToast(<FormattedMessage id="contract_action_error_message" />, Constants.toast.ERROR);
          contractActionErrorProps({ isActionSubmissionError: true });
          contractActionLoaderProps({ isSubmitActionLoading: false });
          console.log(error, 'ERROR');
        }
        break;

      default:
        break;
    }
  };

  /**
   * @param {item => item should be returned from the customer suggestion Component}
   * the errors should be removed after auto-filling the fields
   */
  handleOnCustomerSelected = item => {
    // console.log(item);
    let { customerSection, resellerSection } = this.controls;
    if (item) {
      customerSection[0].setValue(item[0].number);
      customerSection[1].setValue(item[0].name);
      customerSection[2].setValue(item[0].internalId);
      if (item[0].reseller) {
        resellerSection[0].setValue(item[0].reseller.id);
        resellerSection[1].setValue(item[0].reseller.name);
      } else {
        resellerSection[0].setValue(Config.vismaId);
        resellerSection[1].setValue('Rackit AS');
      }
      this.setState({ installationEdit: true });
      this.changeIsValidOnState();
    }
  };
  handleToast = (data, type) => {
    let toastData = {
      type: type,
      message: data,
      delay: Constants.toast.LONG_DELAY,
    };

    this.setState({ showToast: true, toastData });
    setTimeout(() => {
      this.setState({
        showToast: false,
      });
    }, toastData.delay);
  };
  handleOpenButtonMenu = inventoryNo => {
    const {
      openButtonMenu: { open, inventoryNumber },
    } = this.state;
    if (inventoryNo === inventoryNumber) {
      this.setState({
        openButtonMenu: {
          open: !open,
          inventoryNumber: inventoryNo,
        },
      });
    } else {
      this.setState({
        openButtonMenu: {
          open: true,
          inventoryNumber: inventoryNo,
        },
      });
    }

    this.forceUpdate();
  };

  render() {
    const {
      installationEdit,
      customerIdEdit,
      billingRefEdit,
      contractDetails,
      customerSection,
      resellerSection,
      addressSection,
      contactSection,
      subscriptions,
      loading,
      editContractLoader,
      serviceLoading,
      handleActivateAllToggle,
      showToast,
      toastData,
      openButtonMenu,
    } = this.state;
    const isSuperAdmin = this.authService.isSuperAdminUser();
    const isAdminSales = this.authService.isAdminSalesUser();
    const isAdminBackOffice = this.authService.isAdminBackOfficeUser();

    return (
      <>
        {showToast && (
          <Toast type={toastData.type} message={toastData.message} showToast={showToast} delay={toastData.delay} />
        )}
        <section id=" page-header" className="">
          {loading && <div className="pt-10 mt-10" />}
          <LoadingOverlay
            active={loading}
            spinner={<BounceLoader />}
            text={<FormattedMessage id="common_loading_overlay" />}
          >
            {!loading && (
              <div className="container-fluid px-0 ">
                <div className="  mt-0 mb-1">
                  <div className="row content-justify-end pr-0 text-right">
                    <React.Fragment>
                      <div className="col text-right pr-0 margin-top-neg-11 ">
                        <a
                          href="#back"
                          className="font-weight-normal fs-14"
                          // onClick={event => {
                          //     event.preventDefault();
                          //     if (this.props.history && this.props.history.length > 1) {
                          //       return this.props.history.push({ pathname: Constants.routes.contracts });
                          //     }
                          // }}
                          onClick={this.goBack}
                        >
                          <img
                            className="mr-2"
                            src={Constants.icons.arrowBack}
                            width="24"
                            height="24"
                            alt="back-icon"
                          />

                          <FormattedMessage id="common_back" />
                        </a>
                      </div>
                    </React.Fragment>
                  </div>
                  <div className="row mb-0">
                    <div className="col-10">
                      <h1 className="mb-2 d-block ls-0-25">{contractDetails && contractDetails.customer.name}</h1>
                      <p className="mb-1 line-height-28 ls-0-44">
                        {this.props.match.params.id} -
                        {contractDetails && contractDetails.soInstallationAddress.addressLine1}
                        {', '}
                        {contractDetails && contractDetails.soInstallationAddress.postalCode}{' '}
                        {contractDetails && contractDetails.soInstallationAddress.city}
                      </p>
                      <p className="line-height-28 mb-2 ls-0-44">
                        {contractDetails &&
                          contractDetails.status &&
                          (contractDetails.status === Constants.orderItemStatus.activated ||
                            contractDetails.status === Constants.orderItemStatus.terminated ||
                            contractDetails.status === Constants.orderItemStatus.cancelled) && (
                            <img
                              className="mr-2 mb-1"
                              src={
                                contractDetails.status === Constants.orderItemStatus.activated
                                  ? Constants.icons.tickFilled
                                  : contractDetails.status === Constants.orderItemStatus.terminated ||
                                    contractDetails.status === Constants.orderItemStatus.cancelled
                                  ? Constants.icons.crossFilled
                                  : ''
                              }
                              width="18"
                              height="18"
                              alt="status-icon"
                            />
                          )}
                        {contractDetails &&
                          (contractDetails.status === 'Terminated'
                            ? Constants.language.contract_details_terminated_since +
                              ' ' +
                              (contractDetails.terminationDate &&
                                moment(String(contractDetails.terminationDate).slice(0, 10)).format('DD-MM-YYYY'))
                            : contractDetails.status === Constants.orderItemStatus.registered
                            ? Constants.language.contract_details_registered_since +
                              ' ' +
                              (contractDetails.registeredDate &&
                                moment(String(contractDetails.registeredDate).slice(0, 10)).format('DD-MM-YYYY'))
                            : contractDetails.status === Constants.orderItemStatus.activated
                            ? Constants.language.contract_details_activated_since +
                              ' ' +
                              (contractDetails.activationDate &&
                                moment(String(contractDetails.activationDate).slice(0, 10)).format('DD-MM-YYYY'))
                            : contractDetails.status === 'Cancelled' &&
                              Constants.language.contract_cancelled_since +
                                ' ' +
                                (contractDetails.cancellationDate &&
                                  moment(String(contractDetails.cancellationDate).slice(0, 10)).format('DD-MM-YYYY')))}
                      </p>
                      {contractDetails && contractDetails.status === Constants.orderItemStatus.registered && (
                        <span className="font-weight-light fs-14">
                          {contractDetails.dueStatus === 'Pending Activation'
                            ? ' ( ' +
                              Constants.language.contract_activation_due +
                              ' ' +
                              moment(String(contractDetails.activationDate).slice(0, 10)).format('DD-MM-YYYY') +
                              ' )'
                            : ''}
                        </span>
                      )}
                      {contractDetails && contractDetails.status === Constants.orderItemStatus.activated && (
                        <span className="font-weight-light fs-14">
                          {contractDetails.dueStatus === 'Pending Termination'
                            ? ' ( ' +
                              Constants.language.contract_termination_due +
                              ' ' +
                              moment(String(contractDetails.terminationDate).slice(0, 10)).format('DD-MM-YYYY') +
                              ' )'
                            : ''}
                        </span>
                      )}
                      {contractDetails && contractDetails.status === 'Terminated' && (
                        <span className="font-weight-light fs-14">
                          {contractDetails.dueStatus === 'Pending Activation'
                            ? ' ( ' +
                              Constants.language.contract_termination_due +
                              ' ' +
                              moment(String(contractDetails.activationDate).slice(0, 10)).format('DD-MM-YYYY') +
                              ' )'
                            : ''}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    {(isSuperAdmin || isAdminBackOffice) && (
                      <EditContractDetailsModal
                        controls={this.controls}
                        installationEdit={installationEdit}
                        onSubmit={this.handleOnSubmit}
                        isValid={
                          customerSection.isValid &&
                          resellerSection.isValid &&
                          addressSection.isValid &&
                          contactSection.isValid
                        }
                        editContractLoader={editContractLoader} // Below prop is for customer auto suggest component
                        customerService={this.customerService}
                        onCustomerSelected={this.handleOnCustomerSelected}
                        contractDetails={contractDetails}
                      />
                    )}
                  </div>
                </div>
                <div className="">
                  <ContractDetailsTab currentTab={this.currentTab} />
                </div>
                {this.state.tab === 'Services' && (
                  <>
                    <LoadingOverlay
                      active={serviceLoading}
                      spinner={<BounceLoader />}
                      text={<FormattedMessage id="common_loading_overlay" />}
                    >
                      <div className="">
                        <CommonModalView onSaveActionDate={this.handleOnSaveActionDate} />
                        {(isSuperAdmin || isAdminBackOffice) && (
                          <div className="row mb-4 px-3 contracts-heading-action-button ">
                            <div className="d-inline-block mr-2 mb-1">
                              <ContractsActionButtons
                                addService
                                contractID={contractDetails && contractDetails.contractNo}
                                contractStatus={contractDetails && contractDetails.status}
                                handleOpenButtonMenu={this.handleOpenButtonMenu}
                              />
                            </div>
                            {contractDetails &&
                              (contractDetails.status === Constants.orderItemStatus.activated ||
                                contractDetails.status === Constants.orderItemStatus.registered) &&
                              contractDetails.subscriptions.length !== handleActivateAllToggle && (
                                <div className="d-inline-block">
                                  <ContractsActionButtons
                                    type={Constants.contractModalType.activateAll}
                                    onClickActionButtons={this.handleOnClickActionsButtons}
                                    handleOpenButtonMenu={this.handleOpenButtonMenu}
                                  />
                                </div>
                              )}
                            {contractDetails && contractDetails.status === Constants.orderItemStatus.activated && (
                              <div className="d-inline-block">
                                <ContractsActionButtons
                                  type={Constants.contractModalType.terminateAll}
                                  onClickActionButtons={this.handleOnClickActionsButtons}
                                  handleOpenButtonMenu={this.handleOpenButtonMenu}
                                />
                              </div>
                            )}
                          </div>
                        )}
                        <div className="row mb-5">
                          {subscriptions &&
                            subscriptions.map((obj, index) => (
                              <ContractServicesCard
                                data={obj}
                                onClickActionButtons={this.handleOnClickActionsButtons}
                                onSaveActionDate={this.handleOnSaveActionDate}
                                isSuperAdmin={isSuperAdmin}
                                isAdminSales={isAdminSales}
                                isAdminBackOffice={isAdminBackOffice}
                                openButtonMenu={openButtonMenu}
                                handleOpenButtonMenu={this.handleOpenButtonMenu}
                                key={index}
                              />
                            ))}
                        </div>
                      </div>
                    </LoadingOverlay>
                  </>
                )}
                <div className="">
                  {this.state.tab === 'Details' && (
                    <ContractDetailsHead
                      controls={this.controls}
                      editItems={this.handleEditItems}
                      installationEdit={installationEdit}
                      customerIdEdit={customerIdEdit}
                      billingRefEdit={billingRefEdit}
                      contractDetails={contractDetails}
                      isSuperAdmin={isSuperAdmin}
                      isAdminSales={isAdminSales}
                      isAdminBackOffice={isAdminBackOffice}
                    />
                  )}
                  {this.state.tab === 'Orders' && <ServiceOrders contractID={this.props.match.params.id} />}
                  {this.state.tab === 'Invoice' && <ServiceInvoice contractID={this.props.match.params.id} />}
                  {this.state.tab === 'Activity Log' && <ServiceActivityLog contractID={this.props.match.params.id} />}
                </div>
              </div>
            )}
            <a
              href="#edit"
              aria-hidden="true"
              data-toggle="modal"
              data-target="#editContractDetailsModal"
              ref={openModal => (this.openElement = openModal)}
            />
          </LoadingOverlay>
        </section>
      </>
    );
  }
}

const mapStateToProps = state => ({
  contract: state.contract,
});

export default connect(
  mapStateToProps,
  {
    contractActionLoader,
    contractActionError,
    contractModalTypeSelection,
    contractSubscriptionUpdate,
    contractModalDateChange,
    contractModalSuccesModalType,
  }
)(ContractDetails);
