import ApiService from './ApiService';
import Customer from '../shared/models/Customer';

class ResellerService extends ApiService {
  endPoint = 'resellers';
  search = 'name';
  page = 'pageNumber';
  editUsers = 'users/';

  async getAllUsers(queryParam) {
    let response = await this.get(this.baseURL + this.endPoint + this.queryParamString(queryParam));
    if (response.status !== 200) {
      // TODO: Throw more specific errors
      throw Error(response.statusText);
    } else {
      const { data, meta } = response.data;
      return {
        status: response.status,
        resellers: data,
        meta: meta,
      };
    }
  }
  async getAll(queryParam) {
    let response = await this.get(this.baseURL + this.endPoint + this.queryParamString(queryParam));
    if (response.status !== 200) {
      // TODO: Throw more specific errors
      throw Error(response.statusText);
    } else {
      const { data, meta } = response.data;
      return {
        status: response.status,
        resellers: data,
        meta: meta,
      };
    }
  }

  async connectExistingReseller(data) {
    this.setAuthorizationToken();
    let payload = {};
    data.map(obj => {
      payload[obj.name] = obj.value;
      return obj;
    });
    payload['email'] = payload['email'].toLowerCase();
    try {
      let response = await this.post(this.baseURL + this.endPoint + '/connect', payload);
      return response;
    } catch (error) {
      return error.response;
    }
  }

  async createNewReseller(payload, billingSameAsMain, shippingSameAsMain) {
    this.setAuthorizationToken();
    let { customer, subDealer, billing, shipping } = payload;
    let data = {};
    data['corporateId'] = {
      value: customer.find(obj => obj.name === 'corporateId').value,
    };
    if (subDealer) {
      data['subDealerId'] = {
        value: subDealer.find(obj => obj.name === 'subDealerId').value,
      };
    }
    data['name'] = {
      value: customer.find(obj => obj.name === 'name').value,
    };
    data['sendInvoicesByEmail'] = {
      value: true,
    };

    data['mainContact'] = { value: this.formatLoad(customer, 'mainContact') };

    data['mainAddress'] = { value: this.formatLoad(customer, 'mainAddress') };

    data['invoiceContact'] = {
      value: billingSameAsMain ? data['mainContact'] : this.formatLoad(billing, 'invoiceContact'),
    };
    data['invoiceAddress'] = {
      value: billingSameAsMain ? data['mainAddress'] : this.formatLoad(billing, 'invoiceAddress'),
    };

    data['deliveryContact'] = {
      value: shippingSameAsMain ? data['mainContact'] : this.formatLoad(shipping, 'deliveryContact'),
    };

    data['deliveryAddress'] = {
      value: shippingSameAsMain ? data['mainAddress'] : this.formatLoad(shipping, 'deliveryAddress'),
    };
    try {
      let response = await this.post(this.baseURL + this.endPoint, data);
      return response;
    } catch (error) {
      return error.response;
    }
  }
  async createNewCustomer(payload, billingSameAsMain, shippingSameAsMain, reseller, edit, collectiveInvoice) {
    this.setAuthorizationToken();
    let { customer, subDealer, billing, shipping, mainContact } = payload;

    let data = {},
      endPoint;

    let customerType = customer.find(obj => obj.name === 'type').value;
    if (customerType === '4') {
      data['corporateId'] = {
        value: customer.find(obj => obj.name === 'corporateId').value,
      };
      endPoint = 'customers/business-customer';
    }
    if (customerType === '1') {
      endPoint = 'customers/end-customer';
    }
    if (customerType === '5') {
      data['corporateId'] = {
        value: customer.find(obj => obj.name === 'corporateId').value,
      };
      if (subDealer) {
        data['subDealerId'] = {
          value: subDealer.find(obj => obj.name === 'subDealerId').value,
        };
      }
      endPoint = 'resellers';
    }

    if (edit) {
      endPoint = 'customers/' + edit;
    }
    if (customerType === '1' || customerType === '4') {
      data['owner'] = {
        value: reseller.id,
        name: reseller.name,
      };
    }
    data['collectiveInvoice'] = collectiveInvoice;
    data['customerOrder'] = {
      value: billing.find(obj => obj.name === 'billingReference').value,
    };
    data['name'] = {
      value: customer.find(obj => obj.name === 'name').value,
    };
    data['status'] = {
      value: customer.find(obj => obj.name === 'status').value,
    };
    data['sendInvoicesByEmail'] = {
      value: true,
    };

    data['mainContact'] = {
      value: {
        name: { value: customer.find(obj => obj.name === 'name').value },
        attention: { value: mainContact.find(obj => obj.name === 'mainContactPerson').value },
        phone1: { value: mainContact.find(obj => obj.name === 'mainPhone').value },
        email: { value: mainContact.find(obj => obj.name === 'mainEmail').value },
      },
    };

    data['mainAddress'] = {
      value: {
        addressLine1: { value: mainContact.find(obj => obj.name === 'mainAddress').value },
        postalCode: { value: mainContact.find(obj => obj.name === 'mainZip').value },
      },
    };

    data['invoiceContact'] = {
      value: {
        email: { value: billing.find(obj => obj.name === 'billingEmail').value },
        phone1: { value: billing.find(obj => obj.name === 'billingPhone').value },
        attention: { value: billing.find(obj => obj.name === 'billingcontactPerson').value },
      },
    };

    data['invoiceAddress'] = {
      value: {
        billingContact: { value: billing.find(obj => obj.name === 'billingcontactPerson').value },
      },
    };

    data['deliveryContact'] = {
      value: {
        attention: { value: shipping.find(obj => obj.name === 'shippingContactPerson').value },
        phone1: { value: shipping.find(obj => obj.name === 'shippingPhone').value },
        email: { value: shipping.find(obj => obj.name === 'shippingEmail').value },
      },
    };
    data['deliveryAddress'] = {
      value: {
        addressLine1: { value: shipping.find(obj => obj.name === 'shippingAddress').value },
        postalCode: { value: shipping.find(obj => obj.name === 'shippingZip').value },
      },
    };
    try {
      let response = edit
        ? await this.put(this.baseURL + endPoint, data)
        : await this.post(this.baseURL + endPoint, data);
      return response;
    } catch (error) {
      return error.response;
    }
  }
  formatLoad(controls, group) {
    let elements = controls.filter(obj => obj.group === group).filter(obj => obj.value);

    let payload = {};
    for (var i = 0; i < elements.length; i++) {
      const obj = elements[i];
      if (obj.name === 'email') {
        payload[obj.name] = { value: obj.value.toLowerCase() };
      } else {
        payload[obj.name] = { value: obj.value };
      }
    }
    return payload;
  }

  async checkOrgId(corporateId) {
    let response = await this.get(this.baseURL + 'customers' + '/business-customer/' + corporateId);

    if (response.status !== 200) {
      // TODO: Throw more specific errors
      throw Error(response.statusText);
    } else {
      return {
        status: response.status,
        data: response.data,
      };
    }
  }
  async checktelephonemail(telephone, email) {
    let response = await this.get(this.baseURL + 'customers/end-customer?email=' + email + '&phone=' + telephone);
    if (response.status !== 200) {
      throw Error(response.statusText);
    } else {
      return {
        status: response.status,
        data: response.data,
      };
    }
  }
  async editUser(id, data) {
    let response = await this.put(this.baseURL + this.editUsers + id, data);
    if (response.status !== 200) {
      // TODO: Throw more specific errors
      throw Error(response.statusText);
    } else {
      return response;
    }
  }
}

export default ResellerService;
