import ApiService from './ApiService';

class ContractService extends ApiService {
  endPoint = {
    contracts: 'contracts',
    myContracts: 'my-contracts',
    services: 'services',
    createNewService: 'create-new-service',
    orders: 'orders',
    invoices: 'invoices',
    contract: 'contract',
    editContract: 'edit-details',
    terminateSubscription: 'terminate-subscription',
    activateSubscription: 'activate-subscription',
    activities: 'activities',
    addSubscription: 'add-subscription',
    cancelSubscription: 'cancel-subscription',
    renewSubscription: 'renew-subscription',
    editPrice: 'edit-price',
    create: 'create',
    parentEntities: 'parent-entities',
    cancel: 'cancel',
    id: 'id',
    activate: 'activate',
    testBatch: 'test-batch',
    runBatch: 'process-batch',
    exportContracts: 'export-contracts',
    editBillingPeriod: 'edit-billing'
  };

  async getAllContracts(queryParam) {
    // console.log(queryParam);
    let response = await this.get(
      //   this.baseURL + this.endPoint + this.queryParamString(queryParam)
      this.baseURL + this.endPoint.contracts + this.queryParamString(queryParam)
    );
    if (response.status !== 200) {
      // TODO: Throw more specific errors
      throw Error(response.statusText);
    } else {
      return {
        status: response.status,
        orders: response.data.data.map(obj => obj),
        meta: response.data.meta,
        runbatch: response.data.enableBatch,
      };
    }
  }

  async exportContracts(queryParam) {
    let response = await this.get(
      this.baseURL + this.endPoint.exportContracts + this.queryParamString(queryParam)
    );

    if (response.status !== 200) {
      // TODO: Throw more specific errors
      throw Error(response.statusText);
    } else {
        const { data, meta } = response.data;
        const { status } = response;
        return {
          status,
          data,
          meta
        };
    }
  }

  async getAllResellerContracts(queryParam) {
    let response = await this.get(
      //   this.baseURL + this.endPoint + this.queryParamString(queryParam)
      this.baseURL + this.endPoint.contracts + '/' + this.endPoint.myContracts
    );
    if (response.status !== 200) {
      // TODO: Throw more specific errors
      throw Error(response.statusText);
    } else {
      return {
        status: response.status,
        orders: response.data.data.map(obj => obj),
        meta: response.data.meta,
      };
    }
  }

  async getContractDetails(id) {
    let response = await this.get(
      //   this.baseURL + this.endPoint + this.queryParamString(queryParam)
      this.baseURL + this.endPoint.contracts + '/' + id
    );
    if (response.status !== 200) {
      // TODO: Throw more specific errors
      throw Error(response.statusText);
    } else {
      return {
        status: response.status,
        data: response.data,
        meta: response.data.meta,
      };
    }
  }

  async getContractOrders(id, queryParams) {
    let response = await this.get(
      this.baseURL +
        this.endPoint.contracts +
        '/' +
        id +
        '/' +
        this.endPoint.orders +
        this.queryParamString(queryParams)
    );
    if (response.status !== 200) {
      throw Error(response.statusText);
    } else {
      return {
        status: response.status,
        data: response.data,
        meta: response.data.meta,
      };
    }
  }

  async getContractInovices(id, queryParams) {
    let response = await this.get(
      this.baseURL +
        this.endPoint.contracts +
        '/' +
        id +
        '/' +
        this.endPoint.invoices +
        this.queryParamString(queryParams)
    );
    if (response.status !== 200) {
      throw Error(response.statusText);
    } else {
      return {
        status: response.status,
        data: response.data,
        meta: response.data.meta,
      };
    }
  }

  async getActivityLogs(id, queryParams) {
    let response = await this.get(
      this.baseURL +
        this.endPoint.contracts +
        '/' +
        id +
        '/' +
        this.endPoint.activities +
        this.queryParamString(queryParams)
    );
    if (response.status !== 200) {
      throw Error(response.statusText);
    } else {
      return {
        status: response.status,
        data: response.data,
        meta: response.data.meta,
      };
    }
  }
  async getServicesForContract(id) {
    let response = await this.get(this.baseURL + this.endPoint.services + '/' + id);
    if (response.status !== 200) {
      throw Error(response.statusText);
    } else {
      return {
        status: response.status,
        data: response.data,
        meta: response.data.meta,
      };
    }
  }

  async addSubscriptionForContract(serviceData, contractID) {
    let data = {};
    data['subscriptions'] = serviceData;
    let taxPercentage = 25.0 / 100;
    let totalAmount = Number(
      serviceData
        .map(service => service.currentCost)
        .reduce((accumulator, value) => {
          return accumulator + value;
        })
    );
    let moms = Number(totalAmount * taxPercentage);
    data['serviceTotal'] = {
      exMoms: Number(totalAmount),
      Moms: Number(moms),
      total: Number(Number(totalAmount + moms).toFixed(2)),
    };

    let response = await this.put(this.baseURL + this.endPoint.contracts + '/' + contractID, data);
    if (response.status !== 200) {
      throw Error(response.statusText);
    } else {
      return {
        status: response.status,
        data: response.data,
        meta: response.data.meta,
      };
    }
  }

  /**
   * to create a subscription item via products page
   * @param {this function requires the description, defaultPrice and other params} payload
   *
   */
  async createSubscriptionItem(payload) {
    let response = await this.post(
      this.baseURL + this.endPoint.services + '/' + this.endPoint.createNewService,
      payload
    );
    if (response.status !== 202) {
      // TODO: Throw more specific errors
      throw Error(response.statusText);
    } else {
      return {
        status: response.status,
        data: response.data,
        meta: response.data.meta,
      };
    }
  }

  /**
  // To edit the contract details from the contract details page
   * @param {customerDetail, resellerDetail, locationAddress, locationContact} payload 
   */
  async editContractDetails(payload, contractID) {
    let response = await this.put(
      this.baseURL + this.endPoint.contracts + '/' + contractID + '/' + this.endPoint.editContract,
      payload
    );
    if (response.status !== 200) {
      // TODO: Throw more specific errors
      throw Error(response.statusText);
    } else {
      return {
        status: response.status,
        data: response.data,
        meta: response.data.meta,
      };
    }
  }

  /**
   *
   * @param {contractNo, Subscription:[{inventoryNumber, terminationDate}]} payload
   */
  async terminateSubscriptions(payload) {
    let response = await this.post(
      this.baseURL + this.endPoint.orders + '/' + this.endPoint.contract + '/' + this.endPoint.terminateSubscription,
      payload
    );
    if (response.status !== 200) {
      // TODO: Throw more specific errors
      throw Error(response.statusText);
    } else {
      return {
        status: response.status,
        data: response.data,
        meta: response.data.meta,
      };
    }
  }

  /**
   * @author {Karthikeyan U}
   * @param {contractNo, Subscription:[{inventoryNumber, terminationDate}]} payload
   */
  async activateSubscriptions(payload) {
    let response = await this.put(
      this.baseURL + this.endPoint.contracts + '/' + payload.contractNo + '/' + this.endPoint.activate,
      payload
    );
    if (response.status !== 200) {
      // TODO: Throw more specific errors
      throw Error(response.statusText);
    } else {
      return {
        status: response.status,
        data: response.data,
        meta: response.data.meta,
      };
    }
  }

  /**
   * @author {Karthikeyan U}
   * @param {contractNo, Subscription:[{inventoryNumber}]} payload
   */
  async cancelSubscriptions(payload) {
    let response = await this.put(
      this.baseURL +
        this.endPoint.contracts +
        '/' +
        payload.contractNo +
        '/' +
        this.endPoint.cancel +
        '?' +
        this.endPoint.id +
        '=' +
        payload.subscription.inventoryNumber
    );
    if (response.status !== 200) {
      // TODO: Throw more specific errors
      throw Error(response.statusText);
    } else {
      return {
        status: response.status,
        data: response.data,
        meta: response.data.meta,
      };
    }
  }

  /**
   * @author {Karthikeyan U}
   * @param {contractNo, Subscription:[{inventoryNumber, activationDate}]} payload
   */
  async renewSubscriptions(payload) {
    let response = await this.post(
      this.baseURL + this.endPoint.orders + '/' + this.endPoint.contract + '/' + this.endPoint.renewSubscription,
      payload
    );
    if (response.status !== 200) {
      // TODO: Throw more specific errors
      throw Error(response.statusText);
    } else {
      return {
        status: response.status,
        data: response.data,
        meta: response.data.meta,
      };
    }
  }

  /**
   * @author {Karthikeyan U}
   * @param {contractNo, Subscription:[{inventoryNumber, terminationDate}]} payload
   */
  async terminateSubscriptionItem(payload) {
    let response = await this.post(
      this.baseURL + this.endPoint.orders + '/' + this.endPoint.contract + '/' + this.endPoint.terminateSubscription,
      payload
    );
    if (response.status !== 200) {
      // TODO: Throw more specific errors
      throw Error(response.statusText);
    } else {
      return {
        status: response.status,
        data: response.data,
        meta: response.data.meta,
      };
    }
  }

  /**
   * @author {Karthikeyan U}
   * @param {contractNo, Subscription:[{inventoryNumber, price}]} payload
   */
  async itemPriceEdit(payload, contractID) {
    let response = await this.put(
      this.baseURL + this.endPoint.contracts + '/' + contractID + '/' + this.endPoint.editPrice,
      payload
    );
    if (response.status !== 200) {
      // TODO: Throw more specific errors
      throw Error(response.statusText);
    } else {
      return {
        status: response.status,
        data: response.data,
        meta: response.data.meta,
      };
    }
  }

  async editBillingPeriod(payload, contractID) {
    const response = await this.put(
      `${this.baseURL}${this.endPoint.contracts}/${contractID}/${this.endPoint.editBillingPeriod}`,
      payload
    );
    const { statusText, status, data, data: { meta } } = response;
    if (status !== 200) {
      // TODO: Throw more specific errors
      throw Error(statusText);
    } else {
      return {
        status,
        data,
        meta,
      };
    }
  }

  /**
   * @param {just a get request returns the parentServices }
   */
  async getParentServicesEntity() {
    let response = await this.get(
      this.baseURL + this.endPoint.services + '/' + this.endPoint.create + '/' + this.endPoint.parentEntities
    );
    if (response.status !== 200) {
      throw Error(response.statusText);
    } else {
      return {
        status: response.status,
        data: response.data,
        meta: response.data.meta,
      };
    }
  }

  async testBatchService() {
    let response;
    try {
      response = await this.post(this.baseURL + this.endPoint.invoices + '/' + this.endPoint.testBatch);
      if (response.status !== 200) {
        throw Error(response.statusText);
      } else {
        return {
          status: response.status,
          data: response.data,
        };
      }
    } catch (error) {
      console.log(error);
    }
  }

  async runBatchService() {
    try {
      const response = await this.post(this.baseURL + this.endPoint.invoices + '/' + this.endPoint.runBatch);
      return {
        status: response.status,
        data: response.data,
      };
    } catch (error) {
      console.log(error);
    }
    return {};
  }
}

export default ContractService;
