import React from 'react';
import { FormattedMessage } from 'react-intl';
import ProductService from '../services/ProductService';
import AuthService from '../services/AuthService';
import Config from '../config';
import Constants from '../shared/constants';
import Routes from '../shared/Routes';
import Pagination from '../components/Pagination';
import { ProductListView } from '../components/Products';
import LoadingOverlay from 'react-loading-overlay';
import BaseComponent from '../components/BaseComponent';
import BounceLoader from 'react-spinners/BounceLoader';
import { FilterButton } from '../components/FilterData/Components/FilterButton';
import { FilterPopOver } from '../components/FilterData/FilterPopOver';
import ServiceCards from '../components/ServiceCards';
import SearchInput from '../components/Search';
//import CreateProductModal from "../components/Products/ModalView/CreateProductModal";
import ContractsActionButtons from '../components/ContractDetails/Components/ContractsActionButtons';
import CreateServiceModal from '../components/Products/CreateServiceModal';
import CreateProductmodal from '../components/Products/CreateProductmodal';
import Toast from '../components/Toast/index';
import EditProductModal from '../components/Products/EditProductModal';
import ImageModal from '../components/Products/ImageModal';

class Products extends BaseComponent {
  productService = new ProductService(Config.apiBase);

  authService = new AuthService(Config.apiBase);

  state = {
    isRun: false,
    doOpen: false,
    loading: false,
    products: [],
    parentServiceData: [],
    services: [],
    meta: {
      cursor: {
        previous: null,
        current: 0,
        next: 1,
        count: null,
        pageNumber: null,
      },
    },
    sortedBy: 'name',
    searchQuery: '',
    searchData: {
      id: 'name',
      value: '',
      component: 'search',
    },
    sortAscending: true,
    zoom: false,
    itemList: '',
    featureData: [
      {
        feature: 'Video Camera',
        description: 'Video Camera 180º View angle, auto-scale, full color',
      },
      {
        feature: 'Nightvision',
        description: 'Nightvision Full color for HD, and Infrared for Slim Line',
      },
      {
        feature: 'Audio',
        description: 'Audio Omni-directional microphone',
      },
      {
        feature: 'Motion sensor',
        description: 'Motion sensor Detects movement up to 2,4m distance',
      },
      {
        feature: 'Dimensions and weight',
        description: 'Dimensions and weight (W x D; Weight): 7.1 x 2.2 cm; 53.8g',
      },
      {
        feature: 'Required Power',
        description: 'Required Power Power: 8.36VAC, 10VA or 12VDC, 0.5 to 1.0A',
      },
    ],
    isFilterOpen: false,
    productsFilterData: [
      {
        id: 'category',
        multiGroup: true,
        component: 'checkBox',
        groups: [
          {
            label: 'Smarthjem & sikkerhet',
            id: 'category1',
            values: [
              Constants.language.filter_select_all,
              'Alarm',
              'Kamera',
              'Dør & vindu',
              'Lys & Varmestyring',
              'Velferd',
            ],
            defaultValues: [],
          },
          {
            label: 'Skap & nettverk',
            id: 'category2',
            values: [
              Constants.language.filter_select_all,
              'Media Rack',
              'Nettverksutstyr',
              'WiFi',
              'Kabel & Festematriell',
            ],
            defaultValues: [],
          },
          {
            label: 'Lyd & Bilde',
            id: 'category3',
            values: [
              Constants.language.filter_select_all,
              'Forsterkere & Subwoofer',
              'Integrerte Høyttalere',
              'TV Utstyr',
            ],
            defaultValues: [],
          },
          {
            label: 'El-produkter',
            id: 'category4',
            values: [
              Constants.language.filter_select_all,
              'Belysning',
              'Komfyrvakt',
              'El-bil ladere'
            ],
            defaultValues: []
          },
        ],
      },
      {
        id: 'price',
        component: 'rangeSlider',
        label: Constants.language.filter_price_range,
        min: 0,
        max: 5000,
        placeholderValues: [0, 5000],
        defaultValues: [],
      },
    ],
    toBeFilteredData: {},
    showToast: '',
    toastData: {},
    editItem: {},
    image: [],
    editProdutsModalShow: false,
    createProdutsModalShow: false,
    imageOpen: false,
  };

  constructor(props) {
    super(props);
    const isReseller = this.authService.isResellerUser();
    const { productsFilterData, toBeFilteredData } = this.state;
    if (!isReseller) {
      productsFilterData.push({
        id: 'status',
        component: 'checkBox',
        groups: [
          {
            label: 'Status',
            id: 'orderStatus',
            values: ['Aktiv', 'Ikke i salg', 'Ingen inkjøp', 'Inaktiv'],
            defaultValues: ['Aktiv'],
          },
        ],
      });
    }
    toBeFilteredData.status = ['Aktiv'];
  }

  async componentDidMount() {
    this.fetchProducts();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      meta: {
        cursor: { pageNumber },
      },
      sortedBy,
      sortAscending,
      toBeFilteredData,
    } = this.state;
    if (
      prevState.meta.cursor.pageNumber !== pageNumber ||
      prevState.sortedBy !== sortedBy ||
      prevState.sortAscending !== sortAscending
    ) {
      this.fetchProducts(toBeFilteredData);
    }
  }

  handleSearchOrders = async value => {
    const { toBeFilteredData, searchQuery } = this.state;
    if (value && value.length > 0) {
      if (toBeFilteredData.name && toBeFilteredData.name.length > 0) {
        toBeFilteredData.name = toBeFilteredData.name.filter(obj => obj !== searchQuery);
        toBeFilteredData.name.push(value);
      } else {
        toBeFilteredData.name = [value];
      }
    } else {
      delete toBeFilteredData.name;
    }
    let searchData = {
      id: 'name',
      value,
      component: 'search',
    };
    this.fetchProducts(toBeFilteredData, value);
    this.setState({ searchQuery: value, toBeFilteredData, searchData });
  };

  async fetchProducts(toBeFilteredData, searchInput) {
    const {
      meta: {
        cursor: { pageNumber },
      },
      sortedBy,
      searchQuery,
      sortAscending,
      toBeFilteredData: filterData,
    } = this.state;
    const queryParams = [
      ['pageNumber', searchInput ? 1 : pageNumber],
      ['sortedBy', sortedBy],
      ['sortAscending', sortAscending],
      ['filter', toBeFilteredData || filterData],
      ['allProducts', true]
    ];
    this.setState({ loading: true });

    let response;
    try {
      response = await this.productService.getAll(queryParams);

      if (response && response.status === 200) {
        let { status, products, meta } = response;
        if (products.length > 0) {
          if (this.state.searchQuery) {
            this.setState({ message: false });
          }
          this.setState({ products, meta });
        } else {
          if (this.state.searchQuery) {
            this.setState({
              searchQuery: undefined,
              message: true,
              unknown: true,
              products: [],
              meta,
            });
          }
        }
        this.setState({ loading: false });
      } else {
        this.setState({ loading: false, products: [] });
      }
      //   this.setState({
      //     products: JSON.parse(JSON.stringify(products)),
      //     meta: meta,
      //   });
      // }
      // this.setState({ loading: false });
    } catch (error) {
      console.log(error);
    }
    this.setState({ loading: false });
  }

  async fetchServices() {
    this.setState({ loading: true });
    let response;

    try {
      response = await this.productService.getServices({ queryParam: '' });
      let services = response.services;

      const childServices = services.filter(obj => obj.parent);
      // parent service
      var parentServices = services.filter(obj => !obj.parent);
      parentServices = parentServices.map(parent => {
        parent.children = childServices.filter(obj => obj.parent.id === parent.inventoryId);

        return parent;
      });

      this.setState({
        services: services,
        parentServiceData: parentServices,
      });
    } catch (error) {
      console.log(error);
    }

    this.setState({ loading: false });
  }

  handleSorting = (name, e) => {
    const { sortedBy, sortAscending } = this.state;
    if (sortedBy === name) {
      this.setState({ sortAscending: !sortAscending });
    } else {
      this.setState({ sortedBy: name, sortAscending: true });
    }
  };

  searchOrders = async value => {
    let response;
    try {
      response = await this.productService.searchProducts(value);
    } catch (e) {
      console.error(e);
    }

    if (response && response.status === 200) {
      const { status, products } = response;
      this.setState({ products });
    }
  };

  handleGotoPage = pageNumber => {
    this.setState({
      meta: {
        cursor: {
          pageNumber: pageNumber,
        },
      },
    });
  };

  productsArray = products =>
    products
      .map(project => project.itemClass)
      .filter(item => item && parseInt(item.id) > 9 && parseInt(item.id) !== 16)
      .filter((item, i, objects) => objects.find(obj => obj.id === item.id) === item);

  handleEditFeature = indexId => {
    const { featureData } = this.state;
    if (featureData[indexId].selected) {
      featureData[indexId].selected = false;
    } else {
      featureData[indexId].selected = true;
    }
    featureData.map((obj, index) => index !== indexId && (obj.selected = false));
    this.setState({ featureData });
  };
  handleOnFeatureChange = ({ index, type }, e) => {
    const { featureData } = this.state;
    featureData[index][type] = e.target.value;
    this.setState({ featureData });
  };

  // Product filter handles
  handleFitlerButton = () => {
    const { isFilterOpen } = this.state;

    this.setState({ isFilterOpen: !isFilterOpen });
  };

  handleFocusFilter = () => {
    document.getElementById('filter-pop-over-feature').focus();
  };
  handleOnClickValues = (obj, value) => {
    const { productCategoriesData } = this.state;
    const arrayIndex = productCategoriesData[obj].selectedValues.indexOf(value);
    if (arrayIndex > -1) {
      productCategoriesData[obj].selectedValues.splice(arrayIndex, 1);
    } else {
      productCategoriesData[obj].selectedValues.push(value);
    }
    this.setState({ productCategoriesData });
  };

  handleCancelFilter = () => {
    this.setState({ isFilterOpen: false });
  };

  handleApplyFilter = data => {
    let toBeFilteredData = {};
    data.map(obj => {
      if (toBeFilteredData[obj.id]) {
        toBeFilteredData[obj.id].push(obj.value);
      } else {
        toBeFilteredData[obj.id] = [];
        toBeFilteredData[obj.id].push(obj.value);
      }

      return obj;
    });

    this.setState({ toBeFilteredData, isFilterOpen: false });
    this.fetchProducts(toBeFilteredData, data);
  };

  handleClearFilter = () => {
    const { productCategoriesData } = this.state;
    const keys = Object.keys(productCategoriesData);
    keys.map(obj => (productCategoriesData[obj].selectedValues = []));
    this.setState({ productCategoriesData });
  };

  handleDeleteFeature = ({ index, type, value }) => {
    const { featureData } = this.state;
    featureData.splice(index, 1);
    this.setState({ featureData });
  };

  handleFileUploading = async ({ item }, event) => {
    let response;
    const array = this.fileListToArray(event.target.files);
    try {
      response = await this.productService.uploadFile(array[0], item.inventoryNumber, Constants.FileTypes.DOCUMENT);
    } catch (error) {
      console.log(error);
    }
    if (response.status) {
      const { products } = this.state;
      const {
        data: {
          data: { id, name, url },
        },
      } = response;
      const findedProduct = products.find(obj => obj.inventoryNumber === item.inventoryNumber);
      if (!findedProduct.media) {
        findedProduct.media = [];
      }
      findedProduct.media.push({
        file_id: id,
        file_name: name,
        file_url: url,
        file_type: Constants.FileTypes.DOCS,
      });
      this.setState({ products });
    }
  };

  fileListToArray(list) {
    const array = [];
    for (var i = 0; i < list.length; i++) {
      array.push(list.item(i));
    }
    return array;
  }

  handleFileDelete = ({ id, inventoryNumber }) => {
    const response = this.productService.removeProductDocument(id, inventoryNumber);
    const { products } = this.state;
    if (response.status === 200) {
      const findedProduct = products.find(obj => obj.inventoryNumber === inventoryNumber)['media'];
      findedProduct.findIndex(obj => obj.file_id);
    }
    const findedProduct = products.find(obj => obj.inventoryNumber === inventoryNumber)['media'];
    const index = findedProduct.findIndex(obj => obj.file_id === id);
    findedProduct.splice(index, 1);
    this.setState({ products });
  };

  handleReadMore = service => {
    const { services } = this.state;
    services.filter(obj => obj.inventoryId === service.inventoryId).map(obj => (obj.readMore = !obj.readMore));
    this.setState({ services });
  };

  handleOnActionClick = () => {};
  changeIsValidOnState = () => {
    const { addServices } = this.state;
    addServices.isValid = false;
    addServices.isFocus = false;
    this.setState({ addServices });
  };

  handleToUpdateProductService = async () => {
    await this.productService
      .getServices()
      .then(response => this.setState({ services: response.services }))
      .catch(e => console.error(e));
  };

  handleOnPopUpClick = ({ service }) => {
    const { parentServiceData } = this.state;
    const findedService = parentServiceData.find(data => data.inventoryId === service.inventoryId);
    if (!findedService) {
      parentServiceData.map(obj => {
        if (obj.children && obj.children.length > 0) {
          obj.children.map(item => {
            if (item.inventoryId === service.inventoryId) {
              item.isInfoOpened = !item.isInfoOpened;
            }
            return item;
          });
        }
        return obj;
      });
    }

    if (findedService) {
      findedService.isInfoOpened = !findedService.isInfoOpened;
    }
    this.setState({ parentServiceData });
  };

  handleServieToggle = ({ serviceData }) => {
    const { services } = this.state;
    if (serviceData) {
      let chosenService = services.find(obj => obj.inventoryId === serviceData.inventoryId);
      if (chosenService && chosenService.isExpanded) {
        chosenService.isExpanded = false;
      } else if (chosenService) {
        chosenService.isExpanded = true;
      }
      this.setState({ services });
    }
  };
  handleProductOrder = event => {
    const { history } = this.props;
    event.preventDefault();
    history.push(Routes.newOrderChooseCustomerProduct);
  };

  /*handleRunBatch = () => {
    this.setState({ isRun: true });
  };*/

  /* handlecreateproduct = async () => {
    this.setState({
      doOpen: !this.state.doOpen
    }); 
  };*/
  productResponse = data => {
    this.setState({ showToast: true, toastData: data });
    setTimeout(() => {
      this.setState({
        showToast: false,
      });
    }, data.delay);
    if (data.type === Constants.toast.SUCCESS) {
      this.fetchProducts();
    }
  };
  getEditData = editItem => {
    this.setState({ editItem });
  };
  getImage = image => {
    this.setState({ image });
  };
  renderProductListView = ({
    itemClasses,
    products,
    isReseller,
    isSuperAdmin,
    isAdminSales,
    isAdminBackOffice,
    featureData,
    sortAscending,
    sortedBy,
  }) => (
    <ProductListView
      items={products}
      sorting={false}
      forBasket={false}
      onItemClick={this.handleItemClick}
      onIncreaseItem={this.handleIncreaseItemQuanity}
      onDecreaseItem={this.handleDecreaseItemQuantity}
      onRemoveItem={this.handleRemoveItem}
      onQuantityUpdated={this.handleQuantityUpdated}
      onQuantityUpdatedBlur={this.onQuantityUpdatedBlur}
      onPriceChanged={this.handlePriceChanged}
      onRemoveToolTip={this.handleOnRemoveToolTip}
      isReseller={isReseller}
      isSuperAdmin={isSuperAdmin}
      isAdminSales={isAdminSales}
      isAdminBackOffice={isAdminBackOffice}
      featureData={featureData}
      editFeature={this.handleEditFeature}
      onFeatureChange={this.handleOnFeatureChange}
      deleteFeature={this.handleDeleteFeature}
      onSort={this.handleSorting}
      onFileUploading={this.handleFileUploading}
      onFileDelete={this.handleFileDelete}
      sortAscending={sortAscending}
      sortedBy={sortedBy}
      getEditData={this.getEditData}
      editProdutsModal={this.editProdutsModal}
      imageModal={this.imageModal}
      getImage={this.getImage}
    />
  );
  editProdutsModal = (editItem, event) => {
    this.setState({ editProdutsModalShow: true, editItem });
    event.preventDefault();
  };
  editProdutsModalClose = editItem => {
    this.setState({ editProdutsModalShow: false, editItem: [] });
  };

  openCreateproductmodal = () => {
    this.setState({ createProdutsModalShow: true }, () => {
      this.openElement.click();
    });
  };
  imageModalClose = image => {
    const { imageOpen } = this.state;
    this.setState({ imageOpen: false, image: [], zoom: false });
  };
  imageModalOpen = () => {
    this.setState({ imageOpen: true }, () => {
      this.openElement.click();
    });
  };
  imageModal = (image, event) => {
    const { imageOpen } = this.state;
    this.imageModalOpen();
    this.setState({ imageOpen: true, image });
    event.preventDefault();
  };
  handleProductSaving = () => {
    this.setState({ loading: true });
  };
  handleProductSaved = () => {
    this.setState({ loading: false });
  };
  createProdutsModalClose = () => {
    this.setState({ createProdutsModalShow: false });
  };
  zoomin = () => {
    this.state.zoom ? this.setState({ zoom: false }) : this.setState({ zoom: true });
  };
  render() {
    const isReseller = this.authService.isResellerUser();
    const isSuperAdmin = this.authService.isSuperAdminUser();
    const isAdminSales = this.authService.isAdminSalesUser();
    const isAdminBackOffice = this.authService.isAdminBackOfficeUser();
    const {
      products,
      loading,
      itemClasses,
      featureData,
      productsFilterData,
      isFilterOpen,
      meta,
      toBeFilteredData,
      parentServiceData,
      searchQuery,
      sortAscending,
      sortedBy,
      createProdutsModalShow,
      showToast,
      toastData,
      editItem,
      image,
      imageOpen,
      editProdutsModalShow,
      searchData,
      zoom,
    } = this.state;
    return (
      <React.Fragment>
        {showToast && (
          <Toast type={toastData.type} message={toastData.message} showToast={showToast} delay={toastData.delay} />
        )}
        <section id="page-header">
          <div className="container-fluid px-0">
            <div className=" mt-5 mb-4">
              <div className="row mb-4">
                <div className="col-12">
                  <h1 className="mb-2 pb-2 ls-0-25 line-height-normal">
                    <FormattedMessage id="all_products_header" />
                  </h1>
                  <p className="mb-0 line-height-1-43 ls-0-47 w-600-max">
                    <FormattedMessage id="all_products_description" />
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <>
                    <button
                      className="new-product-btn  btn btn-primary fs-14 mr-4 text-align-center line-height-16 w-207-min py-1 line-height-16 ls-1-27"
                      onClick={this.handleProductOrder}
                    >
                      <img src={Constants.icons.iconAddWhite} width="24" height="24" className="mr-1" alt="icon" />
                      <span className="va-middle font-weight-normal">
                        <FormattedMessage id="create_new_product_order" />
                      </span>
                    </button>
                    {(isSuperAdmin || isAdminBackOffice) && (
                      <>
                        <button
                          className="new-product-btn btn bg-white fs-14 py-1 line-height-16 ls-1-27"
                          onClick={this.openCreateproductmodal}
                        >
                          <img src={Constants.icons.iconAddBlack} width="24" height="24" className="mr-1" alt="icon" />
                          <span className="va-middle font-weight-normal">
                            <FormattedMessage id="create_add_product" />
                          </span>
                        </button>
                        <a
                          href="#"
                          data-toggle="modal"
                          data-target="#createproductmodal"
                          ref={openModal => (this.openElement = openModal)}
                        />
                      </>
                    )}
                  </>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="products" className="bg-green pb-3 loading-bg-height list-mb-50">
          <React.Fragment>
            <div id="products-content" className="collapse show">
              <div className="container-fluid mt-3 px-0">
                <div className=" ">
                  <div className="card card-rounded">
                    <div className="card-body list-view-card-body">
                      <div className="row">
                        <h4 className="mb-3 pl-3 font-weight-semibold line-height-normal ls-0-25">
                          <FormattedMessage id="new_order_select_item" />
                        </h4>
                      </div>
                      <div className={'row ' + (Object.keys(toBeFilteredData).length > 0 ? 'mb-4' : 'mb-4 pb-4')}>
                        <div className="col-5 mr-3 w-378-max p-0 ml-3">
                          <SearchInput
                            onSearch={this.handleSearchOrders}
                            containerStyle="h-44 border-2-ededed br-4"
                            iconStyle="h-100 bg-white-1"
                            value={searchQuery}
                          />
                        </div>
                        <div className="col-2 w-107-max p-0">
                          <FilterButton className="btn-grey-light btn mr-3 h-44" onClick={this.handleFitlerButton} />
                        </div>
                      </div>
                      <div className="filter-pop-over-container-products">
                        <FilterPopOver
                          isOpen={isFilterOpen}
                          options={productsFilterData}
                          onCancel={this.handleCancelFilter}
                          onApply={this.handleApplyFilter}
                          onClearFilters={this.handleClearFilter}
                          searchData={searchData}
                          onClearSearch={this.handleSearchOrders.bind(this, '')}
                        />
                      </div>

                      <div className="ri-table mb-3 px-0 fs-14 ls-0-44">
                        <LoadingOverlay
                          active={loading}
                          spinner={<BounceLoader />}
                          className="loading__overlay_white_wrapper loading-all-products"
                          text={<FormattedMessage id="common_loading_overlay" />}
                        >
                          {createProdutsModalShow && (
                            <CreateProductmodal
                              toUpdateProductService={this.handleToUpdateProductService}
                              productResponse={this.productResponse}
                              onProductSaving={this.handleProductSaving}
                              onProductSaved={this.handleProductSaved}
                              createProdutsModalClose={this.createProdutsModalClose}
                            />
                          )}
                          {!loading && products.length > 0
                            ? this.renderProductListView({
                                itemClasses,
                                products,
                                isReseller,
                                isSuperAdmin,
                                isAdminSales,
                                isAdminBackOffice,
                                featureData,
                                sortAscending,
                                sortedBy,
                              })
                            : !loading && (
                                <div className="container pt-3 ri-table">
                                  <React.Fragment>
                                    {!loading && products.length === 0 && (
                                      <div className="row justify-content-center mb-4">
                                        <p className="text-center">
                                          ! &nbsp;
                                          <FormattedMessage id="all_products_no_products" />
                                        </p>
                                      </div>
                                    )}
                                  </React.Fragment>
                                </div>
                              )}
                        </LoadingOverlay>
                      </div>
                      {!loading && products.length > 0 && (
                        <Pagination cursor={meta.cursor} gotoPage={this.handleGotoPage} />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        </section>

        <React.Fragment>
          {editProdutsModalShow && (
            <>
              <EditProductModal
                editItem={editItem}
                editProdutsModalShow={editProdutsModalShow}
                editProdutsModalClose={this.editProdutsModalClose}
                productResponse={this.productResponse}
              />
            </>
          )}
        </React.Fragment>
        <React.Fragment>
          {imageOpen && (
            <>
              <a
                href="#"
                data-toggle="modal"
                data-target="#imagemodal"
                ref={openModal => (this.openElement = openModal)}
              />
              <ImageModal
                zoomin={this.zoomin}
                zoom={zoom}
                image={image}
                imageOpen={imageOpen}
                imageModalClose={this.imageModalClose}
              />
            </>
          )}
        </React.Fragment>
      </React.Fragment>
    );
  }
}

export default Products;
