import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Select from '../Input/select';
import UserService from '../../services/UserService';
import ResellerService from '../../services/ResellerService';
import AuthService from '../../services/AuthService';
import Autosuggest from 'react-autosuggest';
import Constants from '../../shared/constants';
import LoadingOverlay from 'react-loading-overlay';
import BounceLoader from 'react-spinners/BounceLoader';
import { charLimit } from '../../shared/stringOperations';
import Config from '../../config';

import validator from 'validator';
import Input from '../Input/index';

import { Control, ValidationRule } from '../../shared/Control';

export default class UserModal extends Component {
  authService = new AuthService();
  userService = new UserService(Config.apiBase);
  resellerService = new ResellerService(Config.apiBase);
  isSuperAdmin = this.authService.isSuperAdminUser();
  isAdminSales = this.authService.isAdminSalesUser();
  isAdminBackOffice = this.authService.isAdminBackOfficeUser();
  isResellerLoggedIn = this.authService.isResellerUser();

  state = {
    user: { isValid: false, isFocus: false },
    isDisabled: false,
    isSubmitted: false,
    loggedResellerId: JSON.parse(localStorage.userData).visma_id,
    showErrors: false,
    selectedUserType: undefined,
    ismodalopen: false,
    loading: false,
    enterReseller: false,
    users: [],
    resellers: [],
    selectedCustomer: undefined,
    selectedCustomerIsValid: false,
    value: '',
    isValid: false,
    sameEmailErr: false,
    typeOptions: [
      {
        value: '1',
        placeholder: 'super-admin',
        label: 'Superadmin',
        roleName: 'super-admin',
      },
      {
        value: '2',
        placeholder: 'admin-backoffice',
        label: 'Admin Backoffice',
        roleName: 'admin-backoffice',
      },
      {
        value: '3',
        placeholder: 'admin-sales',
        label: 'Admin Sales',
        roleName: 'admin-sales',
      },
      {
        value: '4',
        placeholder: 'reseller',
        label: 'Reseller',
        roleName: 'reseller',
      },
    ],
  };

  constructor(props) {
    super(props);

    const selectControl = new Control({
      name: 'role',
      type: 'select',
      label: <FormattedMessage id="common_user_type" />,
      rule: new ValidationRule({
        method: validator.isEmpty,
        validWhen: false,
        message: <FormattedMessage id="warning_user_type" />,
      }),
      disabled: false,
      group: 'customerDetails',
      id: 'userType',
      column: 7,
      formGroup: 0,
      options: this.props.typeOptions,
      onBlur: this.handleUserDetailsBlur,
      onFocus: this.handleUserDetailsFocus,
      onChange: this.handleUserDetailsChange,
    });

    const user = [
      new Control({
        name: 'name',
        type: 'String',
        label: <FormattedMessage id="all_reseller_contact_person" />,
        rule: new ValidationRule({
          method: validator.isEmpty,
          validWhen: false,
          message: <FormattedMessage id="warning_contact_person" />,
        }),
        disabled: false,

        group: 'mainContact',
        id: 'mainContactPerson',
        formGroup: 0,
        column: 12,
        disabled: false,
        onBlur: this.handleUserDetailsBlur,
        onFocus: this.handleUserDetailsFocus,
        onChange: this.handleUserDetailsChange,
      }),
      new Control({
        name: 'phone',
        type: 'phone',
        label: <FormattedMessage id="ph_phone" />,
        rule: new ValidationRule({
          method: validator.isLength,
          validWhen: true,
          args: [{ min: 8, max: 8 }],
          message: <FormattedMessage id="warning_phone_number" />,
        }),

        group: 'mainContact',
        id: 'mainPhone',
        formGroup: 0,
        column: 12,
        onBlur: this.handleUserDetailsBlur,
        onFocus: this.handleUserDetailsFocus,
        onChange: this.handleUserDetailsChange,
      }),
      new Control({
        name: 'email',
        type: 'email',
        label: <FormattedMessage id="ph_email" />,
        rule: new ValidationRule({
          method: validator.isEmail,
          validWhen: true,
          message: <FormattedMessage id="warning_email" />,
        }),

        group: 'mainContact',
        id: 'mainEmail',
        formGroup: 0,
        column: 12,
        onBlur: this.handleUserDetailsBlur,
        onFocus: this.handleUserDetailsFocus,
        onChange: this.handleUserDetailsChange,
      }),
    ];

    if (!this.isResellerLoggedIn) {
      user.unshift(selectControl);
    }

    this.controls = { user: user };
    //document.getElementById('search-box').focus();
  }

  componentDidMount = () => {
    const { edit, userData, typeOptions } = this.props;
    const { user } = this.controls;
    if (edit && userData) {
      const { role = '', reseller = {} } = userData;
      const filterType = typeOptions.filter(o => o.placeholder === role);
      user[0].value = filterType[0].value || '';
      this.handleSuggestionSelected({}, { suggestion: reseller });
    }
  };

  handleEditUser = async event => {
    const { user } = this.controls;
    const { selectedCustomer,selectedCustomerIsValid } = this.state;
    const isValid = this.validate(user);
    if (!selectedCustomerIsValid) {
      this.setState({ enterReseller: true });
    }
    this.setState({ trigger: true });
    const { handleToast, typeOptions, userData } = this.props;
    if (isValid && selectedCustomerIsValid) {
      let roleIndex = typeOptions.findIndex(option => option.value === user[0].value);
      const roleName = typeOptions[roleIndex].placeholder;
      let payload = {
        reseller: { value: selectedCustomer.id },
        role: { value: roleName },
      };
      try {
        const response = await this.resellerService.editUser(userData._id, payload);
        if (response.status === 200) {
          this.setState({ isSubmitted: true, error: undefined, loading: false, openColumn: '' });
          handleToast({
            type: Constants.toast.SUCCESS,
            message: <FormattedMessage id="toast_user_edited" />,
            delay: Constants.toast.DELAY,
          });
          this.props.userCreate();
          this.closeElement.click();
        } else {
          this.setState({ error: response.data.message, loading: false });
          if (response.data.message === 'User already exist with same email id') {
            this.setState({ sameEmailErr: true, openColumn: '' });
          } else {
            handleToast({
              type: Constants.toast.ERROR,
              message: <FormattedMessage id="toast_user_unable_edit" />,
              delay: Constants.toast.DELAY,
            });
            this.props.userCreate();
            this.closeElement.click();
          }
        }
      } catch (error) {
        this.setState({ error: error, loading: false, openColumn: '' });
        handleToast({
          type: Constants.toast.ERROR,
          message: <FormattedMessage id="toast_user_unable_added" />,
          delay: Constants.toast.DELAY,
        });
        this.props.userCreate();
        this.closeElement.click();
      }
    }
    this.setState({ isSubmitted: false });
  };

  handleUserDetailsBlur = () => {
    let { user } = this.state;
    user.isFocus = false;
    this.setState({ user });
  };
  handleUserDetailsFocus = () => {
    let { user } = this.state;
    user.isFocus = true;
    user.isValid = this.validate(this.controls.user);
  };
  handleUserDetailsChange = async (name, options) => {
    let { user, value } = this.state;
    if (name === 'email') {
      this.setState({ sameEmailErr: false });
    }

    if (this.controls.user[0].name === name) {
      this.controls.user[0].value = options.value;
    }
    if (this.isResellerLoggedIn) {
      user.isValid = this.validate(this.controls.user);
    } else {
      user.isValid = this.validate(this.controls.user) && value.length > 0;
    }
    this.setState({ user });
  };

  handleCloseUserModal = () => {
    this.props.userModalClose();
  };
  onBlur = event => {
    this.setState({ enterReseller: true });
  };
  onChange = (event, { newValue }) => {
    let { user } = this.state;
    user.isValid = this.validate(this.controls.user) && newValue.length > 0;
    this.setState({ user, value: newValue });
  };
  resellerFocus = event => {};

  onSuggestionsFetchRequested = async ({ value }) => {
    if (value.length > 3 && !this.state.fetchingResellers) {
      this.setState({ fetchingResellers: true });
      const response = await this.resellerService.getAllUsers([['filter', { name: [value] }]]);
      const { resellers } = response;
      if (resellers && resellers.length > 0) {
        this.setState({ notFound: false });
      } else {
        this.setState({ notFound: true });
      }
      this.setState({ resellers, fetchingResellers: false });
    }
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {
    this.setState({ resellers: [] });
  };

  handleSuggestionSelected = (event, obj) => {
    if (obj.suggestion.id === Config.vismaId) {
      this.controls.user[0].options = this.state.typeOptions.filter(obj => obj.value !== '4');
      this.controls.user[0].disabled = false;
      this.controls.user[0].setValue(this.state.typeOptions.find(obj => obj.value === '1').value);
      if (this.isAdminBackOffice) {
        this.controls.user[0].setValue(this.state.typeOptions.find(obj => obj.value === '2').value);
        this.controls.user[0].options = this.state.typeOptions.filter(obj => obj.value == '2' || obj.value == '3');
      } else if (this.isAdminSales) {
        this.controls.user[0].setValue(this.state.typeOptions.find(obj => obj.value === '3').value);
        this.controls.user[0].options = this.state.typeOptions.filter(obj => obj.value == '3');
      }
      this.setState({
        isDisabled: false,
        selectedCustomer: obj.suggestion,
        selectedCustomerIsValid: true,
      });
    } else {
      this.controls.user[0].options = this.state.typeOptions;
      this.controls.user[0].setValue(this.state.typeOptions.find(obj => obj.value === '4').value);
      this.controls.user[0].disabled = true;
      this.setState({
        isDisabled: true,
        enterReseller:false,
        selectedCustomer: obj.suggestion,
        selectedCustomerIsValid: true,
      });
    }
  };

  onRemoveSelection = () => {
    this.controls.user[0].disabled = false;
    this.setState({
      selectedCustomer: undefined,
      value: '',
      isDisabled: false,
      selectedCustomerIsValid: false,
    });
  };

  handleCreateUser = async event => {
    const { user } = this.state;
    event.preventDefault();

    this.controls.user.map(e => {
      if (e.value.length === 0 && e.rule !== undefined) {
        e.isValid = false;
        e.showError = true;
      }
    });
    this.handleUserDetailsFocus();
    if (!this.state.selectedCustomerIsValid) {
      this.setState({ enterReseller: true });
    }
    // trigger State ToCall InputComponent;
    this.setState({ trigger: true });

    const { selectedCustomer, loggedResellerId } = this.state;
    const { handleToast } = this.props;
    const { typeOptions } = this.props;
    let selectedRoleValueIdx;
    let response;
    let payload;
    if (this.isResellerLoggedIn) {
      this.state.isValid = user.isValid;
    } else {
      this.state.isValid = user.isValid && this.state.selectedCustomerIsValid;
    }
    if (this.state.isValid) {
      this.setState({ isSubmitted: true });
      payload = this.controls.user.map((obj, index) => {
        if (obj.name === 'role') {
          selectedRoleValueIdx = typeOptions.findIndex(option => option.value === obj.value);
        }
        return obj.name === 'role'
          ? { name: obj.name, value: obj.options[selectedRoleValueIdx].placeholder }
          : { name: obj.name, value: obj.value };
      });

      if (this.isResellerLoggedIn) {
        Array.prototype.push.apply(payload, [{ name: 'vismaId', value: loggedResellerId }]);
        Array.prototype.push.apply(payload, [{ name: 'role', value: 'reseller' }]);
      } else {
        Array.prototype.push.apply(payload, [{ name: 'vismaId', value: selectedCustomer.id }]);
      }

      try {
        response = await this.resellerService.connectExistingReseller(payload);
        if (response.status === 201) {
          this.setState({ isSubmitted: true, error: undefined, loading: false });
          handleToast({
            type: Constants.toast.SUCCESS,
            message: <FormattedMessage id="toast_user_added" />,
            delay: Constants.toast.DELAY,
          });
          this.props.userCreate();
          this.closeElement.click();
        } else {
          this.setState({ error: response.data.message, loading: false });
          if (response.data.message === 'User already exist with same email id') {
            this.setState({ sameEmailErr: true });
          } else {
            handleToast({
              type: Constants.toast.ERROR,
              message: <FormattedMessage id="toast_user_unable_added" />,
              delay: Constants.toast.DELAY,
            });
            this.props.userCreate();
            this.closeElement.click();
          }
        }
      } catch (error) {
        this.setState({ error: error, loading: false });
        handleToast({
          type: Constants.toast.ERROR,
          message: <FormattedMessage id="toast_user_unable_added" />,
          delay: Constants.toast.DELAY,
        });
        this.props.userCreate();
        this.closeElement.click();
      }
      this.setState({ isSubmitted: false });
    }
  };

  renderInputElements = control => {
    const { isDisabled } = this.state;
    let classes = control.type === 'select' ? 'w-186-max px-0 col-' + control.column : 'px-0 col-' + control.column;
    classes = isDisabled && control.type === 'select' ? classes + ' opacity-50 ' : classes;
    return (
      <div className={classes} key={control.name}>
        {control.type === 'select' ? (
          <Select
            options={control}
            placeholder={control.label}
            key={control.name}
            value={control.value}
            autoFocus={true}
            onChange={control.onChange}
            ref={input => {
              control.ref = input;
            }}
          />
        ) : (
          <Input
            key={control.name}
            options={control}
            value={control.value}
            onChange={control.onChange}
            ref={input => {
              control.ref = input;
            }}
          />
        )}
      </div>
    );
  };

  validate(controls) {
    const { edit } = this.props;
    if (edit) {
      return controls.find(obj => obj.rule && !obj.isValid && obj.name === 'role') === undefined;
    }
    return controls.find(obj => obj.rule && !obj.isValid) === undefined;
  }

  render() {
    const { edit } = this.props;
    const {
      value,
      user,
      resellers,
      selectedCustomer,
      selectedCustomerIsValid,
      fetchingResellers,
      notFound,
      sameEmailErr,
      isSubmitted,
      enterReseller,
    } = this.state;
    let isValid = undefined;

    if (this.isResellerLoggedIn) {
      isValid = user.isValid;
    } else {
      isValid = user.isValid && selectedCustomerIsValid;
    }

    const inputProps = {
      placeholder: Constants.language.all_contracts_reseller,
      value,
      onChange: this.onChange,
      onBlur: this.onBlur,
      autoFocus: true,
      onFocus: this.resellerFocus,
    };

    const options = resellers.slice(0, 20).map(user => {
      return { id: user.number, name: user.name };
    });
    return (
      <>
        <div
          className="modal fade bd-example-modal-md fade-products"
          tabIndex="-1"
          data-backdrop="static"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
          id="customerModal"
        >
          <div className="modal-dialog modal-md modal-product">
            <div className="modal-content contracts-modal">
              <div className="modal-header modal-header-product">
                <h3 className="modal-title font-weight-semibold" id="myLargeModalLabel">
                  {edit ? <FormattedMessage id="user_edit_user" /> : <FormattedMessage id="new_user_create" />}
                </h3>

                <button
                  type="button"
                  className="close outline-0"
                  data-dismiss="modal"
                  aria-label="Close"
                  ref={closeModal => (this.closeElement = closeModal)}
                  onClick={this.handleCloseUserModal}
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body p-4  my-2 line-height-normal ls-normal">
                <>
                  <div className="row justify-content-between mb-3 ">
                    <div className="col-12">
                      <div className="row mt-0 mx-0 w-345-max">
                        <label className="Product-heading mb-3 fs-18 font-weight-semibold line-height-normal ls-normal">
                          <FormattedMessage id="new_user_details" />
                        </label>
                        {!this.isResellerLoggedIn && (
                          <React.Fragment>
                            <div className="col-12 px-0">
                              {!selectedCustomer && (
                                <div id="search-box" className="input-group input-common mb-4 h-48">
                                  <div className="form-control border-0 pl-0">
                                    <Autosuggest
                                      suggestions={options}
                                      onSuggestionSelected={this.handleSuggestionSelected}
                                      onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                      onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                      getSuggestionValue={option => option.name + ' - ' + option.id}
                                      renderSuggestion={suggestion => (
                                        <div className="margin-left-neg-8">
                                          {suggestion.name} - #{suggestion.id}
                                        </div>
                                      )} // ref={() => { // ref="autosuggest"
                                      //   React.findDOMNode(this.refs.autosuggest.ref.input).focus();
                                      // }}
                                      inputProps={inputProps}
                                    />
                                  </div>
                                  <div className="input-group-append search-icon z-index-4">
                                    <button className="btn bg-white py-1 mr-1 z-index-4" type="button">
                                      {this.state.fetchingResellers && (
                                        <div className="spinner-border spinner-border-sm" role="status">
                                          <span className="sr-only">
                                            <FormattedMessage id="common_loading_overlay" />
                                          </span>
                                        </div>
                                      )}
                                      {!fetchingResellers ? (
                                        value.length > 0 ? (
                                          <span className="float-right cursor-pointer" onClick={this.onRemoveSelection}>
                                            <img
                                              className="mr-2 cursor-pointer"
                                              src={Constants.icons.crossNew1}
                                              width="14"
                                              height="14"
                                              alt="trash-icon"
                                            />
                                          </span>
                                        ) : (
                                          <img src={Constants.icons.search} alt="search-icon" />
                                        )
                                      ) : (
                                        ''
                                      )}
                                    </button>
                                  </div>
                                </div>
                              )}
                              {enterReseller && !notFound && (
                                <div className="text-danger error-message small customer-suggest-error mt-1">
                                  <FormattedMessage id="warning_reseller" />
                                </div>
                              )}
                              {notFound && (
                                <div className="text-danger error-message small customer-suggest-error mt-1">
                                  <FormattedMessage id="reseller_search_error" />
                                </div>
                              )}
                              {value == undefined && (
                                <div className="text-danger error-message small customer-suggest-error mt-1">
                                  <FormattedMessage id="reseller_search_error" />
                                </div>
                              )}
                              {selectedCustomer && (
                                <div className="form-group animate-label common-input-container mb-4">
                                  <div className="form-control pb-2 input-common ">
                                    <div
                                      className="w-100 position-relative customer-reseller-selected pl-2"
                                      title={selectedCustomer.name + ', ' + selectedCustomer.id}
                                    >
                                      {/* <img
                                        className="ml-3 mr-2 pb-0"
                                        src={Constants.icons.credentialsDone}
                                        alt="trash-icon"
                                      /> */}
                                      {[charLimit.charLimit(selectedCustomer.name, 12), selectedCustomer.id].join(
                                        ',  '
                                      )}
                                      <span className="float-right cursor-pointer" onClick={this.onRemoveSelection}>
                                        <img
                                          className={'mx-2 cursor-pointer'}
                                          src={Constants.icons.crossNew1}
                                          width={'16'}
                                          height={'16'}
                                          alt="trash-icon"
                                        />
                                        {/* <img className="mr-2" src={Constants.icons.trash} alt="trash-icon" /> */}
                                      </span>
                                    </div>
                                  </div>
                                  <label className="form-control-placeholder ml-3 bg-white px-1 customer-reseller-label">
                                    <FormattedMessage id="all_contracts_reseller" />
                                  </label>
                                </div>
                              )}
                            </div>
                          </React.Fragment>
                        )}

                        {this.controls.user.map(control => {
                          if (edit) {
                            if (control.name === 'role') {
                              return this.renderInputElements(control);
                            }
                            return '';
                          }
                          return this.renderInputElements(control);
                        })}
                        {sameEmailErr && (
                          <div className="text-danger error-message small mt-0">
                            <FormattedMessage id="warning_email_already_exists" />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="mt-3">
                    <div className="d-inline-block">
                      <button
                        className={isValid ? 'btn-upg' : 'btn-pro'}
                        onClick={edit ? this.handleEditUser : this.handleCreateUser}
                        disabled={isSubmitted}
                      >
                        {' '}
                        <FormattedMessage id="create_save" />
                      </button>
                    </div>
                    <div className="d-inline-block ml-6">
                      <a
                        href="#"
                        className="font-weight-semibold ls-1-27 black line-height-114"
                        onClick={this.handleCloseUserModal}
                        data-dismiss="modal"
                      >
                        <u>
                          <FormattedMessage id="create_cancel" />
                        </u>
                      </a>
                    </div>
                  </div>
                </>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
