import React from 'react';
import { FormattedMessage } from 'react-intl';
import Constants from '../../shared/constants';
import Config from '../../config';
import CustomerService from '../../services/CustomerService';
import AuthService from '../../services/AuthService';
import CustomerDetailViewTab from '../Customers/Components/DetailView/CustomerDetailViewTab';
import moment from 'moment';
import CustomerDetailViewTabContent from '../Customers/Components/Tabs/CustomerDetailViewTabContent';
import CustomerModal from '../../components/Customers/CustomerModal';
import Toast from '../../components/Toast/index';
import LoadingOverlay from 'react-loading-overlay';
import BounceLoader from 'react-spinners/BounceLoader';
import BaseComponent from '../../components/BaseComponent';

class ResellerDetailView extends BaseComponent {
  resellerService = new CustomerService(Config.apiBase);
  authService = new AuthService(Config.apiBase);
  isResellerLoggedIn = this.authService.isResellerUser();

  constructor(props) {
    super(props);
    this.state = {
      resellerData: {},
      tab: 'Details',
      sortedBy: 'date',
      sortAscending: false,
      resellerDetails: [],
      ismodalopen: false,
      toastData: {},
      showToast: false,
      loading: false,
    };
  }
  async componentDidMount() {
    this.fetchResellerDetails();
  }

  async fetchResellerDetails() {
    const { loading } = this.state;

    if (!loading) {
      this.setState({ loading: true });
    }
    try {
      let response;
      const customerID = this.props.match.params.name;
      if (customerID) {
        response = await this.resellerService.getCustomerDetails(customerID);
        if (response) {
          this.currentTabUpdate(response);
        }
      }
      this.setState({ resellerData: response.data, loading: false });
    } catch (error) {
      console.log(error);
    }
    this.setState({ loading: false });
  }

  handleSorting = name => {
    if (this.state.sortedBy === name) {
      this.setState({ sortAscending: !this.state.sortAscending });
    } else {
      this.setState({ sortedBy: name, sortAscending: true });
    }
  };

  currentTab = async selectedtab => {
    if (selectedtab === 'Details' && this.state.tab !== 'Details') this.setState({ tab: selectedtab });
    this.setState({ serviceLoading: true });
    try {
      let response;
      const customerID = this.props.match.params.number;
      if (customerID) {
        response = await this.resellerService.getCustomerDetails(customerID);
        if (response) {
          this.currentTabUpdate(response);
        }
        this.setState({ serviceLoading: false });
      }
    } catch (error) {
      console.log(error);
    }
    this.setState({ tab: selectedtab });
  };

  currentTabUpdate = response => {
    this.setState({ resellerDetails: response.data });
  };

  handleEdit = () => {
    const { resellerData } = this.state;

    this.setState({ ismodalopen: true }, () => {
      this.openElement.click();
    });
  };

  handleCustomerModalClose = () => {
    this.setState({ ismodalopen: false });
  };

  handleToast = data => {
    this.setState({ showToast: true, toastData: data });
    setTimeout(() => {
      this.setState({
        showToast: false,
      });
    }, data.delay);
    if (data.type === Constants.toast.SUCCESS) {
      // this.fetchResellerDetails();
    }
  };

  handleCreateCustomer = response => {
    this.setState({ resellerData: response });
  };

  render() {
    const { resellerData, tab, ismodalopen, toastData, showToast, loading } = this.state;
    return (
      <>
        {' '}
        {showToast && (
          <Toast type={toastData.type} message={toastData.message} showToast={showToast} delay={toastData.delay} />
        )}
        <LoadingOverlay
          active={loading}
          spinner={<BounceLoader />}
          text={<FormattedMessage id="common_loading_overlay" />}
        >
          <section id=" page-header">
            <div className="container-fluid px-0">
              <div className=" mt-0">
                <div className="pl-0 mb-3">
                  <div className="row justify-content-end pr-0 mx-0">
                    <React.Fragment>
                      <div className="col text-right">
                        <a
                          href="#back"
                          className="font-weight-normal fs-14"
                          onClick={this.goBack}
                          // onClick={event => {
                          //   event.preventDefault();
                          //   if (this.props.history && this.props.history.length > 1) {
                          //     return this.props.history.push({ pathname: Constants.routes.resellers });
                          //   }
                          // }}
                        >
                          <img
                            className="mr-2"
                            src={Constants.icons.arrowBack}
                            width="24"
                            height="24"
                            alt="back-icon"
                          />

                          <FormattedMessage id="common_back" />
                        </a>
                      </div>
                    </React.Fragment>
                  </div>
                  <div className="row mb-3 ml-0">
                    <div className="col-10 p-0">
                      <div className="row mx-0">
                        <h1 className="mb-2 d-inline-block ls-0-25 line-height-normal">{resellerData.name}</h1>
                      </div>
                      <div className="row fs-14 ls-0-44 mx-0 line-height-normal">
                        <FormattedMessage id="customer_head_5" />:{' '}
                        {moment(resellerData.createdDateTime, 'YYYY-MM-DD').format('DD-MM-YYYY')}
                      </div>
                    </div>
                  </div>
                  <div className="row mx-0">
                    <CustomerDetailViewTab
                      currentTab={this.currentTab}
                      resellerloggedin={this.isResellerLoggedIn}
                      reseller={true}
                      customer={false}
                      tab={tab}
                    />
                  </div>
                  <div className="row mx-0">
                    {tab === 'Details' && (
                      <CustomerDetailViewTabContent
                        details={true}
                        customerID={this.props.match.params.name}
                        orderdetails={resellerData}
                        handleEdit={this.handleEdit}
                      />
                    )}
                    {tab === 'Orders' && (
                      <CustomerDetailViewTabContent
                        order={true}
                        customerID={this.props.match.params.name}
                        reseller={true}
                        sortedBy={Constants.sortingOptions.date}
                      />
                    )}
                    {tab === 'Subscriptions' && (
                      <CustomerDetailViewTabContent
                        subscriptions={true}
                        customerID={this.props.match.params.name}
                        reseller={true}
                        sortedBy={Constants.sortingOptions.activationDate}
                      />
                    )}
                    {tab === 'Invoices' && (
                      <CustomerDetailViewTabContent
                        invoice={true}
                        customerID={this.props.match.params.name}
                        reseller={true}
                        sortedBy={Constants.sortingOptions.invoiceDate}
                      />
                    )}
                    {tab === 'Customers' && (
                      <CustomerDetailViewTabContent
                        customer={true}
                        customerID={this.props.match.params.name}
                        reseller={true}
                        sortedBy={'date'}
                      />
                    )}
                    {tab === 'Users' && (
                      <CustomerDetailViewTabContent
                        user={true}
                        customerID={this.props.match.params.name}
                        reseller={true}
                        sortedBy={'date'}
                      />
                    )}
                    {tab === 'Log' && (
                      <CustomerDetailViewTabContent
                        log={true}
                        customerID={this.props.match.params.name}
                        reseller={true}
                        sortedBy={Constants.sortingOptions.logTime}
                      />
                    )}
                  </div>
                </div>
                <a
                  href="#"
                  data-toggle="modal"
                  data-target="#customerModal"
                  ref={openModal => (this.openElement = openModal)}
                />
                {ismodalopen && (
                  <CustomerModal
                    ismodalopen={ismodalopen}
                    customerModalClose={this.handleCustomerModalClose}
                    handleToast={this.handleToast}
                    edit={true}
                    reseller={true}
                    editData={resellerData}
                    customerCreate={this.handleCreateCustomer}
                  />
                )}
              </div>
            </div>
          </section>
        </LoadingOverlay>
      </>
    );
  }
}

export default ResellerDetailView;
