import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import moment from 'moment';
import OrderService from '../services/OrderService';
import Config from '../config';
import Constants from '../shared/constants';
import { AllOrderListView, AllOrderListViewHeader } from '../components/AllOrders';
import SearchInput from '../components/Search';
import Pagination from '../components/Pagination';
import { CSVLink } from 'react-csv';
import LoadingOverlay from 'react-loading-overlay';
import BaseComponent from '../components/BaseComponent';
import BounceLoader from 'react-spinners/BounceLoader';
import AuthService from '../services/AuthService';
import ContractService from '../services/ContractService';
import { FilterButton } from '../components/FilterData/Components/FilterButton';
import { FilterPopOver } from '../components/FilterData/FilterPopOver';
import { compose } from 'redux';
import Toast from '../components/Toast';

class Orders extends BaseComponent {
  orderService = new OrderService(Config.apiBase);
  authService = new AuthService(Config.apiBase);
  contractService = new ContractService(Config.apiBase);

  constructor(props) {
    super(props);
    const {
      location: { pathname },
    } = this.props;
    const pathName = pathname;
    this.state = {
      runbatch: false,
      orders: [],
      meta: {
        cursor: {
          previous: null,
          current: 0,
          next: 1,
          count: null,
        },
      },
      searchQuery: '',
      searchData: {
        id: 'customer',
        value: '',
        component: 'search',
      },
      showNext: true,
      message: false,
      csvDownloadData: [],
      loading: true,
      sortedBy: pathName === '/contracts' || pathName === '/contracts/' ? 'registeredDate' : 'date',
      sortAscending: false,
      isFilterOpen: false,
      ordersFilterData: [
        {
          id: 'startsWith',
          component: 'letterSelect',
          label: Constants.language.customer_head_1,
          values: [
            'A',
            'B',
            'C',
            'D',
            'E',
            'F',
            'G',
            'H',
            'I',
            'J',
            'K',
            'L',
            'M',
            'N',
            'O',
            'P',
            'Q',
            'R',
            'S',
            'T',
            'U',
            'V',
            'W',
            'X',
            'Y',
            'Z',
          ],
          defaultValues: [],
        },
        {
          id: 'type',
          component: 'checkBox',
          groups: [
            {
              label: Constants.language.all_orders_type,
              id: 'type',
              values: ['Produkter', 'Abonnement', 'Oppgradering', 'Oppsigelse'],
              defaultValues: [],
            },
          ],
        },
        {
          id: 'date',
          component: 'datePicker',
          label: Constants.language.all_orders_order_date,
          fromDate: {
            defaultDate: '',
          },
          toDate: {
            defaultDate: '',
          },
        },
        {
          id: 'status',
          component: 'checkBox',
          groups: [
            {
              label: 'Status',
              id: 'orderStatus',
              values: ['Registrert', 'Lukket', 'Kansellert', 'På vent', 'Sent'],
              defaultValues: [],
            },
          ],
        },
        {
          id: 'total',
          component: 'rangeSlider',
          label: Constants.language.order_amount,
          min: 0,
          max: 50000,
          placeholderValues: [0, 50000],
          defaultValues: [],
        },
      ],
      contractsFilterData: [
        {
          id: 'startsWith',
          component: 'letterSelect',
          label: Constants.language.customer_head_1,
          values: [
            'A',
            'B',
            'C',
            'D',
            'E',
            'F',
            'G',
            'H',
            'I',
            'J',
            'K',
            'L',
            'M',
            'N',
            'O',
            'P',
            'Q',
            'R',
            'S',
            'T',
            'U',
            'V',
            'W',
            'X',
            'Y',
            'Z',
          ],
          defaultValues: [],
        },

        {
          id: 'registeredDate',
          component: 'datePicker',
          label: Constants.language.filter_registered_date,
          fromDate: {
            defaultDate: '',
          },
          toDate: {
            defaultDate: '',
          },
        },
        {
          id: 'activationDate',
          component: 'datePicker',
          label: Constants.language.filter_activation_date,
          fromDate: {
            defaultDate: '',
          },
          toDate: {
            defaultDate: '',
          },
        },
        {
          id: 'status',
          component: 'checkBox',
          groups: [
            {
              label: 'Status',
              id: 'orderStatus',
              values: ['Registrert', 'Aktivert', 'Terminert', 'Kansellert'],
              defaultValues: ['Registrert', 'Aktivert'],
            },
          ],
        },
      ],
      selectedFilterData: [],
      toBeFilteredData: {},
      isBatchLoading: false,
      isRunBatchLoading: false,
      runBatchResponse: false,
      showToast: false,
      toastData: {},
    };
  }

  async componentDidMount() {
    const {
      location: { pathname: pathName },
    } = this.props;
    const { toBeFilteredData } = this.state;
    if (pathName === '/contracts' || pathName === '/contracts/') {
      toBeFilteredData.status = ['Registrert', 'Aktivert'];
    }
    await this.fetchOrders();
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      meta: {
        cursor: { pageNumber },
      },
      sortedBy,
      sortAscending,
      toBeFilteredData,
    } = this.state;
    const {
      location: { pathname: pathName },
    } = this.props;

    if (this.props.location !== prevProps.location) {
      const searchData = {
        id: 'customer',
        value: '',
        component: 'search',
      };
      const meta = {
        cursor: {
          previous: null,
          current: 0,
          next: 1,
          count: null,
        },
      };
      const toBeFilteredData =
        pathName === '/contracts' || pathName === '/contracts/' ? { status: ['Registrert', 'Aktivert'] } : {};

      this.setState({
        sortedBy: pathName === '/contracts' || pathName === '/contracts/' ? 'registeredDate' : 'date',
        isFilterOpen: false,
        sortAscending: false,
        searchData,
        meta,
        searchQuery: '',
        toBeFilteredData,
      });
    }

    if (
      prevState.meta.cursor.pageNumber !== pageNumber ||
      prevState.sortedBy !== sortedBy ||
      prevState.sortAscending !== sortAscending
    ) {
      if (pathName === '/contracts' || pathName === '/contracts/') {
        toBeFilteredData.status = ['Registrert', 'Aktivert'];
      } else {
        delete toBeFilteredData.status;
      }
      await this.fetchOrders(toBeFilteredData);
    }
  }

  handleGotoPage = pageNumber => {
    this.setState({
      meta: {
        cursor: {
          pageNumber: pageNumber,
        },
      },
    });
  };

  handleSearchOrders = async value => {
    const { toBeFilteredData, searchQuery, isContracts } = this.state;

    if (value && value.length > 0) {
      if ((typeof Number(value) === 'number' && !isNaN(Number(value))) || /\d/.test(value)) {
        toBeFilteredData.id = [value];
        let index = toBeFilteredData.customer ? toBeFilteredData.customer.findIndex(obj => obj === searchQuery) : -1;
        if (index > -1) {
          toBeFilteredData.customer.splice(index, 1);
        } else {
          delete toBeFilteredData.customer;
        }
      } else {
        if (toBeFilteredData.customer && toBeFilteredData.customer.length > 0) {
          toBeFilteredData.customer = toBeFilteredData.customer.filter(obj => obj !== searchQuery);
          toBeFilteredData.customer.push(value);
        } else {
          toBeFilteredData.customer = [value];
        }
        delete toBeFilteredData.id;
      }
    } else {
      let index = toBeFilteredData.customer ? toBeFilteredData.customer.findIndex(obj => obj === searchQuery) : -1;
      if (index > -1) {
        toBeFilteredData.customer.splice(index, 1);
      } else {
        delete toBeFilteredData.customer;
      }
      delete toBeFilteredData.id;
    }

    let searchData = {
      id: typeof Number(value) === 'number' && !isNaN(Number(value)) ? 'id' : 'customer',
      value,
      component: 'search',
    };
    this.setState({ searchQuery: value, toBeFilteredData, searchData });
    this.fetchOrders(toBeFilteredData, value);
  };
  async reload() {
    await this.fetchOrders();
  }
  async fetchOrders(toBeFilteredData, searchInput) {
    const {
      searchQuery,
      sortedBy,
      sortAscending,
      meta: {
        cursor: { pageNumber },
      },
      orders,
      runbatch,
      toBeFilteredData: filterData,
    } = this.state;
    const {
      location: { pathname },
    } = this.props;
    const isSuperAdmin = this.authService.isSuperAdminUser();
    const isAdminSales = this.authService.isAdminSalesUser();
    const isAdminBackOffice = this.authService.isAdminBackOfficeUser();

    const queryParams = [
      ['pageNumber', searchInput ? 1 : pageNumber],
      ['sortedBy', sortedBy],
      ['sortAscending', sortAscending],
      ['filter', toBeFilteredData || filterData],
    ];
    this.setState({ loading: true });
    let response;
    if (pathname === '/my-orders') {
      response = await this.orderService.myOrders(queryParams);
    } else if (pathname === '/contracts' || pathname === '/contracts/') {
      if (isSuperAdmin || isAdminSales || isAdminBackOffice) {
        response = await this.contractService.getAllContracts(queryParams);
      } else {
        response = await this.contractService.getAllResellerContracts(queryParams);
      }
    } else {
      response = await this.orderService.getAll(queryParams);
    }
    try {
      if (response && response.status === 200) {
        let { status, orders: responseOrders, meta, runbatch: enableBatch } = response;
        const showNext = this.canShowNext(orders, responseOrders, meta.cursor, runbatch, enableBatch);
        if (responseOrders.length > 0) {
          if (searchQuery) {
            this.setState({ message: false });
          }
          this.setState({
            orders: responseOrders,
            meta,
            showNext,
            runbatch: enableBatch,
          });
        } else {
          if (searchQuery) {
            this.setState({
              searchQuery: undefined,
              message: true,
              unknown: true,
              orders: [],
              runbatch: enableBatch,
            });
          } else {
            this.setState({ orders: [], runbatch: enableBatch });
          }
        }
      }
    } catch (e) {
      console.error(e);
    }
    this.setState({ loading: false });
  }

  handleDownloadCSV = async () => {
    const {
      sortedBy,
      sortAscending,
      meta: {
        cursor: { pageNumber },
      },
      toBeFilteredData,
    } = this.state;

    const queryParams = [
      ['sortedBy', sortedBy],
      ['sortAscending', sortAscending],
      ['filter', toBeFilteredData],
      ['export', true],
    ];
    this.setState({ loading: true });
    let response;

    try {
      response = await this.contractService.exportContracts(queryParams);
      this.setState({ csvDownloadData: Object.values(response.data), loading: false });
      if (response) {
        this.contractsCsv.link.click();
      }
    } catch (e) {
      console.error(e);
    }
    this.setState({ loading: false });
  };

  handleSorting = (name, e) => {
    const { sortAscending } = this.state;
    let sortedBy;
    if (this.state.sortedBy === name) {
      this.setState({ sortAscending: !sortAscending });
    } else {
      sortedBy = name;
      this.setState({ sortedBy, sortAscending: true });
    }
  };

  // Filter Data Handle
  handleFitlerButton = event => {
    const { isFilterOpen } = this.state;

    this.setState({ isFilterOpen: !isFilterOpen });
  };

  // handleClickEvent = event => {
  //   let targetElement = event.target;
  //   do {
  //     if (targetElement.id === 'filter-button' || targetElement.id === 'filter-pop-over-feature') {
  //       return;
  //     }
  //     targetElement = targetElement.parentNode;
  //   } while (targetElement);
  //   console.log('true');
  //   this.setState({ isFilterOpen: false });
  // };

  handleCancelFilter = () => {
    this.setState({ isFilterOpen: false });
  };

  // handleContractsFilterApply = data => {
  //   console.log(data);
  // };

  handleOrdersFilterApply = data => {
    const { searchQuery } = this.state;
    let toBeFilteredData = {};

    data.map(obj => {
      if (toBeFilteredData[obj.id]) {
        toBeFilteredData[obj.id].push(obj.value);
      } else {
        toBeFilteredData[obj.id] = [];
        toBeFilteredData[obj.id].push(obj.value);
      }

      return obj;
    });

    this.setState({ toBeFilteredData, isFilterOpen: false });
    this.fetchOrders(toBeFilteredData, data);
  };

  handleOnBack(event) {
    this.goBack(event);
  }

  handleTestBatch = async () => {
    this.setState({ isBatchLoading: true });
    const response = await this.contractService.testBatchService();
    const {
      data: { file },
    } = response;
    const fileUrlEncoded = encodeURI(file);

    //console.log(Config.backEndUrl, "Hello");
    window.open(Config.backEndUrl + '/' + fileUrlEncoded, '_self');
    this.setState({ isBatchLoading: false });
    this.reload();
  };

  handleRunBatch = async () => {
    this.setState({ isRunBatchLoading: true });
    const response = await this.contractService.runBatchService();
    if (response.status === 200) {
      const {
        data: { file },
      } = response;
      if (file) {
        window.open(Config.backEndUrl + '/' + encodeURI(file), '_self');
      }
      this.setState({ runBatchResponse: true });
    } else {
      this.setState({ runBatchResponse: false });
    }
    this.setState({ isRunBatchLoading: false });
    this.reload();
  };

  handleDeleteOrder = (orderId) => async () => {
    this.setState({loading : true });
    try {
      const response = await this.orderService.deleteOrder(orderId);
      if(response) {
        this.setState({loading: false});
        this.handleToast(<FormattedMessage id="order_action_delete_order_success_message" />, Constants.toast.SUCCESS);
        this.fetchOrders();
      }
    } catch (e) {
      this.setState({loading: false});
      this.handleToast(<FormattedMessage id="order_action_delete_order_failed_message" />, Constants.toast.ERROR);
    }
  }
  
  handleRetryOrder = (orderId) => async () => {
    this.setState({loading : true });
    try {
      const response = await this.orderService.retryOrder(orderId);
      if(response) {
        this.setState({loading: false});
        this.handleToast(<FormattedMessage id="order_action_retry_order_success_message" />, Constants.toast.SUCCESS);
        this.fetchOrders();
      }
    } catch (e) {
      this.setState({loading: false});
      this.handleToast(<FormattedMessage id="order_action_retry_order_failed_message" />, Constants.toast.ERROR);
    }
  } 

  handleToast = (data, type) => {
    let toastData = {
      type: type,
      message: data,
      delay: Constants.toast.LONG_DELAY,
    };

    this.setState({ showToast: true, toastData });
    setTimeout(() => {
      this.setState({
        showToast: false,
      });
    }, toastData.delay);
  };

  render() {
    const {
      orders,
      meta,
      runbatch,
      loading,
      sortAscending,
      sortedBy,
      isFilterOpen,
      ordersFilterData,
      contractsFilterData,
      isBatchLoading,
      toBeFilteredData,
      toastData,
      showToast,
      searchData,
      isRunBatchLoading,
      runBatchResponse,
      csvDownloadData,
    } = this.state;
    const isSuperAdmin = this.authService.isSuperAdminUser();
    const isAdminBackOffice = this.authService.isAdminBackOfficeUser();
    const isAdminSales = this.authService.isAdminSalesUser();
    const {
      location: { pathname: pathName },
    } = this.props;
    let isContracts = false;

    if (pathName === '/contracts' || pathName === '/contracts/') {
      isContracts = true;
    }

    return (
      <React.Fragment>
        {showToast && (
          <Toast type={toastData.type} message={toastData.message} showToast={showToast} delay={toastData.delay} />
        )}
        <CSVLink
          data={csvDownloadData}
          filename={'contracts.csv'}
          className="d-none"
          ref={r => (this.contractsCsv = r)}
        />
        <section id="page-header">
          <div className="container-fluid px-0">
            <div className="mt-5">
              <div className="row mb-3">
                <div className="col-auto">
                  <h1 className="mb-1 ls-0-25 line-height-normal">
                    {pathName === '/my-orders' && <FormattedMessage id="bc_my_orders" />}
                    {pathName === '/orders' && <FormattedMessage id="common_orders" />}
                    {pathName === '/contracts' && <FormattedMessage id="all_contracts_header" />}
                  </h1>
                  <p className="line-height-22 mb-2 ls-0-47 w-600-max">
                    {pathName === '/contracts' ? (
                      <FormattedMessage id="all_contracts_description" />
                    ) : (
                      <FormattedMessage id="all_orders_description" />
                    )}
                  </p>
                </div>

                {isContracts && (
                  <React.Fragment>
                    {/* <div className="col text-right">
                      <a href="#back" className="font-weight-normal fs-14" onClick={this.handleOnBack.bind(this)}>
                        <img className="mr-2" src={Constants.icons.arrowBack} width="18" height="18" alt="back-icon" />

                        <FormattedMessage id="common_back" />
                      </a>
                    </div> */}
                  </React.Fragment>
                )}
              </div>
              {!isContracts && (
                <div className="row ml-0 mb-4">
                  <div className="w-180-min mr-4">
                    <Link
                      to={'new-order/choose-customer/product'}
                      className={'btn btn-primary h-44 pt-2 pb-1 line-height-16 ls-1-27'}
                    >
                      <img src={Constants.icons.iconAddWhite} width="24" height="24" className="mr-1" alt="icon" />

                      <span className="va-middle font-weight-normal">
                        <FormattedMessage id="bc_new_order_products" />
                      </span>
                    </Link>
                  </div>
                  <div className="w-180-min">
                    <Link
                      to={'new-order/choose-customer/service'}
                      className={'btn btn-primary h-44 pt-2 pb-1 line-height-16 ls-1-27'}
                    >
                      <img src={Constants.icons.iconAddWhite} width="24" height="24" className="mr-1" alt="icon" />

                      <span className="va-middle font-weight-normal">
                        <FormattedMessage id="bc_new_subscription" />
                      </span>
                    </Link>
                  </div>
                </div>
              )}
              {isContracts && (
                <div className="row mb-4 ml-0">
                  <div className="w-180-min ">
                    <Link
                      to={'new-order/choose-customer/service'}
                      className={'btn btn-primary h-44 line-height-16 ls-1-27 border-0'}
                    >
                      <img src={Constants.icons.iconAddWhite} width="24" height="24" className="mr-1" alt="icon" />

                      <span className="va-middle font-weight-normal">
                        <FormattedMessage id="bc_new_subscription" />
                      </span>
                    </Link>
                  </div>
                  <div className="col pl-4">
                    {isSuperAdmin && (
                      <>
                        <button
                          className="batch-button border br-4 btn button-for-loader contracts-action-add-service mr-4 h-44"
                          onClick={this.handleTestBatch}
                          disabled={isBatchLoading}
                        >
                          {isBatchLoading ? (
                            <BounceLoader size={25} color={'#000'} />
                          ) : (
                            <FormattedMessage id="common_test_batch" />
                          )}
                        </button>
                        <button
                          className="batch-button border br-4 btn"
                          data-toggle="modal"
                          data-target="#batch-run-dialog-modal"
                          disabled={!this.state.runbatch}
                        >
                          <FormattedMessage id="common_run_batch" />
                        </button>
                        {(isSuperAdmin || isAdminBackOffice) && (
                          <button className="batch-button border ml-4 br-4 btn" onClick={this.handleDownloadCSV}>
                            <FormattedMessage id="common_export" />
                          </button>
                        )}
                      </>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>

        <section id="products" className="bg-green pb-3 loading-bg-height list-mb-50">
          <React.Fragment>
            <div id="products-content" className="collapse show">
              <div className="container-fluid mt-3 px-0">
                <div className="">
                  <div className="card card-rounded">
                    <div className="card-body list-view-card-body">
                      <div className="row">
                        <h4 className="mb-3 pl-3 font-weight-semibold line-height-normal ls-0-25">
                          {isContracts ? (
                            <FormattedMessage id="all_contracts_overview" />
                          ) : (
                            <FormattedMessage id="all_orders_overview" />
                          )}
                        </h4>
                      </div>
                      <div className={'row ' + (Object.keys(toBeFilteredData).length > 0 ? 'mb-4' : 'mb-4 pb-4')}>
                        <div className="col-5 mr-4 w-378-max p-0 ml-3">
                          <SearchInput
                            onSearch={this.handleSearchOrders}
                            containerStyle="h-44 border-2-ededed br-4"
                            iconStyle="h-100"
                          />
                        </div>

                        <div className="col-2 w-107-max p-0">
                          <FilterButton
                            className="btn-grey-light btn py-2 mr-3 text-black filter-btn-text"
                            onClick={this.handleFitlerButton}
                            onBlur={this.handleBlur}
                          />
                        </div>
                      </div>
                      <div className="">
                        <FilterPopOver
                          isOpen={isFilterOpen}
                          onApply={this.handleOrdersFilterApply}
                          onCancel={this.handleCancelFilter}
                          options={isContracts ? contractsFilterData : ordersFilterData}
                          searchData={searchData}
                          onClearSearch={this.handleSearchOrders.bind(this, '')}
                        />
                      </div>

                      <div className="ri-table mb-3 fs-16 ls-0-44">
                        <LoadingOverlay
                          active={loading}
                          spinner={<BounceLoader />}
                          className="loading__overlay_white_wrapper loader-list-view-common"
                          text={<FormattedMessage id="common_loading_overlay" />}
                        >
                          {orders.length > 0 && !loading ? (
                            <>
                              <div className="fs-14">
                                <AllOrderListViewHeader
                                  onSort={this.handleSorting}
                                  sortAscending={sortAscending}
                                  pathName={pathName}
                                  sortedBy={sortedBy}
                                  isContracts={isContracts}
                                />
                                <AllOrderListView
                                  orders={orders}
                                  sorting={true}
                                  onSort={this.handleSorting}
                                  onRetryOrder={this.handleRetryOrder}
                                  onDeleteOrder={this.handleDeleteOrder}
                                  isSuperAdmin={isSuperAdmin}
                                  isAdminBackOffice={isAdminBackOffice}
                                  isAdminSales={isAdminSales}
                                  pathName={pathName}
                                  isContracts={isContracts}
                                  sortAscending={sortAscending}
                                  sortedBy={sortedBy}
                                />
                              </div>
                            </>
                          ) : (
                            !loading && (
                              <React.Fragment>
                                <div className="container pt-3 ri-table ">
                                  <div className="row justify-content-center mb-4">
                                    <p className="text-center">
                                      ! &nbsp;
                                      <FormattedMessage id="my_orders_no_orders_yet" />
                                    </p>
                                  </div>
                                </div>
                              </React.Fragment>
                            )
                          )}
                        </LoadingOverlay>
                      </div>
                      {!loading && orders.length > 0 && (
                        <Pagination cursor={meta.cursor} gotoPage={this.handleGotoPage} />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
          <div
            className="modal fade bd-example-modal-sm fade-contracts"
            id="batch-run-dialog-modal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="batchRunDialogTitle"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-small" role="document">
              <div className="modal-content contracts-modal bg-green rounded border-0">
                <div className="modal-header pl-5 pb-3">
                  <h4 className="modal-title font-weight-semibold" id="myLargeModalLabel">
                    <span>
                      <FormattedMessage id="common_run_batch" />
                    </span>
                  </h4>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>

                <div className="modal-body mx-5 rounded p-0 mb-4 mt-2">
                  <div className="">
                    <LoadingOverlay
                      active={isRunBatchLoading}
                      spinner={<BounceLoader />}
                      text={<FormattedMessage id="common_loading_overlay" />}
                    >
                      <div className="row py-4">
                        <div className="col">
                          <h6 className="line-height-28">
                            <FormattedMessage id="run_batch_message" />
                          </h6>
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col">
                          <button
                            className="btn-upg"
                            data-toggle="modal"
                            data-target="#batch-run-modal"
                            onClick={this.handleRunBatch}
                            data-dismiss="modal"
                          >
                            <FormattedMessage id="run_batch_button" />
                          </button>
                        </div>
                        <div className="col text-left mt-2">
                          <a
                            href="#"
                            className="font-weight-semibold ls-1-27 black line-height-114"
                            aria-label="Close"
                            data-dismiss="modal"
                          >
                            <u>
                              <FormattedMessage id="create_cancel" />
                            </u>
                          </a>
                        </div>
                      </div>
                    </LoadingOverlay>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal fade bd-example-modal-sm fade-contracts"
            id="batch-run-modal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="batchRunTitle"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-small" role="document">
              <div className="modal-content contracts-modal bg-green rounded border-0">
                <div className="modal-header pl-5 pt-4 pb-3">
                  <h4 className="modal-title font-weight-semibold" id="myLargeModalLabel">
                    <span>
                      <FormattedMessage id="common_run_batch" />
                    </span>
                  </h4>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>

                <div className="modal-body mx-5 rounded p-0 mb-5 mt-2 py-5">
                  <div className="">
                    <LoadingOverlay
                      active={isRunBatchLoading}
                      spinner={<BounceLoader />}
                      text={<FormattedMessage id="common_loading_overlay" />}
                    >
                      <div className="row">
                        <div className="col-12 text-center">
                          {runBatchResponse ? (
                            <h6>
                              <FormattedMessage id="common_run_batch_response_success" />
                            </h6>
                          ) : (
                            <h6>
                              <FormattedMessage id="common_run_batch_response_failed" />
                            </h6>
                          )}
                        </div>
                      </div>
                    </LoadingOverlay>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

Orders.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object,
};

export default Orders;
