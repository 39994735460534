import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import LoadingOverlay from 'react-loading-overlay';
import BounceLoader from 'react-spinners/BounceLoader';
import Constants from '../../../shared/constants';
import moment from 'moment';

const ContractDetailsHead = ({
  contractDetails,
  loading,
  editItems,
  isSuperAdmin,
  isAdminSales,
  isAdminBackOffice,
}) => {
  return (
    <>
      {loading && <div className="pt-2 mt-5" />}
      <LoadingOverlay
        active={loading}
        spinner={<BounceLoader />}
        text={<FormattedMessage id="common_loading_overlay" />}
      >
        <div className="row m-0 p-4 bg-white mb-4 color-1d1d1d ">
          <div className="col-6 p-0">
            <h5 className="pl-2">
              <FormattedMessage id="contract_contract_details" />
            </h5>

            <div className="contracts-details-tab row">
              <div className="col-5 p-0 font-weight-semibold">
                <FormattedMessage id="common_order_contract_no" /> <FormattedMessage id="all_contracts_contract_id" />:
              </div>
              <div className="col-7">
                {' '}
                <span>{contractDetails && contractDetails.contractNo}</span>
              </div>
            </div>
            <div className="contracts-details-tab row">
              <div className="col-5 p-0 font-weight-semibold">
                <FormattedMessage id="customer_name" />:
              </div>
              <div className="col-7 text-truncate" title={contractDetails.customer && contractDetails.customer.name}>
                {' '}
                <span>{contractDetails.customer && contractDetails.customer.name}</span>
              </div>
            </div>
            <div className="contracts-details-tab row">
              <div className="col-5 p-0 font-weight-semibold">
                <FormattedMessage id="common_customer_ID" />:
              </div>
              <div className="col-7">
                {' '}
                <span>#{contractDetails.customer && contractDetails.customer.number}</span>
              </div>
            </div>
            <div className="contracts-details-tab row">
              <div className="col-5 p-0 font-weight-semibold">
                {' '}
                <span>Alarm.com ID:</span>
              </div>
              <div className="col-7 text-truncate">
                {' '}
                <span>{contractDetails && contractDetails.alarmId}</span>
              </div>
            </div>
            <div className="contracts-details-tab row">
              <div className="col-5 p-0 font-weight-semibold">
                {' '}
                <FormattedMessage id="all_orders_order_status" />:
              </div>
              <div className="col-7">
                {' '}
                <span>{contractDetails.status}</span>
              </div>
            </div>
            <div className="contracts-details-tab row">
              <div className="col-5 p-0 font-weight-semibold">
                <FormattedMessage id="filter_registered_date" />:
              </div>
              <div className="col-7">
                {' '}
                <span>
                  {moment(String(contractDetails.registeredDate).slice(0, 10)).format('DD-MM-YYYY') === 'Invalid date'
                    ? 'N/A'
                    : moment(String(contractDetails.registeredDate).slice(0, 10)).format('DD-MM-YYYY')}
                </span>
              </div>
            </div>
            <div className="contracts-details-tab row">
              <div className="col-5 p-0 font-weight-semibold">
                <FormattedMessage id="filter_activation_date" />:
              </div>
              <div className="col-7">
                {' '}
                <span>
                  {moment(String(contractDetails.activationDate).slice(0, 10)).format('DD-MM-YYYY') === 'Invalid date'
                    ? 'N/A'
                    : moment(String(contractDetails.activationDate).slice(0, 10)).format('DD-MM-YYYY')}
                </span>
              </div>
            </div>
            <div className="contracts-details-tab row">
              <div className="col-5 p-0 font-weight-semibold">
                <FormattedMessage id="filter_cancellation_date" />:
              </div>
              <div className="col-7">
                {' '}
                <span>
                  {moment(String(contractDetails.cancellationDate).slice(0, 10)).format('DD-MM-YYYY') === 'Invalid date'
                    ? 'N/A'
                    : moment(String(contractDetails.cancellationDate).slice(0, 10)).format('DD-MM-YYYY')}
                </span>
              </div>
            </div>
            <div className="contracts-details-tab row">
              <div className="col-5 p-0 font-weight-semibold">
                <FormattedMessage id="filter_termination_date" />:
              </div>
              <div className="col-7">
                {' '}
                <span>
                  {moment(String(contractDetails.terminationDate).slice(0, 10)).format('DD-MM-YYYY') === 'Invalid date'
                    ? 'N/A'
                    : moment(String(contractDetails.terminationDate).slice(0, 10)).format('DD-MM-YYYY')}
                </span>
              </div>
            </div>
          </div>
          <div className="col-6 p-0">
            <div>
              <h5 className="d-inline-block pl-2">
                <FormattedMessage id="contract_location_details" />
              </h5>
              {(isSuperAdmin || isAdminBackOffice) && (
                <span className="p-0 cursor-pointer float-right d-inline-block" onClick={editItems}>
                  <u className="mr-2 color-1d1d1d fs-14 font-weight-normal">
                    <FormattedMessage id="contract_button_edit_service" />
                  </u>
                  <img className="mr-2" src={Constants.icons.materialEdit} width="16" height="16" alt="back-icon" />
                </span>
              )}
              <div className="contracts-details-tab row">
                <div className="col-5 p-0 font-weight-semibold">
                  <FormattedMessage id="common_installation_address" />:
                </div>
                <div className="col-7">
                  {' '}
                  <span className="text-nowrap-normal">
                    {contractDetails.soInstallationAddress &&
                      (contractDetails.soInstallationAddress.addressLine1 &&
                        contractDetails.soInstallationAddress.addressLine1 + ' ')}
                  </span>
                  <br />
                  <span>
                    {contractDetails.soInstallationAddress.postalCode +
                      ' ' +
                      contractDetails.soInstallationAddress.city}
                  </span>
                </div>
              </div>
              <div className="contracts-details-tab row">
                <div className="col-5 p-0 font-weight-semibold">
                  <FormattedMessage id="customer_details_contact_person" />:
                </div>
                <div
                  className="col-7 text-truncate"
                  title={
                    contractDetails.mainContact &&
                    (contractDetails.mainContact.attention || contractDetails.mainContact.name)
                  }
                >
                  {' '}
                  <span>
                    {contractDetails.mainContact &&
                      (contractDetails.mainContact.attention || contractDetails.mainContact.name)}
                  </span>
                </div>
              </div>
              <div className="contracts-details-tab row">
                <div className="col-5 p-0 font-weight-semibold">
                  <FormattedMessage id="ph_phone" />:
                </div>
                <div className="col-7">
                  {' '}
                  <span>{contractDetails.mainContact && contractDetails.mainContact.phone1}</span>
                </div>
              </div>
              <div className="contracts-details-tab row">
                <div className="col-5 p-0 font-weight-semibold">
                  <FormattedMessage id="ph_email" />:
                </div>
                <div
                  className="col-7 text-truncate"
                  title={contractDetails.mainContact && contractDetails.mainContact.email}
                >
                  {' '}
                  <span>{contractDetails.mainContact && contractDetails.mainContact.email}</span>
                </div>
              </div>
              <div className="contracts-details-tab row">
                <div className="col-5 p-0 font-weight-semibold">
                  <FormattedMessage id="ph_location_name" />:
                </div>
                <div className="col-7">
                  {' '}
                  <span className="text-nowrap-normal">
                    {contractDetails.soInstallationAddress && contractDetails.soInstallationAddress.locationName}
                  </span>
                </div>
              </div>
              <div className="contracts-details-tab row">
                <div className="col-5 p-0 font-weight-semibold">
                  <FormattedMessage id="ph_billing_reference" defaultMessage="Billing refrence" />:
                </div>
                <div className="col-7">
                  {' '}
                  <span className="text-nowrap-normal">
                    {contractDetails.customerOrder && contractDetails.customerOrder.value}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LoadingOverlay>
    </>
  );
};

ContractDetailsHead.propTypes = {
  contractDetails: PropTypes.object,
};

export default ContractDetailsHead;
