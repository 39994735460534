import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Constants from '../../shared/constants';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import renderHTML from 'react-render-html';
import { charLimit } from '../../shared/stringOperations';

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const ProductAccordionBody = ({
  item,
  isExpanded,
  isSuperAdminUser,
  isAdminSales,
  isAdminBackOffice,
  onFilesAdded,
  findMediaLength,
  featureData,
  specifications,
  editFeature,
  onFeatureChange,
  onFileUploading,
  onFileDelete,
  isTextAreaEnabled,
  handleTextArea,
  handleTextAreaChange,
  productBrief,
  saveTextArea,
  closeTextArea,
  result,
  editProdutsModal,
  imageModal,
}) => {
  return (
    <React.Fragment>
      <div className={'row ri-list-row-details br-2 bg-green text-truncate  ' + (isExpanded ? ' pb-0' : '')}>
        <div
          id={'product-' + item.inventoryId}
          className={'col collapse ' + (isExpanded ? 'show p-0' : '')}
          data-parent=".accordion"
        >
          <div className={'border-0 justify-content-between pb-4 mx-4 pr-2 mt-3 row fs-14 image-test'}>
            {(!item.media || (item.media && findMediaLength(item.media, Constants.FileTypes.IMAGE) === 0)) && (
              <div className="imageCropped mr-2">
                <div
                  className="image-crop-no-image"
                  style={{ backgroundImage: `url("${Constants.rackitLogoBlackBG}")`, backgroundSize: 'contain' }}
                />
              </div>
            )}
            {item.media && findMediaLength(item.media, Constants.FileTypes.IMAGE) > 0 && (
              <div className="imageCropped carousel mr-2">
                <OwlCarousel
                  className="owl-carousel owl-theme"
                  margin={10}
                  center={true}
                  items={1}
                  nav={true}
                  navText={[
                    "<div class='nav-btn prev-slide fa-angle-left fs-20'></div>",
                    "<div class='nav-btn next-slide fa-angle-right fs-20'></div>",
                  ]}
                  dotsClass={' owl-dots sliderDots'}
                >
                  {item.media
                    .filter(obj => obj.file_type === Constants.FileTypes.IMAGE)
                    .map((obj, index) => (
                      <>
                        {imageModal ? (
                          <a onClick={imageModal.bind(this, obj)}>
                            <div
                              key={index}
                              className="image-crop-no-image"
                              style={{ backgroundImage: `url("${obj.file_url}")`, backgroundSize: 'contain' }}
                            />
                          </a>
                        ) : (
                          <div
                            key={index}
                            className="image-crop-no-image"
                            style={{ backgroundImage: `url("${obj.file_url}")`, backgroundSize: 'contain' }}
                          />
                        )}
                      </>
                    ))}
                </OwlCarousel>
              </div>
            )}
            <div className="col ml-4 p-0">
              <Tabs className="tab-container-custom ml-0 pl-0 " selectedTabClassName="tab-selected-custom">
                <div className="row m-0">
                  <TabList className="tab-tablist-custom px-3 mb-0 col mr-3">
                    <Tab className="tab-title-custom">
                      <FormattedMessage id="all_products_product_snapshot" />
                    </Tab>
                    <Tab className="tab-title-custom">
                      <FormattedMessage id="all_products_technical_data" />
                    </Tab>
                    <Tab className="tab-title-custom">
                      <FormattedMessage id="create_documents" />
                    </Tab>
                  </TabList>
                  {editProdutsModal && (isSuperAdminUser || isAdminBackOffice) && (
                    <>
                      <a
                        href="#"
                        onClick={editProdutsModal.bind(this, item)}
                        className="fs-14 text-align-center col-2 p-0 text-right"
                      >
                        <span className="redigere ">
                          <u>
                            <FormattedMessage id="contract_button_edit_service" />
                          </u>
                        </span>
                        <img alt="" src={Constants.icons.edit} width="14" height="14" className="ml-2 opacity-0-7" />
                      </a>
                    </>
                  )}
                </div>
                <TabPanel className="react-tabs-1 ml-3">
                  <div className="ml-0 mt-2">
                    <div className="col color-1d1d1d font-weight-semibold fs-14 row ls-0-25">{item.description}</div>
                    {(isSuperAdminUser || isAdminSales || isAdminBackOffice) && (
                      <>
                        {!isTextAreaEnabled && (
                          <div className="row m-0 mt-2">
                            {item.productBrief && (
                              <>
                                <div className="col-12 p-0 ql-editor ql-editor-change text-justify">
                                  {item.productBrief && (
                                    <div className="color-1d1d1d ls-0-25">{renderHTML(item.productBrief)}</div>
                                  )}
                                </div>
                              </>
                            )}
                            <div className="col p-0">
                            {item.elNumber &&
                              (item.elNumber !== 'undefined' ? (
                                <p className="m-0 p-0">
                                  <b><FormattedMessage id="create_El_nr" /></b> : {item.elNumber}
                                </p>
                              ) : (
                                ''
                              ))}
                            
                            {item.installationTime !== 0 &&
                                <p className="m-0 p-0">
                                  <b><FormattedMessage id="installation_time" /></b> : {item.installationTime + ' ' + Constants.language.proposal_installation_hours_label}
                                </p>
                            }
                              </div>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </TabPanel>
                <TabPanel className="react-tabs-2 mx-3 color-1d1d1d">
                  {item.specifications ? (
                    item.specifications.map((obj, index) => (
                      <div className="row border-bottom product-feature-container pl-3" key={index}>
                        <div className="col-4 p-0">
                          <span>{obj.spec}</span>
                        </div>
                        <div className="col-8">
                          <span>{obj.description}</span>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="row file-upload-product-head">
                      <div className="col font-weight-light text-black fs-14 color-1d1d1d">
                        <FormattedMessage id="all_products_no_features_data" />
                      </div>
                    </div>
                  )}
                </TabPanel>
                <TabPanel className="react-tabs-3 ">
                  {item.media ? (
                    item.media.filter(obj => obj.file_type === Constants.FileTypes.DOCS).length === 0 && (
                      <div className="row file-upload-product-head mt-2">
                        <div className="col font-weight-light text-black ml-3 fs-14 color-1d1d1d">
                          <FormattedMessage id="all_products_no_documents" />
                        </div>
                      </div>
                    )
                  ) : (
                    <div className="row file-upload-product-head mt-2">
                      <div className="col font-weight-light text-black ml-3 fs-14 color-1d1d1d">
                        <FormattedMessage id="all_products_no_documents" />
                      </div>
                    </div>
                  )}
                  {item.media &&
                    item.media.map((obj, index) => (
                      <React.Fragment key={index}>
                        {obj.file_type === Constants.FileTypes.DOCS && (
                          <div className="row file-upload-product-row ml-0 fs-14">
                            <div className="border-bottom col-11 product-feature-container py-2">
                              <div className="row justify-content-between pl-3">
                                <div>
                                  <img
                                    src={Constants.icons.fileDescription}
                                    alt="file-icon"
                                    className="file-icon-products mr-2"
                                  />
                                  <a href={obj.file_url} target="_blank">
                                    <u title={obj.file_name}>{charLimit.charLimit(obj.file_name, 58)}</u>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </React.Fragment>
                    ))}
                </TabPanel>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

ProductAccordionBody.propTypes = {
  item: PropTypes.object,
  isExpanded: PropTypes.bool,
  isSuperAdminUser: PropTypes.bool,
  isAdminSales: PropTypes.bool,
  isAdminBackOffice: PropTypes.bool,
  onFilesAdded: PropTypes.func,
  findMediaLength: PropTypes.func,
  featureData: PropTypes.array,
  editFeature: PropTypes.func,
  onFeatureChange: PropTypes.func,
  onFileUploading: PropTypes.func,
  onFileDelete: PropTypes.func,
};

export default ProductAccordionBody;
