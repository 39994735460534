import rackitHeaderLogo from '../assets/images/rackit-logo.png';
import noImage from '../assets/images/no-img.jpeg';
import rackitLogoBlackBG from '../assets/images/RackitLogoBlackBG.svg';
//icons
import phone from '../assets/images/icons/icon-phone.svg';
import apps from '../assets/images/icons/icon-apps.svg';
import translate from '../assets/images/icons/icon-translate.svg';
import translateBlack from '../assets/images/icons/icon-translate-black.svg';
import editicon from '../assets/images/icons/edit.svg';
import personAdd from '../assets/images/icons/icon-person-add.svg';
import menu from '../assets/images/icons/icon-menu.svg';
import group from '../assets/images/icons/icon-group.svg';
import circlesAdd from '../assets/images/icons/icon-circles-add.svg';
import list from '../assets/images/icons/icon-list.svg';
import arrow from '../assets/images/icons/icon-arrow.svg';
import search from '../assets/images/icons/icon-search.svg';
import accArrow from '../assets/images/icons/icon-accordion-arrow.svg';
import accArrowBlack from '../assets/images/icons/icon-accordion-arrow-black.svg';
import wavingHandSign from '../assets/images/icons/icon-waving-hand-sign.png';
import trash from '../assets/images/icons/icon-delete.svg';
import tick from '../assets/images/icons/icon-tick.svg';
import tickWhite from '../assets/images/icons/tick-icon-white.svg';
import tickBlack from '../assets/images/icons/icon-tick-black.svg';
import tickGreen from '../assets/images/icons/check-icon-green.svg';
import cross from '../assets/images/icons/icon-cross.svg';
import crossGrey from '../assets/images/icons/cross-grey.svg';
import crossBlack from '../assets/images/icons/icon-cross-black.svg';
import arrowRight from '../assets/images/icons/icon-arrow-right.svg';
import done from '../assets/images/icons/icon-done.svg';
import circle from '../assets/images/icons/icon-circle.svg';
import circleDisabled from '../assets/images/icons/icon-circle-disabled.svg';
import triangle from '../assets/images/icons/triangle.svg';
import edit from '../assets/images/icons/edit.svg';
import greentick from '../assets/images/icons/icon-tick-green.svg';
import redcross from '../assets/images/icons/icon-cross-red.svg';
import xCrossRed from '../assets/images/icons/x-cross-red.svg';
import circleBlack from '../assets/images/icons/icon-circle-black.svg';
import activated from '../assets/images/icons/icon-activated.png';
import terminated from '../assets/images/icons/icon-terminated.png';
import pending from '../assets/images/icons/icon-pending.png';
import filterIcon from '../assets/images/icons/filter_icon.svg';
import fileIcon from '../assets/images/icons/file-icon.svg';
import deleteIcon from '../assets/images/icons/trash-icon.svg';
import newEdit from '../assets/images/icons/pencil-edit.svg';
import crossNew1 from '../assets/images/icons/cross-new-1.svg';
import productTray from '../assets/images/icons/product-icon-tray.svg';
import subscriptionTray from '../assets/images/icons/subscription-icon-tray.svg';
import searchDark from '../assets/images/icons/search-icon-dark.svg';
import infoIconGreen from '../assets/images/icons/info-icon-green.svg';
import loginPageBG from '../assets/images/login-bg.png';
import credentialsDone from '../assets/images/icons/icon-credentials-done.svg';
import plusIconBlack from '../assets/images/icons/plus-icon-black.svg';
import plusIconWhite from '../assets/images/icons/plus-icon-white.svg';
import materialEdit from '../assets/images/icons/edit-material.svg';
import arrowBack from '../assets/images/icons/arrow-back-icon.svg';
import arrowUp from '../assets/images/icons/arrow-up-icon.svg';
import fileDescription from '../assets/images/icons/file-description.svg';
import infoBlack from '../assets/images/icons/info-black-icon.svg';
import pendingIcon from '../assets/images/icons/update-pending.svg';
import dot from '../assets/images/icons/dot.svg';
import document from '../assets/images/icons/icon-document.svg';
import image from '../assets/images/icons/icon-image.svg';
import selectArrow from '../assets/images/icons/icon-select-drop-down.svg';
import crossFilled from '../assets/images/icons/icon-cross-red-filled.svg';
import tickFilled from '../assets/images/icons/icon-tick-green-filled.svg';
import tickIconGreen from '../assets/images/icons/tickicon-green.svg';
import terminateIcon from '../assets/images/icons/terminateicon.svg';
import updatePending from '../assets/images/icons/update-pending-color.svg';
import pendingActivation from '../assets/images/icons/pending-activation.svg';
import languagesIcon from '../assets/images/icons/icon-languages.svg';
import dashboardSubscriptions from '../assets/images/icons/dashboard-subscription.svg';
import dashboardOrders from '../assets/images/icons/dashboard-orders.svg';
import dashboardIncome from '../assets/images/icons/dashboard-income.svg';
import dashboardUsers from '../assets/images/icons/dashboard-users.svg';
import dashboardDelete from '../assets/images/icons/dashboard-message-delete.svg';
import dashboardEdit from '../assets/images/icons/dashboard-message-edit.svg';
import pdfIcon from '../assets/images/icons/pdf_icon.png'

import menuClose from '../assets/images/icons/menu-close.svg';
import menuOpen from '../assets/images/icons/menu-open.svg';
import expandMore from '../assets/images/icons/sidemenu-expand-more.svg';

import shome from '../assets/images/icons/sidemenu-home.svg';
import sOrder from '../assets/images/icons/sidemenu-orders.svg';
import sSubscription from '../assets/images/icons/sidemenu-subscription.svg';
import sUsers from '../assets/images/icons/sidemenu-customer.svg';
import sInventory from '../assets/images/icons/sidemenu-product.svg';
import sDocuments from '../assets/images/icons/sidemenu-documents.svg';
import sCustomer from '../assets/images/icons/sidemenu-users.svg';
import sDatabaseAdmin from '../assets/images/icons/sidemenu-database.svg';
import sProposalInActive from '../assets/images/icons/Proposal_inactive.png';

import sHomeActive from '../assets/images/icons/sidemenu-home-active.svg';
import sCustomerActive from '../assets/images/icons/sidemenu-customer-active.svg';
import sSubscriptionActive from '../assets/images/icons/sidemenu-subscription-active.svg';
import sOrderActive from '../assets/images/icons/sidemenu-orders-active.svg';
import sUsersActive from '../assets/images/icons/sidemenu-users-active.svg';
import sInventoryActive from '../assets/images/icons/sidemenu-product-active.svg';
import sDocumentsActive from '../assets/images/icons/sidemenu-documents-active.svg';
import sDatabaseAdminActive from '../assets/images/icons/sidemenu-database-active.svg';
import sProposalActive from '../assets/images/icons/Proposal_active.png';

import logoutIcon from '../assets/images/icons/icon-logout.svg';
import userLoginIcon from '../assets/images/icons/icon-user-login.svg';
import paginationLeft from '../assets/images/icons/paginationLeft.svg';
import paginationRight from '../assets/images/icons/paginationRight.svg';
import iconAddWhite from '../assets/images/icons/icon-add-white.svg';
import iconAddBlack from '../assets/images/icons/icon-add-black.svg';
import Routes from './Routes';

//File Types

import Config from '../config';
import { FileTypes } from './FileTypes';

//Order Status
import { ORDER_STATUS } from './OrderStatus';
import { CustomerType } from './CustomerType';

const language = localStorage.getItem('language')
  ? localStorage.getItem('language') === 'en'
    ? require('../translations/en.json')
    : require('../translations/no.json')
  : require('../translations/no.json');

const Constants = {
  ApplicationName: 'Rackit',
  TitleDivder: ' | ',
  language: localStorage.getItem('language')
    ? localStorage.getItem('language') === 'en'
      ? require('../translations/en.json')
      : require('../translations/no.json')
    : require('../translations/no.json'),
  routes: Routes,
  end_points: {
    v1: {
      getAllProducts: `${Config.apiBase}/api/products`,
      signIn: `${Config.apiBase}/api/users/login`,
      userDetails: `${Config.apiBase}/api/users`,
      generateResetLink: `${Config.apiBase}/api/users/generate-reset-link`,
      resetPassword: `${Config.apiBase}/api/users/reset-password`,
    },
  },
  pageTitle: {
    products: 'Rackit | Products',
    signin: 'Rackit | Sign In',
    home: 'Rackit | Home',
    resellerDashboard: 'Rackit | Dashboard',
    adminDashboard: 'Rackit | Dashboard',
    createNewReseller: 'Rackit | Create New Reseller',
    forgotPassword: 'Rackit | Forgot password',
    allResellers: 'Rackit | Resellers',
    allOrders: 'Rackit | Orders',
    newOrder: 'Rackit | New Order',
    resetPassword: 'Rackit | Reset Password',
    newReseller: 'Rackit | Reseller',
  },
  rackitHeaderLogo,
  noImage,
  rackitLogoBlackBG,
  loginPageBG,
  icons: {
    apps,
    translate,
    translateBlack,
    editicon,
    personAdd,
    menu,
    group,
    circlesAdd,
    list,
    phone,
    search,
    accArrow,
    accArrowBlack,
    arrow,
    wavingHandSign,
    trash,
    tick,
    tickBlack,
    tickGreen,
    cross,
    crossBlack,
    arrowRight,
    tickIconGreen,
    terminateIcon,
    done,
    credentialsDone,
    circle,
    circleDisabled,
    triangle,
    edit,
    greentick,
    redcross,
    xCrossRed,
    circleBlack,
    activated,
    terminated,
    pending,
    filterIcon,
    crossGrey,
    fileIcon,
    deleteIcon,
    tickWhite,
    newEdit,
    crossNew1,
    productTray,
    subscriptionTray,
    searchDark,
    infoIconGreen,
    plusIconBlack,
    plusIconWhite,
    materialEdit,
    arrowBack,
    arrowUp,
    fileDescription,
    infoBlack,
    pendingIcon,
    dot,
    document,
    image,
    selectArrow,
    crossFilled,
    tickFilled,
    updatePending,
    logoutIcon,
    userLoginIcon,
    pendingActivation,
    languagesIcon,
    paginationLeft,
    paginationRight,
    iconAddWhite,
    iconAddBlack,
    dashboardSubscriptions,
    dashboardOrders,
    dashboardIncome,
    dashboardUsers,
    dashboardDelete,
    dashboardEdit,
    pdfIcon,
  },
  SideMenu: {
    menuClose,
    menuOpen,
    expandMore,
    shome,
    sOrder,
    sSubscription,
    sCustomer,
    sInventory,
    sDocuments,
    sUsers,
    sDatabaseAdmin,
    sHomeActive,
    sCustomerActive,
    sSubscriptionActive,
    sOrderActive,
    sInventoryActive,
    sUsersActive,
    sDocumentsActive,
    sDatabaseAdminActive,
    sProposalActive,
    sProposalInActive,
  },
  config: {
    apiHeaderJSON: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  },
  storage: {
    token: 'token',
    userData: 'user',
    roles: 'roles',
  },
  role: {
    admin: 'Admin',
    reseller: 'Reseller',
  },

  currency: {
    norway: ',-',
    NorwayKrone: 'NOK/mnd',
  },

  publicPages: function() {
    return [this.routes.signin, this.routes.forgotPassword, this.routes.resetPassword];
  },
  apiParams: {
    signin: {
      email: 'email',
      password: 'password',
    },
  },

  imageTypes: [
    'image/png',
    'image/jpg',
    'image/gif',
    'image/jpeg',
    'image/ppm',
    'image/tiff',
    'image/pgm',
    'image/pnm',
    'image/svg',
    'image/svg+xml',
  ],
  inputAcceptDocumentTypes: '.pdf,.txt,.doc,.html,.htm,.docx,.odt,.ods,.ppt,.pptx,.xls,.xlsx',
  inputAcceptImageTypes: '.png,.jpg,.gif,.jpeg,.ppm,.tiff,.pgm,.pnm,.svg',
  imageMimeType: 'image/*',
  FileTypes,
  ORDER_STATUS,
  LOADER_PROPS: {
    COLOR: {
      white: '#fff',
    },
    SIZE: 60,
    SIZE_UNIT: 'px',
  },
  filterOptions: {
    components: {
      checkBox: 'checkBox',
      datePicker: 'datePicker',
      rangeSlider: 'rangeSlider',
      search: 'search',
    },
    label: {
      Produkter: language.filter_all_products,
      Abonnement: language.all_orders_type_new_sub,
      Oppgradering: language.all_orders_type_upgrade_sub,
      Oppsigelse: language.all_orders_type_termination_sub,
      Registrert: language.all_orders_status_open,
      Lukket: language.all_order_status_closed,
      Kansellert: language.all_orders_status_cancelled,
      'På vent': language.all_order_status_hold,
      Registrert: language.all_contracts_status_registered,
      Aktivert: language.all_contracts_status_activated,
      Terminert: language.all_contracts_status_terminated,
      Aktiv: language.all_products_status_active,
      'Ingen inkjøp': language.all_products_status_no_purchases,
      'Ikke i salg': language.all_products_status_no_sales,
      Inaktiv: language.all_products_status_in_active,
      NoRequest: language.all_products_status_no_request,
      'Välj samtliga': language.filter_select_all,
      1: language.filter_private,
      4: language.filter_business,
      5: language.filter_reseller,
      Active: language.all_products_status_active,
      Inactive: language.all_products_status_in_active,
    },
  },
  sortingOptions: {
    contractNo: 'contractNo',
    orderNo: 'orderNo',
    customerName: 'customer.name',
    registeredDate: 'registeredDate',
    type: 'type',
    activationDate: 'activationDate',
    date: 'date',
    status: 'status',
    logTime: 'logTime',
    invoiceDate: 'invoiceDate',
    referenceNumber: 'referenceNumber',
    dueDate: 'dueDate',
    amount: 'orderTotal',
    changeType: 'changeType',
    description: 'description',
    proposalNo: 'proposalNo',
  },
  orderItemStatus: {
    active: 'Active',
    activated: 'Activated',
    registered: 'Registered',
    terminated: 'Terminated',
    cancelled: 'Cancelled',
    pendingTermination: 'Pending Termination',
    pendingActivation: 'Pending Activation',
  },
  contractModalType: {
    terminate: 'terminate',
    terminateAll: 'terminateAll',
    activate: 'activate',
    activateAll: 'activateAll',
    renew: 'renew',
    cancel: 'cancel',
    editPrice: 'editPrice',
    editActivate: 'editActivate',
    editTerminate: 'editTerminate',
    parent: 'parent',
    child: 'child',
    editBillingPeriod: 'editBillingPeriod',
  },
  toast: {
    SHORT_DELAY: 2000,
    DELAY: 5000,
    LONG_DELAY: 3500,
    SUCCESS: 'Success',
    ERROR: 'Error',
  },
  customerClass: {
    1: CustomerType.PRIVATE_CUSTOMER,
    4: CustomerType.BUSINESS_CUSTOMER,
    5: CustomerType.RESELLER,
  },
  taxPercentage: 0.25,
  installation: {
    yes: 'yes',
    no: 'no',
    hours: 'hours',
    price: 'price',
  },
  orderTypes: {
    proposalProduct: 'proposal-product',
    proposalService: 'proposal-service',
    service: 'service',
    product: 'product',
  },
};
export default Constants;
