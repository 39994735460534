import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import LoadingOverlay from 'react-loading-overlay';
import BounceLoader from 'react-spinners/BounceLoader';
import Constants from '../../shared/constants';
import validator from 'validator';
import Select from '../Input/select';
import Input from '../Input/index';
import { Control, ValidationRule } from '../../shared/Control';
import Autosuggest from 'react-autosuggest';
import { charLimit } from '../../shared/stringOperations';
import PostalInput from '../PostalInput';
import { CheckBox } from '../CheckBox/CheckBox';
import ResellerService from '../../services/ResellerService';
import Config from '../../config';
import { object } from 'prop-types';

export class CustomerModal extends Component {
  state = {};

  constructor(props) {
    super(props);

    this.resellerService = new ResellerService(Config.apiBase);
    this.state = {
      customer: { isValid: false, isFocus: false },
      billing: { isValid: false, isFocus: false },
      shipping: { isValid: false, isFocus: false },
      mainContact: { isValid: false, isFocus: false },
      billingIsSameAsMain: false,
      shippingIsSameAsMain: false,
      sendInvoice: true,
      collectiveInvoice: false,
      isSubmitted: false,
      showErrors: false,
      enterReseller: false,
      billingAsMain: false,
      ismodalopen: false,
      corporateIdError: false,
      phoneNoError: false,
      emailError: false,
      loading: false,
      resellers: [],
      data: [],
      customerClass: { Bedriftskunde: 'Bedriftskunder', Privatkunde: 'Privatkunder', Forhandler: 'Forhandler' },
      selectedCustomer: '',
      value: '',
      selectedCustomerIsValid: false,
      resellerHide: false,
      activeOptions: [
        { value: 'Active', label: Constants.language.all_products_status_active },
        { value: 'Inactive', label: Constants.language.create_inactive },
        { value: 'OnHold', label: Constants.language.on_hold },
      ],
      typeOptions: [
        { value: '1', label: Constants.language.customer_type_private },
        { value: '4', label: Constants.language.new_order_business_customer },
        { value: '5', label: Constants.language.all_contracts_reseller },
      ],
    };
    const customer = [
      new Control({
        name: 'name',
        type: 'string',
        label: <FormattedMessage id="customer_name" />,
        rule: new ValidationRule({
          method: validator.isEmpty,
          validWhen: false,
          message: <FormattedMessage id="warning_customer_name" />,
        }),
        group: 'customerDetails',
        id: 'customerName',
        formGroup: 0,
        column: 12,
        classProps: 'w-378-max',
        onBlur: this.handleCustomerDetailsBlur,
        onFocus: this.handleCustomerDetailsFocus,
        onChange: this.handleCustomerDetailsChange,
      }),
      new Control({
        name: 'type',
        type: 'select',
        label: Constants.language.warning_customer_type,
        rule: new ValidationRule({
          method: validator.isEmpty,
          validWhen: false,
          message: <FormattedMessage id="warning_customer_type" />,
        }),
        group: 'customerDetails',
        id: 'customerType',
        column: 6,
        formGroup: 0,
        classProps: 'w-180-max',
        options: this.state.typeOptions,
        onBlur: this.handleCustomerDetailsBlur,
        onFocus: this.handleCustomerDetailsFocus,
        onChange: this.handleCustomerDetailsChange,
      }),
      new Control({
        name: 'corporateId',
        type: 'phone',
        label: <FormattedMessage id="corporate_id" />,
        rule: new ValidationRule({
          method: validator.isLength,
          validWhen: true,
          args: [{ min: 9, max: 9 }],
          message: <FormattedMessage id="warning_corporate_id" />,
        }),
        id: 'customerCorporateId',
        column: 6,
        formGroup: 0,
        classProps: 'w-180-max corporateId',
        isValid: true,
        onBlur: this.handleCustomerDetailsBlur,
        onFocus: this.handleCustomerDetailsFocus,
        onChange: this.handleCustomerDetailsChange,
      }),
      new Control({
        name: 'status',
        type: 'select',
        label: <FormattedMessage id="all_orders_order_status" />,
        rule: new ValidationRule({
          method: validator.isEmpty,
          validWhen: false,
          message: <FormattedMessage id="warning_status" />,
        }),
        group: 'customerDetails',
        id: 'customerStatus',
        column: 6,
        classProps: 'w-180-max',
        formGroup: 0,
        options: this.state.activeOptions,

        onBlur: this.handleCustomerDetailsBlur,
        onFocus: this.handleCustomerDetailsFocus,
        onChange: this.handleCustomerDetailsChange,
      }),
    ];

    const subDealer = [
      new Control({
        name: 'subDealerId',
        type: 'phone',
        label: <FormattedMessage id="ph_sub_dealer_id" />,
        id: 'customerSubDealerId',
        formGroup: 0,
        group: 'customerDetails',
        column: 6,
        classProps: 'w-180-max',

        onBlur: this.handleCustomerDetailsBlur,
        onFocus: this.handleCustomerDetailsFocus,
        onChange: this.handleCustomerDetailsChange,
      }),
    ];

    const billing = [
      new Control({
        name: 'billingEmail',
        type: 'email',
        label: <FormattedMessage id="ph_billing_email" />,
        rule: new ValidationRule({
          method: validator.isEmail,
          validWhen: true,
          message: <FormattedMessage id="warning_email" />,
        }),
        group: 'billingDetails',
        id: 'billingEmail',
        formGroup: 1,
        column: 12,
        classProps: 'w-378-max pull-right',
        onBlur: this.handleBillingDetailsBlur,
        onFocus: this.handleBillingDetailsFocus,
        onChange: this.handleBillingAddressChange,
      }),
      new Control({
        name: 'billingcontactPerson',
        type: 'string',
        label: <FormattedMessage id="all_reseller_contact_person" />,
        rule: new ValidationRule({
          method: validator.isEmpty,
          validWhen: false,
          message: <FormattedMessage id="warning_contact_person" />,
        }),
        group: 'billingDetails',
        id: 'billingcontactPerson',
        formGroup: 1,
        column: 12,
        classProps: 'w-378-max pull-right',
        onBlur: this.handleBillingDetailsBlur,
        onFocus: this.handleBillingDetailsFocus,
        onChange: this.handleBillingAddressChange,
      }),
      new Control({
        name: 'billingPhone',
        type: 'phone',
        label: <FormattedMessage id="ph_phone" />,
        rule: new ValidationRule({
          method: validator.isLength,
          validWhen: true,
          args: [{ min: 8, max: 8 }],
          message: <FormattedMessage id="warning_phone_number" />,
        }),
        group: 'billingDetails',
        id: 'billingPhone',
        formGroup: 1,
        column: 12,
        classProps: 'w-378-max pull-right',
        onBlur: this.handleBillingDetailsBlur,
        onFocus: this.handleBillingDetailsFocus,
        onChange: this.handleBillingAddressChange,
      }),
      new Control({
        name: 'billingReference',
        type: 'String',
        label: <FormattedMessage id="ph_billing_reference" />,
        group: 'billingDetails',
        id: 'billingReference',
        formGroup: 1,
        column: 12,
        classProps: 'w-378-max pull-right',
        isValid: true,
        onBlur: this.handleBillingDetailsBlur,
        onFocus: this.handleBillingDetailsFocus,
        onChange: this.handleBillingAddressChange,
      }),
    ];
    const mainContact = [
      new Control({
        name: 'mainContactPerson',
        type: 'String',
        label: <FormattedMessage id="all_reseller_contact_person" />,
        rule: new ValidationRule({
          method: validator.isEmpty,
          validWhen: false,
          message: <FormattedMessage id="warning_contact_person" />,
        }),
        group: 'mainContact',
        id: 'mainContactPerson',
        formGroup: 2,
        column: 12,
        classProps: 'w-378-max',
        onBlur: this.handleMainDetailsBlur,
        onFocus: this.handleMainDetailsFocus,
        onChange: this.handleMainAddressChange,
      }),
      new Control({
        name: 'mainPhone',
        type: 'phone',
        label: <FormattedMessage id="ph_phone" />,
        rule: new ValidationRule({
          method: validator.isLength,
          validWhen: true,
          args: [{ min: 8, max: 8 }],
          message: <FormattedMessage id="warning_phone_number" />,
        }),
        group: 'mainContact',
        id: 'mainPhone',
        formGroup: 2,
        column: 12,
        classProps: 'w-378-max',
        onBlur: this.handleMainDetailsBlur,
        onFocus: this.handleMainDetailsFocus,
        onChange: this.handleMainAddressChange,
      }),
      new Control({
        name: 'mainEmail',
        type: 'email',
        label: <FormattedMessage id="ph_email" />,
        rule: new ValidationRule({
          method: validator.isEmail,
          validWhen: true,
          message: <FormattedMessage id="warning_email" />,
        }),
        group: 'mainContact',
        id: 'mainEmail',
        formGroup: 2,
        column: 12,
        classProps: 'w-378-max',
        onBlur: this.handleMainDetailsBlur,
        onFocus: this.handleMainDetailsFocus,
        onChange: this.handleMainAddressChange,
      }),
      new Control({
        name: 'mainAddress',
        type: 'string',
        label: <FormattedMessage id="contract_details_edit_address" />,
        rule: new ValidationRule({
          method: validator.isEmpty,
          validWhen: false,
          message: <FormattedMessage id="warning_address" />,
        }),
        group: 'mainContact',
        id: 'mainAddress',
        formGroup: 2,
        column: 12,
        classProps: 'w-378-max',
        onBlur: this.handleMainDetailsBlur,
        onFocus: this.handleMainDetailsFocus,
        onChange: this.handleMainAddressChange,
      }),
      new Control({
        name: 'mainZip',
        type: 'number',
        label: <FormattedMessage id="ph_postal_code" />,
        rule: new ValidationRule({
          method: validator.isLength,
          validWhen: true,
          args: [{ min: 0, max: 4 }],
          message: <FormattedMessage id="warning_postal_code" />,
        }),
        group: 'mainContact',
        id: 'mainZip1',
        formGroup: 2,
        column: 12,
        classProps: 'w-378-max',
        onBlur: this.handleMainDetailsBlur,
        onFocus: this.handleMainDetailsFocus,
        onChange: this.handleMainAddressChange,
      }),
    ];
    const shipping = [
      new Control({
        name: 'shippingContactPerson',
        type: 'String',
        label: <FormattedMessage id="all_reseller_contact_person" />,
        rule: new ValidationRule({
          method: validator.isEmpty,
          validWhen: false,
          message: <FormattedMessage id="warning_contact_person" />,
        }),
        group: 'shippingAddress',
        id: 'shippingContactPerson',
        formGroup: 3,
        column: 12,
        classProps: 'w-378-max pull-right',
        onBlur: this.handleshippingAddressBlur,
        onFocus: this.handleshippingAddressFocus,
        onChange: this.handleshippingAddressChange,
      }),
      new Control({
        name: 'shippingPhone',
        type: 'phone',
        label: <FormattedMessage id="ph_phone" />,
        rule: new ValidationRule({
          method: validator.isLength,
          validWhen: true,
          args: [{ min: 8, max: 8 }],
          message: <FormattedMessage id="warning_phone_number" />,
        }),
        group: 'shippingAddress',
        id: 'shippingPhone',
        formGroup: 3,
        column: 12,
        classProps: 'w-378-max pull-right',
        onBlur: this.handleshippingAddressBlur,
        onFocus: this.handleshippingAddressFocus,
        onChange: this.handleshippingAddressChange,
      }),
      new Control({
        name: 'shippingEmail',
        type: 'email',
        label: <FormattedMessage id="ph_email" />,
        rule: new ValidationRule({
          method: validator.isEmail,
          validWhen: true,
          message: <FormattedMessage id="warning_email" />,
        }),
        group: 'shippingAddress',
        id: 'shippingEmail',
        formGroup: 3,
        column: 12,
        classProps: 'w-378-max pull-right',
        onBlur: this.handleshippingAddressBlur,
        onFocus: this.handleshippingAddressFocus,
        onChange: this.handleshippingAddressChange,
      }),
      new Control({
        name: 'shippingAddress',
        type: 'string',
        label: <FormattedMessage id="contract_details_edit_address" />,
        rule: new ValidationRule({
          method: validator.isEmpty,
          validWhen: false,
          message: <FormattedMessage id="warning_address" />,
        }),
        group: 'shippingAddress',
        id: 'shippingAddress',
        formGroup: 3,
        column: 12,
        classProps: 'w-378-max pull-right',
        onBlur: this.handleshippingAddressBlur,
        onFocus: this.handleshippingAddressFocus,
        onChange: this.handleshippingAddressChange,
      }),
      new Control({
        name: 'shippingZip',
        type: 'number',
        label: <FormattedMessage id="ph_postal_code" />,
        rule: new ValidationRule({
          method: validator.isLength,
          validWhen: true,
          args: [{ min: 0, max: 4 }],
          message: <FormattedMessage id="warning_postal_code" />,
        }),
        group: 'shippingAddress',
        id: 'shippingZip',
        formGroup: 3,
        column: 12,
        classProps: 'w-378-max pull-right',
        onBlur: this.handleshippingAddressBlur,
        onFocus: this.handleshippingAddressFocus,
        onChange: this.handleshippingAddressChange,
      }),
    ];
    this.controls = {
      customer: customer,
      subDealer: subDealer,
      billing: billing,
      shipping: shipping,
      mainContact: mainContact,
    };
  }
  componentDidMount() {
    const { ismodalopen, reseller, edit, editData } = this.props;
    const { typeOptions, activeOptions, customerClass } = this.state;
    const { customer, subDealer, mainContact, billing, shipping } = this.controls;
    this.setState({ ismodalopen });
    if (reseller) {
      this.setState({ selectedCustomerIsValid: true, resellerHide: true });
      customer[1].setValue(typeOptions.find(obj => obj.value === '5').value);
      customer[1].disabled = true;
    }
    customer[3].setValue(activeOptions.find(obj => obj.value === 'Active').value);
    if (edit && editData) {
      const { collectiveInvoice = false } = editData;
      this.setState({ collectiveInvoice });
      customer[0].setValue(editData.name || '');
      customer[1].setValue(editData.customerClass.id || '');
      customer[2].setValue(editData.corporateId || '');
      customer[3].setValue(editData.status || '');
      this.state.customer.isValid = this.validate(customer);
      this.handleCustomerDetailsFocus();

      if (edit && editData.reseller) {
        this.setState({ selectedCustomer: editData.reseller, selectedCustomerIsValid: true });
        subDealer[0].setValue(editData.subDealerId || '');
      }

      mainContact[0].setValue(
        editData.mainContact.attention ? editData.mainContact.attention : editData.mainContact.name
      );
      mainContact[1].setValue(editData.mainContact.phone1 || '');
      mainContact[2].setValue(editData.mainContact.email || '');
      mainContact[3].setValue(editData.mainAddress.addressLine1 || '');
      mainContact[4].setValue(editData.mainAddress.postalCode || '');
      this.state.customer.isValid = this.validate(mainContact);
      this.handleBillingDetailsFocus();

      billing[0].setValue(editData.invoiceContact.email || '');
      billing[1].setValue(
        editData.invoiceContact.attention ? editData.invoiceContact.attention : editData.invoiceContact.name
      );
      billing[2].setValue(editData.invoiceContact.phone1 || '');
      billing[3].setValue((editData.customerOrder && editData.customerOrder.value) || '');
      this.state.customer.isValid = this.validate(billing);
      this.handleBillingDetailsFocus();

      shipping[0].setValue(
        editData.deliveryContact.attention ? editData.deliveryContact.attention : editData.deliveryContact.name
      );
      shipping[1].setValue(editData.deliveryContact.phone1 || '');
      shipping[2].setValue(editData.deliveryContact.email || '');
      shipping[3].setValue(editData.deliveryAddress.addressLine1 || '');
      shipping[4].setValue(editData.deliveryAddress.postalCode || '');
      this.state.customer.isValid = this.validate(shipping);
      this.handleshippingAddressFocus();
    }
  }

  handleCloseCustomerModal = () => {
    this.setState({ ismodalopen: false });
    this.props.customerModalClose();
  };
  renderInputElements = control => {
    return (
      <div
        className={
          'col-' +
          control.column +
          (control.column == 6 ? ' pr-0 w-180-max p-0 ml-3' : '') +
          (control.id === 'shippingZip' ? ' pl-5' : '') +
          (control.id === 'customerCorporateId' &&
          (this.controls.customer[1].value === '1' || this.controls.customer[1].value === '')
            ? ' fade'
            : ' show')
        }
        key={control.name}
      >
        {control.type === 'select' ? (
          <Select
            options={control}
            className={' cursor-pointer'}
            placeholder={control.label}
            key={control.name}
            value={control.value}
            onChange={control.onChange}
            ref={input => {
              control.ref = input;
            }}
          />
        ) : control.name === 'mainZip' || control.name === 'shippingZip' ? (
          <PostalInput
            key={control.name}
            id={control.id}
            options={control}
            onChange={control.onChange}
            ref={input => {
              control.ref = input;
            }}
          />
        ) : (
          <Input
            key={control.name}
            options={control}
            value={control.value}
            onChange={control.onChange}
            ref={input => {
              control.ref = input;
            }}
          />
        )}
      </div>
    );
  };

  handleCustomerDetailsBlur = () => {
    let { customer } = this.state;
    customer.isFocus = false;
    this.setState({ customer });
  };
  handleCustomerDetailsFocus = () => {
    let { customer } = this.state;
    customer.isFocus = true;
    customer.isValid = this.validate(this.controls.customer);
  };
  handleCustomerDetailsChange = async (name, options) => {
    let { customer, selectedCustomer, resellerHide } = this.state;
    const { edit } = this.props;
    let error;
    if (name === 'corporateId') {
      this.setState({ corporateIdError: false });
    }
    this.setState({ resellerHide: false });
    this.controls.customer[2].disabled = false;
    if (this.controls.customer[1].value === '1') {
      this.controls.customer[2].disabled = true;
      this.controls.customer[2].isValid = true;
      this.controls.customer[2].value = '';
      this.controls.customer[2].showError = false;
    } else if (this.controls.customer[1].value === '5') {
      this.setState({ selectedCustomerIsValid: true, resellerHide: true });
    }
    if (!resellerHide && selectedCustomer) {
      this.setState({ selectedCustomerIsValid: true });
    }
    if (this.controls.customer[1].value === '4' && this.controls.customer[2].value.length === 9) {
      try {
        error = await this.resellerService.checkOrgId(this.controls.customer[2].value);
        let { status, data } = error;
        if (edit) {
          if (data.corporateId || data.length < 2) {
            this.setState({ corporateIdError: false });
          } else {
            this.setState({ corporateIdError: true });
          }
        } else {
          if (data.corporateId || data.length > 0) {
            this.setState({ corporateIdError: true });
          } else {
            this.setState({ corporateIdError: false });
          }
        }
      } catch (err) {}
    }
    customer.isValid = this.validate(this.controls.customer);

    this.setState({ customer });
  };

  handleBillingDetailsBlur = () => {
    let { billing } = this.state;
    billing.isFocus = false;
    this.setState({ billing });
  };
  handleBillingDetailsFocus = () => {
    let { billing } = this.state;
    billing.isFocus = true;
    billing.isValid = this.validate(this.controls.billing);
  };
  handleBillingAddressChange = () => {
    const { billing, mainContact } = this.controls;
    const { billingIsSameAsMain } = this.state;
    this.state.billing.isValid = this.validate(this.controls.billing);
    if (
      billing[0].value === mainContact[2].value &&
      billing[1].value === mainContact[0].value &&
      billing[2].value === mainContact[1].value
    ) {
      this.setState({ billingIsSameAsMain: true });
    } else {
      this.setState({ billingIsSameAsMain: false });
    }
    this.setState({ billing });
  };

  handleMainDetailsBlur = () => {
    let { mainContact } = this.state;
    mainContact.isFocus = false;
    this.setState({ mainContact });
  };
  handleMainDetailsFocus = () => {
    let { mainContact } = this.state;
    mainContact.isFocus = true;
    mainContact.isValid = this.validate(this.controls.mainContact);
  };
  handleMainAddressChange = async (name, options) => {
    let { mainContact } = this.state;
    const { edit } = this.props;
    mainContact.isValid = this.validate(this.controls.mainContact);
    this.setState({ mainContact });
    let error2;
    if (this.controls.customer[1].value === '1' && this.controls.mainContact[1].value.length === 8) {
      try {
        error2 = await this.resellerService.checktelephonemail(
          this.controls.mainContact[1].value,
          this.controls.mainContact[2].value
        );
        let { status, data } = error2;
        if (edit) {
          if (data.length < 2) {
            this.setState({ phoneNoError: false });
          } else {
            this.setState({ phoneNoError: true });
          }
        } else {
          if (data.length > 0) {
            this.setState({ phoneNoError: true });
          } else {
            this.setState({ phoneNoError: false });
          }
        }
      } catch (err) {}
    }
  };

  handleshippingAddressBlur = () => {
    let { shipping } = this.state;
    shipping.isFocus = false;
    this.setState({ shipping });
  };
  handleshippingAddressFocus = () => {
    let { shipping } = this.state;
    shipping.isFocus = true;
    shipping.isValid = this.validate(this.controls.shipping);
  };
  handleshippingAddressChange = () => {
    const { shipping, mainContact } = this.controls;
    this.state.shipping.isValid = this.validate(this.controls.shipping);
    if (
      shipping[0].value === mainContact[0].value &&
      shipping[1].value === mainContact[1].value &&
      shipping[2].value === mainContact[2].value &&
      shipping[3].value === mainContact[3].value &&
      shipping[4].value === mainContact[4].value
    ) {
      this.setState({ shippingIsSameAsMain: true });
    } else {
      this.setState({ shippingIsSameAsMain: false });
    }
    this.setState({ shipping });
  };

  onBillingSameAsCustomer = () => {
    const { mainContact, billing } = this.controls;
    const { billingIsSameAsMain } = this.state;
    let isValid = this.validate(billing);
    this.setState({ billingIsSameAsMain: !billingIsSameAsMain });
    if (!billingIsSameAsMain) {
      billing[0].setValue(mainContact[2].value);
      billing[1].setValue(mainContact[0].value);
      billing[2].setValue(mainContact[1].value);

      isValid = this.validate(billing);
    }
    this.setState({ billing: { isValid } });
  };
  onShippingSameAsCustomer = () => {
    const { mainContact, shipping } = this.controls;
    const { shippingIsSameAsMain } = this.state;
    this.setState({ shippingIsSameAsMain: !shippingIsSameAsMain });
    let isValid = this.validate(shipping);
    if (!shippingIsSameAsMain) {
      shipping[0].setValue(mainContact[0].value);
      shipping[1].setValue(mainContact[1].value);
      shipping[2].setValue(mainContact[2].value);
      shipping[3].setValue(mainContact[3].value);
      shipping[4].setValue(mainContact[4].value);

      isValid = this.validate(shipping);
    }
    this.setState({ shippingIsSameAsMain: !shippingIsSameAsMain });
    this.setState({ shipping: { isValid } });
  };
  onBlur = event => {
    this.setState({ enterReseller: true });
  };
  onChange = (event, { newValue }) => {
    this.setState({ value: newValue });
  };

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  onSuggestionsFetchRequested = async ({ value }) => {
    if (value.length > 3 && !this.state.fetchingResellers) {
      this.setState({ fetchingResellers: true });
      const response = await this.resellerService.getAllUsers([['filter', { name: [value] }]]);
      const { resellers } = response;
      if (resellers && resellers.length > 0) {
        this.setState({ notFound: false });
      } else {
        this.setState({ notFound: true });
      }
      this.setState({ resellers, fetchingResellers: false });
    }
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {
    this.setState({ resellers: [] });
  };

  handleSuggestionSelected = (event, obj) => {
    this.setState({ selectedCustomer: obj.suggestion, selectedCustomerIsValid: true });
  };

  onRemoveSelection = () => {
    this.setState({ selectedCustomer: undefined, value: '', selectedCustomerIsValid: false });
  };

  handleCollectiveInvoicing = () => {
    const { collectiveInvoice } = this.state;
    this.setState({ collectiveInvoice: !collectiveInvoice });
  };

  handleSubmit = async event => {
    event.preventDefault();
    const { customer } = this.controls;
    const { corporateIdError, emailError, phoneNoError, collectiveInvoice } = this.state;
    this.controls.customer.map(e => {
      if (e.name !== 'corporateId') {
        if (e.value.length === 0 && e.rule !== undefined) {
          e.isValid = false;
          e.showError = true;
        }
      }
    });
    if (customer[1].value === '1') {
      customer[2].isValid = true;
      customer[2].showError = false;
    } else {
      if (customer[2].value.length === 0 && customer[2].rule !== undefined) {
        customer[2].isValid = false;
        customer[2].showError = true;
      }
    }
    this.handleCustomerDetailsFocus();
    this.controls.billing.map(e => {
      if (e.value.length === 0 && e.rule !== undefined) {
        e.isValid = false;
        e.showError = true;
      }
    });
    this.handleBillingDetailsFocus();
    this.controls.shipping.map(e => {
      if (e.value.length === 0 && e.rule !== undefined) {
        e.isValid = false;
        e.showError = true;
      }
    });
    this.handleshippingAddressFocus();
    this.controls.mainContact.map(e => {
      if (e.value.length === 0 && e.rule !== undefined) {
        e.isValid = false;
        e.showError = true;
      }
      if (e.name === 'mainZip') {
        if (e.value.length < 4 || e.city === '') {
          e.isValid = false;
          e.showError = true;
        }
      }
    });
    this.handleMainDetailsFocus();
    if (!this.state.selectedCustomerIsValid) {
      this.setState({ enterReseller: true });
    }
    //trigger State ToCall InputComponent;
    this.setState({ triigerState: true });

    let { selectedCustomer, billingIsSameAsMain, shippingIsSameAsMain } = this.state;
    const { handleToast, edit, editData } = this.props;
    let response;
    if (
      (this.controls.customer[1].value === '1' ? true : this.validate(this.controls.customer)) &&
      this.validate(this.controls.billing) &&
      this.validate(this.controls.mainContact) &&
      this.validate(this.controls.shipping) &&
      this.state.selectedCustomerIsValid &&
      (this.controls.customer[1].value === '4' ? !corporateIdError : true) &&
      !phoneNoError
    ) {
      try {
        response = await this.resellerService.createNewCustomer(
          this.controls,
          billingIsSameAsMain,
          shippingIsSameAsMain,
          selectedCustomer,
          edit ? editData.number : false,
          collectiveInvoice
        );
        if (response.status === 200) {
          this.setState({ isSubmitted: true, error: undefined, loading: false });
          handleToast({
            type: Constants.toast.SUCCESS,
            message: edit ? (
              <FormattedMessage id="toast_customer_updated" />
            ) : (
              <FormattedMessage id="toast_customer_added" />
            ),
            delay: Constants.toast.DELAY,
          });
          this.props.customerCreate(response.data.data);
          this.closeElement.click();
        } else {
          this.setState({ error: response.data.message, loading: false });
          if (response.data.message === 'Same corporate id is used by different user') {
            this.setState({ corporateIdError: true });
          } else if (response.data.message === 'Reseller already exist with same email id') {
            this.setState({ emailError: true });
          } else {
            handleToast({
              type: Constants.toast.ERROR,
              message: edit ? (
                <FormattedMessage id="toast_customer_unable_update" />
              ) : (
                <FormattedMessage id="toast_customer_unable_add" />
              ),
              delay: Constants.toast.DELAY,
            });
            this.props.customerCreate(response.data.data);
            this.closeElement.click();
          }
        }
      } catch (error) {
        this.setState({ error: error, loading: false });
        handleToast({
          type: Constants.toast.ERROR,
          message: edit ? (
            <FormattedMessage id="toast_customer_unable_update" />
          ) : (
            <FormattedMessage id="toast_customer_unable_add" />
          ),
          delay: Constants.toast.DELAY,
        });
        this.props.customerCreate();
        this.closeElement.click();
      }
    }
  };

  validate(controls) {
    return controls.find(obj => obj.rule && !obj.isValid && !obj.donotCheck) === undefined;
  }

  render() {
    const {
      loading,
      value,
      resellers,
      selectedCustomer,
      billingIsSameAsMain,
      shippingIsSameAsMain,
      enterReseller,
      customer,
      billing,
      mainContact,
      shipping,
      resellerHide,
      phoneNoError,
      corporateIdError,
      emailError,
      selectedCustomerIsValid,
      notFound,
      collectiveInvoice,
    } = this.state;
    const inputProps = {
      placeholder: Constants.language.all_contracts_reseller,
      value,
      onChange: this.onChange,
      onBlur: this.onBlur,
    };
    if (this.controls.customer[1].value === '1') {
      this.controls.customer[2].isValid = true;
    }
    let valid =
      this.validate(this.controls.customer) &&
      this.validate(this.controls.billing) &&
      this.validate(this.controls.mainContact) &&
      this.validate(this.controls.shipping) &&
      this.state.selectedCustomerIsValid &&
      (this.controls.customer[1].value === '4' ? !corporateIdError : true) &&
      !phoneNoError;

    const options = resellers.slice(0, 20).map(reseller => {
      return { id: reseller.number, name: reseller.name };
    });
    const { edit, reseller } = this.props;
    return (
      <>
        <div
          className="modal fade bd-example-modal-lg fade-products"
          tabIndex="-1"
          data-backdrop="static"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
          id="customerModal"
        >
          <div className="modal-dialog modal-lg modal-product">
            <div className="modal-content contracts-modal">
              <div className="modal-header modal-header-product">
                <h3 className="modal-title font-weight-semibold" id="myLargeModalLabel">
                  {reseller && edit ? (
                    <FormattedMessage id="new_reseller_edit" />
                  ) : edit ? (
                    <FormattedMessage id="contract_button_edit_service" />
                  ) : reseller ? (
                    <FormattedMessage id="new_reseller_create_new" />
                  ) : (
                    <FormattedMessage id="new_customer_modal_heading" />
                  )}
                </h3>

                <button
                  type="button"
                  className="close outline-0"
                  data-dismiss="modal"
                  aria-label="Close"
                  ref={closeModal => (this.closeElement = closeModal)}
                  onClick={this.handleCloseCustomerModal}
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body p-4  my-2 line-height-normal ls-normal">
                {/* <LoadingOverlay
                  active={loading}
                  spinner={<BounceLoader />}
                  text={<FormattedMessage id="common_loading_overlay" />}
                  classNamePrefix={'light_modal_ '}
                  className={'loader-list-view-common'}
                > */}
                <div className="row mb-5 pb-3">
                  <div className="col-6 px-0">
                    <div className="col-12">
                      <label className="product-heading mb-1 ml-1">
                        <FormattedMessage id="common_customer_details" />*
                      </label>
                      <div className="row ">
                        {this.controls.customer.map(control => this.renderInputElements(control))}
                        {resellerHide && this.controls.subDealer.map(control => this.renderInputElements(control))}
                        {!resellerHide && (
                          <React.Fragment>
                            <div className="col-12  mb-3 form-group">
                              {!selectedCustomer && (
                                <div id="search-box" className="input-group input-common pl-4 h-48 w-378-max">
                                  <div className="form-control border-0 pl-0">
                                    <Autosuggest
                                      suggestions={options}
                                      onSuggestionSelected={this.handleSuggestionSelected}
                                      onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                      onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                      getSuggestionValue={option => option.name}
                                      renderSuggestion={suggestion => (
                                        <div className="margin-left-neg-8">
                                          {suggestion.name} - #{suggestion.id}
                                        </div>
                                      )}
                                      inputProps={inputProps}
                                    />
                                  </div>
                                  <div className="input-group-append search-icon z-index-4">
                                    <button className="btn bg-white py-1 mr-1 z-index-4" type="button">
                                      {this.state.fetchingResellers && (
                                        <div className="spinner-border spinner-border-sm" role="status">
                                          <span className="sr-only">
                                            <FormattedMessage id="common_loading_overlay" />
                                          </span>
                                        </div>
                                      )}
                                      {!this.state.fetchingResellers ? (
                                        value.length > 0 ? (
                                          <span className="float-right cursor-pointer" onClick={this.onRemoveSelection}>
                                            <img
                                              className="mr-2 cursor-pointer"
                                              src={Constants.icons.crossNew1}
                                              width="14"
                                              height="14"
                                              alt="trash-icon"
                                            />
                                          </span>
                                        ) : (
                                          <img src={Constants.icons.search} alt="search-icon" />
                                        )
                                      ) : (
                                        ''
                                      )}
                                    </button>
                                  </div>
                                </div>
                              )}
                              {enterReseller && !notFound && (
                                <div className="text-danger error-message small customer-suggest-error mt-1">
                                  <FormattedMessage id="warning_reseller" />
                                </div>
                              )}
                              {notFound && (
                                <div className="text-danger error-message small customer-suggest-error mt-1">
                                  <FormattedMessage id="reseller_search_error" />
                                </div>
                              )}
                              {selectedCustomer && (
                                <div className="form-group animate-label common-input-container mt-0 w-378-max h-48">
                                  <div className="form-control pb-2 input-common ">
                                    <div className="w-100 position-relative customer-reseller-selected pl-2">
                                      {[charLimit.charLimit(selectedCustomer.name, 12), selectedCustomer.id].join(
                                        ',  '
                                      )}
                                      <span className="float-right cursor-pointer" onClick={this.onRemoveSelection}>
                                        <img
                                          className="mr-2 cursor-pointer"
                                          src={Constants.icons.crossNew1}
                                          width="14"
                                          height="14"
                                          alt="trash-icon"
                                        />
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              )}
                              <label className="form-control-placeholder ml-3 bg-white px-1 customer-reseller-label">
                                <FormattedMessage id="all_contracts_reseller" />
                              </label>
                            </div>
                          </React.Fragment>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-6 px-0">
                    <div className="col-12">
                      <div className="row">
                        <label className="product-heading mb-0 col-4 pl-5">
                          <FormattedMessage id="common_billing" />*
                        </label>
                        <div className="form-check pl-0 col-8">
                          <label className="d-flex align-items-center justify-content-end pcolor fs-14">
                            <CheckBox
                              isSelected={billingIsSameAsMain}
                              onClick={this.onBillingSameAsCustomer.bind(this)}
                              label={<FormattedMessage id="common_same_as_main_contact" />}
                            />
                          </label>
                        </div>
                      </div>
                      <div className="row">
                        {this.controls.billing.map(control => this.renderInputElements(control))}
                      </div>
                      <span className="optionalText fs-12 ml-3 pl-4">
                        <FormattedMessage id="create_optional" />
                      </span>
                      <div className="form-check pl-4 ml-1 pt-3 col-12">
                        <label className="d-flex align-items-center pcolor fs-14">
                          <CheckBox
                            isSelected={collectiveInvoice}
                            onClick={this.handleCollectiveInvoicing}
                            label={<FormattedMessage id="common_collective_invoices" />}
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-1">
                  <div className="col-6 px-0">
                    <div className="col-12 ">
                      <label className="product-heading mb-1 ml-1">
                        <FormattedMessage id="common_main_contact" />*
                      </label>
                      <div className="row">
                        {this.controls.mainContact.map(control => this.renderInputElements(control))}
                      </div>
                    </div>
                  </div>
                  <div className="col-6 px-0">
                    <div className="col-12">
                      <div className="row">
                        <label className="product-heading mb-0 col-4 pl-5">
                          <FormattedMessage id="common_shipping" />*
                        </label>
                        <div className="form-check pl-0 col-8">
                          <label className="d-flex align-items-center justify-content-end pcolor fs-14">
                            <CheckBox
                              isSelected={shippingIsSameAsMain}
                              onClick={this.onShippingSameAsCustomer.bind(this)}
                              label={<FormattedMessage id="common_same_as_main_contact" />}
                            />
                          </label>
                        </div>
                      </div>
                      <div className="row">
                        {this.controls.shipping.map(control => this.renderInputElements(control))}
                      </div>
                    </div>
                  </div>
                </div>
                {corporateIdError && (
                  <div className="text-danger error-message small mt-0">
                    <FormattedMessage id="warning_organization_number_already_used" />
                  </div>
                )}
                {emailError && (
                  <div className="text-danger error-message small mt-0">
                    <FormattedMessage id="warning_email_already_exists" />
                  </div>
                )}
                {phoneNoError && (
                  <div className="text-danger error-message small mt-0">
                    <FormattedMessage id="warning_phone_number_already_used" />
                  </div>
                )}
                <div className="mt-3">
                  <div className="d-inline-block mr-5 pr-1">
                    <button
                      className={
                        (valid ? 'btn-primary btn-pro' : 'btn-pro') +
                        ' ls-0-127 line-height-114 fs-14 font-weight-semibold'
                      }
                      onClick={this.handleSubmit}
                    >
                      {' '}
                      <FormattedMessage id="create_save" />
                    </button>
                  </div>
                  <div className="d-inline-block ml-5">
                    <a
                      href="#"
                      className="s-0-127 line-height-114 fs-14 font-weight-semibold"
                      onClick={this.handleCloseCustomerModal}
                      data-dismiss="modal"
                    >
                      <u>
                        <FormattedMessage id="create_cancel" />
                      </u>
                    </a>
                  </div>
                </div>
                {/* </LoadingOverlay> */}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default CustomerModal;
