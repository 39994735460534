import ApiService from './ApiService';

class OrderService extends ApiService {
  endPoint = {
    order: 'order',
    orders: 'orders',
    retryOrder: 'orders/retry',
    proposals: 'proposals',
  };
  search = 's=';
  page = '?pageNumber=';

  async getAll(queryParam) {
    let response = await this.get(this.baseURL + this.endPoint.orders + this.queryParamString(queryParam));
    if (response.status !== 200) {
      // TODO: Throw more specific errors
      throw Error(response.statusText);
    } else {
      return {
        status: response.status,
        orders: response.data.data.map(obj => obj),
        meta: response.data.meta,
      };
    }
  }

  async myOrders(queryParam) {
    let response = await this.get(
      this.baseURL + this.endPoint.orders + '/my-orders' + this.queryParamString(queryParam)
    );
    if (response.status !== 200) {
      // TODO: Throw more specific errors
      throw Error(response.statusText);
    } else {
      return {
        status: response.status,
        orders: response.data.data.map(obj => obj),
        meta: response.data.meta,
      };
    }
  }

  async searchOrders(searchText) {
    let response = await this.get(this.baseURL + this.endPoint.orders + this.search + searchText);
    if (response.status !== 200) {
      // TODO: Throw more specific errors
      throw Error(response.statusText);
    } else {
      return response;
    }
  }

  async gotoPage(page_number) {
    let response = await this.get(this.baseURL + this.endPoint.orders + this.page + page_number);
    if (response.status !== 200) {
      // TODO: Throw more specific errors
      throw Error(response.statusText);
    } else {
      return {
        status: response.status,
        orders: response.data.data.map(obj => obj),
        meta: response.data.meta,
      };
    }
  }

  async deleteOrder(orderId) {
    const response = await this.delete(`${this.baseURL}${this.endPoint.order}/${orderId}`);
    const { status, statusText, data } = response;
    if (status !== 200) {
      // TODO: Throw more specific errors
      throw Error(statusText);
    } else {
      return {
        status,
        data,
      };
    }
  }

  async retryOrder(orderId) {
    const response = await this.post(`${this.baseURL}${this.endPoint.retryOrder}/${orderId}`);
    const { status, statusText, data } = response;
    if (status !== 201) {
      // TODO: Throw more specific errors
      throw Error(statusText);
    } else {
      return {
        status,
        data,
      };
    }
  }

  async createProductOrder(
    products,
    controls,
    customer,
    reseller,
    postponedDate,
    shippingCharges = 0,
    proposalDetails
  ) {
    this.setAuthorizationToken();
    let { customerDetails, billingAddress, shippingAddress } = controls;
    let data = {};

    if (customer) {
      data['customer'] = {
        value: customer.number,
      };
    }

    if (proposalDetails && Object.keys(proposalDetails).length > 0) {
      data['fromProposal'] = {
        value: true,
      };
      data['proposalNo'] = {
        value: proposalDetails && proposalDetails.proposalNo,
      };
    }

    data['owner'] = {
      value: reseller.internalId || reseller.id,
    };
    data['orderType'] = {
      value: 'SO',
    };
    data['hold'] = {
      value: false,
    };
    if (postponedDate) {
      data['requestOn'] = { value: postponedDate };
    }
    if (postponedDate) {
      data['hold'] = {
        value: true,
      };
    }

    const billingReference = billingAddress.find(obj => obj.id === 'billingReference').value;

    data['customerOrder'] = {
      value: billingReference ? billingReference : '-',
    };

    const name = customerDetails.find(obj => obj.id === 'name');
    data['name'] = {
      value: name ? name.value : undefined,
    };

    const corporateId = customerDetails.find(obj => obj.name === 'corporateId');
    data['corporateId'] = {
      value: corporateId ? corporateId.value : undefined,
    };

    data['mainContact'] = {
      value: this.formatLoad(customerDetails, 'mainContact'),
    };

    data['mainAddress'] = {
      value: this.formatLoad(customerDetails, 'mainAddress'),
    };

    data['soBillingContact'] = {
      value: {
        overrideContact: { value: true },
        ...this.formatLoad(billingAddress, 'soBillingContact'),
      },
    };
    data['soBillingAddress'] = {
      value: {
        overrideAddress: { value: true },
        ...this.formatLoad(customerDetails, 'mainAddress'),
      },
    };

    data['soShippingContact'] = {
      value: {
        overrideContact: { value: true },
        ...this.formatLoad(shippingAddress, 'soShippingContact'),
      },
    };

    data['soShippingAddress'] = {
      value: {
        overrideAddress: { value: true },
        ...this.formatLoad(shippingAddress, 'soShippingAddress'),
      },
    };

    data['lines'] = products.map(product => {
      let line = {
        operation: 'Insert',
        inventoryId: { value: product.inventoryNumber },
        quantity: { value: product.quantity },
        unitPrice: { value: product.defaultPrice },
        uom: { value: product.baseUnit },
      };
      if (product.warehouseDetails) {
        line['warehouse'] = {
          value: product.warehouseDetails[0].warehouse,
        };
      }
      return line;
    });

    let taxPercentage = 25.0 / 100;
    // let totalAmount = products
    //   .map(product => product.defaultPrice)
    //   .reduce((accumulator, value) => {
    //     return accumulator + value;
    //   });
    let totalAmount = 0;
    products.map(product => {
      totalAmount += Number(product.defaultPrice) * Number(product.quantity);
      return totalAmount;
    });
    let line = {
      operation: 'Insert',
      inventoryId: { value: 'FRK01' },
      quantity: { value: 1 },
      uom: { value: 'STK' },
      warehouse: {
        value: 1,
      },
      unitPrice: { value: shippingCharges },
    };

    data.lines.push(line);
    let moms = totalAmount * taxPercentage;

    data['productTotal'] = {
      exMoms: Number(totalAmount),
      Moms: Number(moms),
      total: Number(Number(totalAmount + moms).toFixed(2)),
    };

    let response = await this.post(this.baseURL + this.endPoint.orders + '/products', data);
    if (response.status !== 200 && response.data.statusCode !== 201) {
      // TODO: Throw more specific errors
      throw Error(response.statusText);
    } else {
      return {
        status: response.status,
      };
    }
  }

  async editProductProposal(products, controls, customer, reseller, emails, installationData, proposalDetails) {
    const { proposalNo } = proposalDetails;
    this.setAuthorizationToken();
    let { customerDetails, sendEmail } = controls;
    let data = {};

    if (customer) {
      data['customer'] = {
        value: customer.number,
      };
    }

    data['mailTo'] = {
      value: emails,
    };

    data['reseller'] = {
      value: reseller.internalId || reseller.id,
    };

    const name = customerDetails.find(obj => obj.id === 'name');
    data['name'] = {
      value: name ? name.value : undefined,
    };

    data['mainContact'] = {
      value: this.formatLoad(customerDetails, 'mainContact'),
    };

    data['mainAddress'] = {
      value: this.formatLoad(customerDetails, 'mainAddress'),
    };

    data['lines'] = products.map(product => {
      let line = {
        inventoryId: { value: product.inventoryNumber },
        quantity: { value: product.quantity },
        unitPrice: { value: product.defaultPrice },
        resellerPrice: { value: product.resellerPrice },
      };
      return line;
    });
    data['installation'] = installationData;
    let response = await this.put(this.baseURL + this.endPoint.proposals + '/' + proposalNo, data);
    if (response.status !== 200 && response.status !== 201 && response.status !== 204) {
      // TODO: Throw more specific errors
      throw Error(response.statusText);
    } else {
      return {
        status: response.status,
      };
    }
  }

  //proposal section - products
  async createProductProposal(products, controls, customer, reseller, emails, installationData) {
    this.setAuthorizationToken();
    let { customerDetails, sendEmail } = controls;
    let data = {};
    const corporateId = customerDetails.find(obj => obj.name === 'corporateId');
    if (corporateId) {
      data['corporateId'] = {
        value: corporateId ? corporateId.value : undefined,
      };
    }

    if (customer) {
      data['customer'] = {
        value: customer.number,
      };
    }

    data['mailTo'] = {
      value: emails,
    };

    data['reseller'] = {
      value: reseller.internalId || reseller.id,
    };

    const name = customerDetails.find(obj => obj.id === 'name');
    data['name'] = {
      value: name ? name.value : undefined,
    };

    data['mainContact'] = {
      value: this.formatLoad(customerDetails, 'mainContact'),
    };

    data['mainAddress'] = {
      value: this.formatLoad(customerDetails, 'mainAddress'),
    };

    data['lines'] = products.map(product => {
      let line = {
        inventoryId: { value: product.inventoryNumber },
        quantity: { value: product.quantity },
        unitPrice: { value: product.defaultPrice },
        resellerPrice: { value: product.resellerPrice },
      };
      return line;
    });
    data['installation'] = installationData;

    let response = await this.post(this.baseURL + this.endPoint.proposals + '/products', data);
    if (response.status !== 200 && response.status !== 201) {
      // TODO: Throw more specific errors
      throw Error(response.statusText);
    } else {
      return {
        status: response.status,
      };
    }
  }

  // service section
  async createServiceOrder(
    services = {},
    controls = {},
    customer = {},
    newCustomer = false,
    newInstallation = false,
    total = 0,
    reseller = {},
    proposalDetails = {}
  ) {
    this.setAuthorizationToken();
    let { customerDetails, billingAddress, shippingAddress } = controls;
    let data = {};
    if (!newCustomer) {
      data['customer'] = {
        id: customer.number,
      };
    }

    if (proposalDetails && Object.keys(proposalDetails).length > 0) {
      data['fromProposal'] = {
        value: true,
      };
      data['proposalNo'] = {
        value: proposalDetails && proposalDetails.proposalNo,
      };
    }
    const corporateId = customerDetails.find(obj => obj.name === 'corporateId');
    data['corporateId'] = {
      value: corporateId ? corporateId.value : undefined,
    };

    data['mainContact'] = {
      value: this.formatLoad(customerDetails, 'mainContact'),
    };

    const billingReference = billingAddress.find(obj => obj.id === 'billingReference').value;

    data['customerOrder'] = {
      value: billingReference ? billingReference : '-',
    };

    const mainAddress = this.formatLoad(customerDetails, 'mainAddress');
    mainAddress.city = { value: customerDetails[6].city };

    data['mainAddress'] = {
      value: mainAddress,
    };

    data['soBillingContact'] = {
      value: this.formatLoad(billingAddress, 'soBillingContact'),
    };
    data['soBillingAddress'] = {
      value: this.formatLoad(customerDetails, 'mainAddress'),
    };
    data.soBillingAddress.value.city = {
      value: customerDetails[6].city,
    };

    data['owner'] = {
      value: reseller.id || reseller.internalId || '',
    };

    data['soInstallationContact'] = {
      value: this.formatLoad(shippingAddress, 'soShippingContact'),
    };

    const soShippingAddress = this.formatLoad(shippingAddress, 'soShippingAddress');
    soShippingAddress.city = { value: shippingAddress[4].city };
    data['soInstallationAddress'] = {
      value: soShippingAddress,
    };

    if (newInstallation) {
      data['isNewLocation'] = { value: 1 };
    } else {
      data['isNewLocation'] = { value: 0 };
    }

    if (customer.length > 0) {
      data['location'] = {
        id: customer.location.id,
        description: '',
      };
    } else {
      data['location'] = {
        id: '',
        description: data['mainAddress'].value.addressLine1.value,
      };
    }

    // if (!newCustomer) {
    //   data["location"] = {
    //     id: customer.location.id,
    //     description: ""
    //   };
    // } else {
    //   data["location"] = {
    //     id: "",
    //     description: data["mainAddress"].value.addressLine1.value
    //   };
    // }

    // data["lines"] = services.map(service => {
    //   return {
    //     item_code: service.contractItem.id
    //   };
    // });
    services.map(obj => {
      if (obj.children) {
        obj.isChild = false;
        // delete obj.children;
      } else {
        obj.isChild = true;
      }
      return obj;
    });
    data['serviceData'] = services;
    let taxPercentage = 25.0 / 100;
    let totalAmount = Number(
      services
        .map(service => service.defaultPrice)
        .reduce((accumulator, value) => {
          return accumulator + value;
        })
    );
    let moms = Number(totalAmount * taxPercentage);
    data['serviceTotal'] = {
      exMoms: Number(Number(totalAmount).toFixed(2)),
      Moms: Number(Number(moms).toFixed(2)),
      total: Number(Number(totalAmount + moms).toFixed(2)),
    };

    let response = await this.post(this.baseURL + 'contracts', data);
    if (response.status !== 200 && response.data.statusCode !== 201) {
      // TODO: Throw more specific errors
      throw Error(response.statusText);
    } else {
      return {
        status: response.status,
      };
    }
  }

  //proposal section - services
  async createServiceProposal(services, controls, customer, reseller, emails, installationData) {
    this.setAuthorizationToken();
    let { customerDetails, sendEmail } = controls;
    let data = {};

    if (customer) {
      data['customer'] = {
        value: customer.number,
      };
    }

    data['mailTo'] = {
      value: emails,
    };

    data['reseller'] = {
      value: reseller.internalId || reseller.id,
    };

    const name = customerDetails.find(obj => obj.id === 'name');
    data['name'] = {
      value: name ? name.value : undefined,
    };

    data['mainContact'] = {
      value: this.formatLoad(customerDetails, 'mainContact'),
    };

    data['mainAddress'] = {
      value: this.formatLoad(customerDetails, 'mainAddress'),
    };

    data['lines'] = services.map(service => {
      let line = {
        inventoryNumber: { value: service.inventoryNumber },
        price: { value: service.defaultPrice },
      };
      return line;
    });

    let response = await this.post(this.baseURL + this.endPoint.proposals + '/services', data);
    if (response.status !== 200 && response.status !== 201) {
      // TODO: Throw more specific errors
      throw Error(response.statusText);
    } else {
      return {
        status: response.status,
      };
    }
  }

  async editServiceProposal(services, controls, customer, reseller, emails, installationData, proposalDetails) {
    const { proposalNo } = proposalDetails;
    this.setAuthorizationToken();
    let { customerDetails, sendEmail } = controls;
    let data = {};

    if (customer) {
      data['customer'] = {
        value: customer.number,
      };
    }

    data['mailTo'] = {
      value: emails,
    };

    data['reseller'] = {
      value: reseller.internalId || reseller.id,
    };

    const name = customerDetails.find(obj => obj.id === 'name');
    data['name'] = {
      value: name ? name.value : undefined,
    };

    data['mainContact'] = {
      value: this.formatLoad(customerDetails, 'mainContact'),
    };

    data['mainAddress'] = {
      value: this.formatLoad(customerDetails, 'mainAddress'),
    };

    data['lines'] = services.map(service => {
      let line = {
        inventoryNumber: { value: service.inventoryNumber },
        price: { value: service.defaultPrice },
      };
      return line;
    });
    let response = await this.put(this.baseURL + this.endPoint.proposals + '/' + proposalNo, data);
    if (response.status !== 200 && response.status !== 201 && response.status !== 204) {
      // TODO: Throw more specific errors
      throw Error(response.statusText);
    } else {
      return {
        status: response.status,
      };
    }
  }

  async createProductDescription(inventoryNumber, description) {
    this.setAuthorizationToken();

    let data = {};

    if (inventoryNumber && description) {
      data['inventoryNumber'] = inventoryNumber;
      data['productBrief'] = description;
    }

    let response = await this.post(this.baseURL + 'products/update-description', data);
    if (response.status !== 200 && response.data.statusCode !== 201) {
      // TODO: Throw more specific errors
      throw Error(response.statusText);
    } else {
      return {
        status: response.status,
      };
    }
  }

  formatLoad(controls, group) {
    let elements = controls.filter(obj => obj.group === group).filter(obj => obj.value);

    let payload = {};
    for (var i = 0; i < elements.length; i++) {
      const obj = elements[i];
      payload[obj.name] = { value: obj.value };

      if ((group === 'mainAddress' || group === 'soShippingAddress') && obj.name === 'postalCode') {
        payload['city'] = { value: obj.city };
      }
    }
    return payload;
  }
}

export default OrderService;
