import React from 'react';
import { connect } from 'react-redux';
import { getAll } from '../state/actions/products';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Config from '../config';
import Constants from '../shared/constants';
import { AllOrderListView, AllOrderListViewHeader } from '../components/AllOrders';
import SearchInput from '../components/Search';
import Pagination from '../components/Pagination';
import LoadingOverlay from 'react-loading-overlay';
import BaseComponent from '../components/BaseComponent';
import BounceLoader from 'react-spinners/BounceLoader';
import AuthService from '../services/AuthService';
import { FilterButton } from '../components/FilterData/Components/FilterButton';
import { FilterPopOver } from '../components/FilterData/FilterPopOver';
import Toast from '../components/Toast';
import ProposalService from '../services/ProposalService';

class Proposals extends BaseComponent {
  authService = new AuthService(Config.apiBase);
  proposalService = new ProposalService(Config.apiBase);

  constructor(props) {
    super(props);
    const {
      location: { pathname },
    } = this.props;
    const pathName = pathname;
    this.state = {
      proposals: [],
      meta: {
        cursor: {
          previous: null,
          current: 1,
          next: 1,
          count: null,
          pageNumber: 1,
        },
      },
      searchQuery: '',
      searchData: {
        id: 'customer',
        value: '',
        component: 'search',
      },
      showNext: true,
      message: false,
      loading: true,
      sortedBy: 'date',
      sortAscending: false,
      isFilterOpen: false,
      proposalsFilterData: [
        {
          id: 'startsWith',
          component: 'letterSelect',
          label: Constants.language.customer_head_1,
          values: [
            'A',
            'B',
            'C',
            'D',
            'E',
            'F',
            'G',
            'H',
            'I',
            'J',
            'K',
            'L',
            'M',
            'N',
            'O',
            'P',
            'Q',
            'R',
            'S',
            'T',
            'U',
            'V',
            'W',
            'X',
            'Y',
            'Z',
          ],
          defaultValues: [],
        },
        {
          id: 'type',
          component: 'checkBox',
          groups: [
            {
              label: Constants.language.all_orders_type,
              id: 'type',
              values: ['Produkter', 'Abonnement'],
              defaultValues: [],
            },
          ],
        },
        {
          id: 'date',
          component: 'datePicker',
          label: Constants.language.all_proposals_registered_date,
          fromDate: {
            defaultDate: '',
          },
          toDate: {
            defaultDate: '',
          },
        },
        {
          id: 'status',
          component: 'checkBox',
          groups: [
            {
              label: 'Status',
              id: 'orderStatus',
              values: ['Registrert', 'Akseptert', 'Avvist'],
              defaultValues: [],
            },
          ],
        },
        {
          id: 'total',
          component: 'rangeSlider',
          label: Constants.language.order_amount,
          min: 0,
          max: 50000,
          placeholderValues: [0, 50000],
          defaultValues: [],
        },
      ],
      selectedFilterData: [],
      toBeFilteredData: {},
      showToast: false,
      toastData: {},
    };
  }

  async componentDidMount() {
    const { updateSelectedProductList, updateProductList } = this.props;
    updateSelectedProductList([]);
    updateProductList([]);
    await this.fetchProposals();
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      meta: {
        cursor: { pageNumber },
      },
      sortedBy,
      sortAscending,
      toBeFilteredData,
    } = this.state;

    if (this.props.location !== prevProps.location) {
      const searchData = {
        id: 'customer',
        value: '',
        component: 'search',
      };
      const meta = {
        cursor: {
          previous: null,
          current: 0,
          next: 1,
          count: null,
        },
      };
      const toBeFilteredData = {};

      this.setState({
        sortedBy: 'date',
        isFilterOpen: false,
        sortAscending: false,
        searchData,
        meta,
        searchQuery: '',
        toBeFilteredData,
      });
    }

    if (
      prevState.meta.cursor.pageNumber !== pageNumber ||
      prevState.sortedBy !== sortedBy ||
      prevState.sortAscending !== sortAscending
    ) {
      delete toBeFilteredData.status;

      await this.fetchProposals(toBeFilteredData);
    }
  }

  handleGotoPage = pageNumber => {
    this.setState({
      meta: {
        cursor: {
          pageNumber: pageNumber,
        },
      },
    });
  };

  handleSearchProposals = async value => {
    const { toBeFilteredData, searchQuery } = this.state;

    if (value && value.length > 0) {
      if ((typeof Number(value) === 'number' && !isNaN(Number(value))) || /\d/.test(value)) {
        toBeFilteredData.id = [value];
        let index = toBeFilteredData.customer ? toBeFilteredData.customer.findIndex(obj => obj === searchQuery) : -1;
        if (index > -1) {
          toBeFilteredData.customer.splice(index, 1);
        } else {
          delete toBeFilteredData.customer;
        }
      } else {
        if (toBeFilteredData.customer && toBeFilteredData.customer.length > 0) {
          toBeFilteredData.customer = toBeFilteredData.customer.filter(obj => obj !== searchQuery);
          toBeFilteredData.customer.push(value);
        } else {
          toBeFilteredData.customer = [value];
        }
        delete toBeFilteredData.id;
      }
    } else {
      let index = toBeFilteredData.customer ? toBeFilteredData.customer.findIndex(obj => obj === searchQuery) : -1;
      if (index > -1) {
        toBeFilteredData.customer.splice(index, 1);
      } else {
        delete toBeFilteredData.customer;
      }
      delete toBeFilteredData.id;
    }

    let searchData = {
      id: typeof Number(value) === 'number' && !isNaN(Number(value)) ? 'id' : 'customer',
      value,
      component: 'search',
    };
    this.setState({ searchQuery: value, toBeFilteredData, searchData });
    this.fetchProposals(toBeFilteredData, value);
  };
  async fetchProposals(toBeFilteredData, searchInput) {
    const {
      searchQuery,
      sortedBy,
      sortAscending,
      meta: {
        cursor: { pageNumber },
      },
      proposals,
      toBeFilteredData: filterData,
    } = this.state;

    const queryParams = [
      ['pageNumber', searchInput ? 1 : pageNumber],
      ['sortedBy', sortedBy],
      ['sortAscending', sortAscending],
      ['filter', toBeFilteredData || filterData],
    ];
    this.setState({ loading: true });
    let response;
    if (this.authService.isResellerUser()) {
      const userData = JSON.parse(localStorage.getItem('userData'));
      response = await this.proposalService.getAll(queryParams, userData.visma_id);
    } else {
      response = await this.proposalService.getAll(queryParams);
    }
    try {
      if (response && response.status === 200) {
        let { proposals: responseProposals, meta } = response;
        const showNext = this.canShowNext(proposals, responseProposals, meta.cursor);
        if (responseProposals.length > 0) {
          if (searchQuery) {
            this.setState({ message: false });
          }
          this.setState({
            proposals: responseProposals,
            meta,
            showNext,
          });
        } else {
          if (searchQuery) {
            this.setState({
              searchQuery: undefined,
              message: true,
              unknown: true,
              proposals: [],
            });
          } else {
            this.setState({ proposals: [] });
          }
        }
      }
    } catch (e) {
      console.error(e);
    }
    this.setState({ loading: false });
  }

  handleSorting = (name, e) => {
    const { sortAscending } = this.state;
    let sortedBy;
    if (this.state.sortedBy === name) {
      this.setState({ sortAscending: !sortAscending });
    } else {
      sortedBy = name;
      this.setState({ sortedBy, sortAscending: true });
    }
  };

  // Filter Data Handle
  handleFitlerButton = event => {
    const { isFilterOpen } = this.state;
    this.setState({ isFilterOpen: !isFilterOpen });
  };

  handleCancelFilter = () => {
    this.setState({ isFilterOpen: false });
  };

  handleApplyFilters = data => {
    const { searchQuery } = this.state;
    let toBeFilteredData = {};

    data.map(obj => {
      if (toBeFilteredData[obj.id]) {
        toBeFilteredData[obj.id].push(obj.value);
      } else {
        toBeFilteredData[obj.id] = [];
        toBeFilteredData[obj.id].push(obj.value);
      }

      return obj;
    });

    this.setState({ toBeFilteredData, isFilterOpen: false });
    this.fetchProposals(toBeFilteredData, data);
  };

  handleDeclineProposal = orderId => async () => {
    this.setState({ loading: true });
    try {
      const response = await this.proposalService.declineProposal(orderId);
      if (response) {
        this.setState({ loading: false });
        this.handleToast(
          <FormattedMessage id="proposal_action_decline_proposal_success_message" />,
          Constants.toast.SUCCESS
        );
        this.fetchProposals();
      }
    } catch (e) {
      this.setState({ loading: false });
      this.handleToast(<FormattedMessage id="proposal_action_decline_order_failed_message" />, Constants.toast.ERROR);
    }
  };

  handleEditProposal = proposal => async () => {
    const { history } = this.props;
    const { proposalNo: orderId, type } = proposal;
    if (type === 'Service') {
      history.push({
        pathname: Constants.routes.newServicePrepareProposal,
        acceptServiceProposal: true,
        proposalNo: orderId,
      });
    } else {
      history.push({ pathname: Constants.routes.newProductPrepareProposal, acceptProposal: true, proposalNo: orderId });
    }
  };

  handleAcceptProposal = proposal => async () => {
    const { history } = this.props;
    const { proposalNo: orderId, type } = proposal;
    if (type === 'Service') {
      history.push({
        pathname: Constants.routes.newServicePrepareOrder,
        acceptServiceProposal: true,
        proposalNo: orderId,
        serviceOrder: true,
      });
    } else {
      history.push({ pathname: Constants.routes.newProductPrepareOrder, acceptProposal: true, proposalNo: orderId });
    }
  };

  handleToast = (data, type) => {
    let toastData = {
      type: type,
      message: data,
      delay: Constants.toast.LONG_DELAY,
    };

    this.setState({ showToast: true, toastData });
    setTimeout(() => {
      this.setState({
        showToast: false,
      });
    }, toastData.delay);
  };

  render() {
    const {
      proposals,
      meta,
      loading,
      sortAscending,
      sortedBy,
      isFilterOpen,
      proposalsFilterData,
      toBeFilteredData,
      toastData,
      showToast,
      searchData,
    } = this.state;
    const isSuperAdmin = this.authService.isSuperAdminUser();
    const isAdminBackOffice = this.authService.isAdminBackOfficeUser();
    const isAdminSales = this.authService.isAdminSalesUser();
    const {
      location: { pathname: pathName },
    } = this.props;

    return (
      <React.Fragment>
        {showToast && (
          <Toast type={toastData.type} message={toastData.message} showToast={showToast} delay={toastData.delay} />
        )}
        <section id="page-header">
          <div className="container-fluid px-0">
            <div className="mt-5">
              <div className="row mb-3">
                <div className="col-auto">
                  <h1 className="mb-1 ls-0-25 line-height-normal">
                    <FormattedMessage id="common_proposals" />
                  </h1>
                  <p className="line-height-22 mb-2 ls-0-47 w-600-max">
                    <FormattedMessage id="proposal_description" />
                  </p>
                </div>
              </div>

              <div className="row ml-0 mb-4">
                <div className="w-180-min mr-4">
                  <Link
                    to={{ pathname: Constants.routes.newProposalChooseCustomerProduct }}
                    className={'btn btn-primary h-44 pt-2 pb-1 line-height-16 ls-1-27'}
                  >
                    <img src={Constants.icons.iconAddWhite} width="24" height="24" className="mr-1" alt="icon" />

                    <span className="va-middle font-weight-normal">
                      <FormattedMessage id="create_new_proposal_product" />
                    </span>
                  </Link>
                </div>
                <div className="w-180-min">
                  <Link
                    to={{ pathname: Constants.routes.newProposalChooseCustomerService }}
                    className={'btn btn-primary h-44 pt-2 pb-1 line-height-16 ls-1-27'}
                  >
                    <img src={Constants.icons.iconAddWhite} width="24" height="24" className="mr-1" alt="icon" />

                    <span className="va-middle font-weight-normal">
                      <FormattedMessage id="create_new_proposal_services" />
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="products" className="bg-green pb-3 loading-bg-height list-mb-50">
          <React.Fragment>
            <div id="products-content" className="collapse show">
              <div className="container-fluid mt-3 px-0">
                <div className="">
                  <div className="card card-rounded">
                    <div className="card-body list-view-card-body">
                      <div className="row">
                        <h4 className="mb-3 pl-3 font-weight-semibold line-height-normal ls-0-25">
                          <FormattedMessage id="proposal_overview_header" />
                        </h4>
                      </div>
                      <div className={'row ' + (Object.keys(toBeFilteredData).length > 0 ? 'mb-4' : 'mb-4 pb-4')}>
                        <div className="col-5 mr-4 w-378-max p-0 ml-3">
                          <SearchInput
                            onSearch={this.handleSearchProposals}
                            containerStyle="h-44 border-2-ededed br-4"
                            iconStyle="h-100"
                          />
                        </div>

                        <div className="col-2 w-107-max p-0">
                          <FilterButton
                            className="btn-grey-light btn py-2 mr-3 text-black filter-btn-text"
                            onClick={this.handleFitlerButton}
                            onBlur={this.handleBlur}
                          />
                        </div>
                      </div>
                      <div className="">
                        <FilterPopOver
                          isOpen={isFilterOpen}
                          onApply={this.handleApplyFilters}
                          onCancel={this.handleCancelFilter}
                          options={proposalsFilterData}
                          searchData={searchData}
                          onClearSearch={this.handleSearchProposals.bind(this, '')}
                        />
                      </div>

                      <div className="ri-table mb-3 fs-16 ls-0-44">
                        <LoadingOverlay
                          active={loading}
                          spinner={<BounceLoader />}
                          className="loading__overlay_white_wrapper loader-list-view-common"
                          text={<FormattedMessage id="common_loading_overlay" />}
                        >
                          {proposals.length > 0 && !loading ? (
                            <>
                              <div className="fs-14">
                                <AllOrderListViewHeader
                                  onSort={this.handleSorting}
                                  sortAscending={sortAscending}
                                  pathName={pathName}
                                  sortedBy={sortedBy}
                                  isProposal={true}
                                />
                                <AllOrderListView
                                  orders={proposals}
                                  isProposal={true}
                                  sorting={true}
                                  onSort={this.handleSorting}
                                  onRetryOrder={this.handleAcceptProposal}
                                  onEditOrder={this.handleEditProposal}
                                  onDeleteOrder={this.handleDeclineProposal}
                                  isSuperAdmin={isSuperAdmin}
                                  isAdminBackOffice={isAdminBackOffice}
                                  isAdminSales={isAdminSales}
                                  pathName={pathName}
                                  sortAscending={sortAscending}
                                  sortedBy={sortedBy}
                                />
                              </div>
                            </>
                          ) : (
                            !loading && (
                              <React.Fragment>
                                <div className="container pt-3 ri-table ">
                                  <div className="row justify-content-center mb-4">
                                    <p className="text-center">
                                      ! &nbsp;
                                      <FormattedMessage id="my_proposals_no_proposals_yet" />
                                    </p>
                                  </div>
                                </div>
                              </React.Fragment>
                            )
                          )}
                        </LoadingOverlay>
                      </div>
                      {!loading && proposals.length > 0 && (
                        <Pagination cursor={meta.cursor} gotoPage={this.handleGotoPage} />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        </section>
      </React.Fragment>
    );
  }
}

Proposals.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object,
};

const mapStateToProps = ({ order, Products }) => ({
  order,
  Products,
});

const mapDispatchToProps = dispatch => {
  return {
    updateProductList: value => {
      dispatch({
        type: 'UPDATE_TOTAL_PRODUCTS',
        payLoad: value,
      });
    },
    updateSelectedProductList: value => {
      dispatch({
        type: 'UPDATE_SELECTED_PRODUCTS',
        payload: value,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Proposals);
