import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Control } from '../../shared/Control';
import Constants from '../../shared/constants';

class CircledInput extends Component {
  state = {
    classes: ['form-control', 'form-control-lg', 'signin-input', 'h-44'],
    isValid: false,
  };

  handleChange = (event) => {
    const { name, rule } = this.props.options;

    if (rule) {
      this.props.options.isValid = this.validate(event.target.value, rule);
    }
    this.props.options.value = event.target.value;
    // Trigger on change event only when the value is valid
    this.props.onChange(name, this.props.options);
  };

  render() {
    const { name, type, defaultValue, placeholder } = this.props.options;
    const { error } = this.props;
    const { classes } = this.state;
    return (
      <div className="position-relative w-378-max w-100">
        <input
          type={type}
          className={classes.join(' ')}
          name={name}
          id={name}
          aria-describedby={name}
          defaultValue={defaultValue}
          onChange={this.handleChange}
          placeholder={placeholder}
        />
        {error && <img className="error-icon signin-error" src={Constants.icons.triangle} alt="" />}
      </div>
    );
  }

  validate(value, rule) {
    const args = rule.args || [];
    return rule.method(value, ...args) === rule.validWhen;
  }
}

CircledInput.propsTypes = {
  options: PropTypes.objectOf(Control),
  onChange: PropTypes.func,
};

export default CircledInput;
