const Config = {
  apiBase: "https://test-t6dnbai-bp3xkxvmpjwyk.eu-2.platformsh.site/api/",
  backEndUrl: "https://test-t6dnbai-bp3xkxvmpjwyk.eu-2.platformsh.site/",
  language: localStorage.getItem("language")
    ? localStorage.getItem("language") === "en"
      ? "en"
      : "no"
    : "no",
  vismaId: "20003",
  markerDestinationId: '623c60c749f5696a81941210',
};

export default Config;
