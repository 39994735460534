import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import Constants from '../../shared/constants';
import AuthService from '../../services/AuthService';
import Config from '../../config';
import { FormattedMessage } from 'react-intl';

import { connect } from 'react-redux';
import { updateSideMenu } from '../../state/actions/UtilsAction';
import { getUserDetails } from '../../state/actions/user';

class SideMenu extends Component {
  authService = new AuthService(Config.apiBase);
  constructor(props) {
    super(props);
    this.state = {
      route: this.props.route,
      customerOpen:
        window.location.pathname === Constants.routes.customers
          ? true
          : window.location.pathname === Constants.routes.resellers
          ? true
          : false,
      inventoryOpen:
        window.location.pathname === Constants.routes.products
          ? true
          : window.location.pathname === Constants.routes.service
          ? true
          : false,
      isLoggedIn: '',
      token: '',
      user: null,
      width: window.innerWidth,
      languageOpen: false,
    };
    let state = localStorage[Constants.storage.appState];
    if (state) {
      let AppState = JSON.parse(state);
      let userInLocalStorage = localStorage[Constants.storage.userData];
      const { activePage } = this.props;
      this.setState({ isLoggedIn: AppState.isLoggedIn, token: AppState.token });
      if (!userInLocalStorage || userInLocalStorage === 'null') {
        //if user not in login page,then fetch user details
        let publicPages = Constants.publicPages();
        var i = publicPages.indexOf(activePage);
        if (i > -1) {
          //have to add logics if need
        } else {
          this.getUser(AppState.token);
        }
      } else {
        this.setState({ user: JSON.parse(userInLocalStorage) });
      }
    }
  }

  authService = new AuthService(Config.apiBase);

  dashboard = ['/', '/dashboard', '/forgot-password', '/reset-password'];

  getUser = token => {
    getUserDetails(Constants.end_points.v1.userDetails, token).then(response => {
      if (response.status !== 200) {
        throw Error(response.statusText);
      } else if (response.data) {
        localStorage[Constants.storage.userData] = JSON.stringify(response.data);
        this.setState({ user: response.data });
        let { history } = this.props;
        history.push({ pathname: Constants.routes.home.url });
      }
    });
  };

  logOut = () => {
    localStorage.clear();
    this.props.history.push({ pathname: Constants.routes.home.url });
  };

  setLanguage = event => {
    localStorage.setItem('language', event);
    window.location.reload();
  };

  dashboardRedirect = () => {
    const { history } = this.props;

    history.push({ pathname: Constants.routes.home.url });
  };
  componentDidMount() {
    this.setState({
      customerOpen:
        window.location.pathname === Constants.routes.customers
          ? true
          : window.location.pathname === Constants.routes.resellers
          ? true
          : false,
      inventoryOpen:
        window.location.pathname === Constants.routes.products
          ? true
          : window.location.pathname === Constants.routes.service
          ? true
          : false,
      languageOpen: false,
    });
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.route.url !== this.props.route.url) {
      this.setState({
        customerOpen:
          window.location.pathname === Constants.routes.customers
            ? true
            : window.location.pathname === Constants.routes.resellers
            ? true
            : false,
        inventoryOpen:
          window.location.pathname === Constants.routes.products
            ? true
            : window.location.pathname === Constants.routes.service
            ? true
            : false,
        languageOpen: false,
      });
    }
  }
  // function that sets the class to active of the active page
  activeRoute(routeName) {
    return window.location.pathname === routeName ? 'active' : '';
  }
  activeRouteIndex(routeName) {
    return window.location.pathname.indexOf(routeName) > -1 ? 'active' : '';
  }
  handleCustomerOpen = () => {
    this.setState({
      customerOpen: !this.state.customerOpen,
      inventoryOpen: false,
      languageOpen: false,
    });
  };
  handleInventoryOpen = () => {
    this.setState({
      inventoryOpen: !this.state.inventoryOpen,
      customerOpen: false,
      languageOpen: false,
    });
  };
  handleLanguageOpen = () => {
    this.setState({
      inventoryOpen: false,
      customerOpen: false,
      languageOpen: !this.state.languageOpen,
    });
  };
  handleCloseExpandMenu = () => {
    this.setState({
      inventoryOpen: false,
      customerOpen: false,
      languageOpen: false,
    });
  };
  handleSideMenuClick = () => {
    let mainWrapper = document.getElementById('main-wrapper');
    mainWrapper.classList.toggle('sidebar-mini');
    this.props.updateSideMenu(!mainWrapper.classList.contains('sidebar-mini'));
  };
  handleSideSubMenuClick = subMenu => {
    this.handleSideMenuClick();
    this.setState({
      customerOpen: subMenu === 'customer' ? true : false,
      inventoryOpen: subMenu === 'inventory' ? true : false,
      languageOpen: subMenu === 'language' ? true : false,
    });
  };

  render() {
    const isSuperAdmin = this.authService.isSuperAdminUser();
    const isAdminSales = this.authService.isAdminSalesUser();
    const isAdminBackOffice = this.authService.isAdminBackOfficeUser();
    const isReseller = this.authService.isResellerUser();
    this.token = localStorage.getItem('token');

    if (localStorage.userData) {
      this.userData = JSON.parse(localStorage.getItem('userData'));
      let name = this.userData.name;
      this.userName = name && name.split(' ', 1);

      if (!localStorage.language) {
        localStorage.setItem('language', 'no');
      }
    }
    let language = localStorage.language;
    const {
      location: { pathname },
      sideMenuCollapse,
    } = this.props;

    return (
      <div className="sidebar bg-dark mh-100 h-100" id="sidebar">
        <div className="logoContainer">
          {' '}
          <img
            src={Constants.rackitHeaderLogo}
            alt="logo"
            onClick={this.dashboardRedirect}
            className="cursor-pointer dashboardLogo"
          />
        </div>
        <div className="menuContainer">
          {' '}
          <div>
            <img
              src={sideMenuCollapse ? Constants.SideMenu.menuClose : Constants.SideMenu.menuOpen}
              alt="logo"
              onClick={this.handleSideMenuClick}
              className="cursor-pointer"
            />
          </div>
        </div>
        <div className="sidebar-wrapper">
          <ul className="nav m-0">
            <li className={this.activeRoute(Constants.routes.dashboard.url)}>
              <Link to={'/' + 'dashboard'}>
                {' '}
                <img
                  src={
                    this.activeRoute(Constants.routes.dashboard.url)
                      ? Constants.SideMenu.sHomeActive
                      : Constants.SideMenu.shome
                  }
                  alt={Constants.language.sidemenu_home}
                />
                <p className="menuItems">
                  <FormattedMessage id="sidemenu_home" />
                </p>
              </Link>
            </li>
            <li
              className={
                this.activeRoute(isReseller ? Constants.routes.myProposals : Constants.routes.proposals) ||
                this.activeRoute(Constants.routes.newProposalChooseCustomerProduct) ||
                this.activeRoute(Constants.routes.newProposalChooseCustomerService) ||
                this.activeRoute(Constants.routes.newServicePrepareProposal) ||
                this.activeRoute(Constants.routes.newProductPrepareProposal) ||
                this.activeRoute(Constants.routes.newProposalConfirmProposalProducts) ||
                this.activeRoute(Constants.routes.newProposalConfirmProposalServices)
              }
            >
              <Link to={isReseller ? Constants.routes.myProposals : Constants.routes.proposals}>
                {' '}
                <img
                  src={
                    this.activeRoute(isReseller ? Constants.routes.myProposals : Constants.routes.proposals) ||
                    this.activeRoute(Constants.routes.newProposalChooseCustomerProduct) ||
                    this.activeRoute(Constants.routes.newProposalChooseCustomerService) ||
                    this.activeRoute(Constants.routes.newServicePrepareProposal) ||
                    this.activeRoute(Constants.routes.newProductPrepareProposal) ||
                    this.activeRoute(Constants.routes.newProposalThankyou) ||
                    this.activeRoute(Constants.routes.newProposalConfirmProposalProducts) ||
                    this.activeRoute(Constants.routes.newProposalConfirmProposalServices)
                      ? Constants.SideMenu.sProposalActive
                      : Constants.SideMenu.sProposalInActive
                  }
                  alt={Constants.language.commmon_proposals}
                />
                <p className="menuItems">
                  <FormattedMessage id="common_proposals" />
                </p>
              </Link>
            </li>
            <li
              className={
                this.activeRoute(isReseller ? Constants.routes.myOrders : Constants.routes.orders) ||
                this.activeRoute(Constants.routes.newOrderChooseCustomerProduct) ||
                this.activeRoute(Constants.routes.newOrderChooseCustomerService) ||
                this.activeRoute(Constants.routes.newServicePrepareOrder) ||
                this.activeRoute(Constants.routes.newProductPrepareOrder) ||
                this.activeRoute(Constants.routes.newOrderConfirmOrderProducts) ||
                this.activeRoute(Constants.routes.newOrderConfirmOrderServices)
              }
            >
              <Link to={isReseller ? Constants.routes.myOrders : Constants.routes.orders}>
                {' '}
                <img
                  src={
                    this.activeRoute(isReseller ? Constants.routes.myOrders : Constants.routes.orders) ||
                    this.activeRoute(Constants.routes.newOrderChooseCustomerProduct) ||
                    this.activeRoute(Constants.routes.newOrderChooseCustomerService) ||
                    this.activeRoute(Constants.routes.newServicePrepareOrder) ||
                    this.activeRoute(Constants.routes.newProductPrepareOrder) ||
                    this.activeRoute(Constants.routes.newOrderConfirmOrderProducts) ||
                    this.activeRoute(Constants.routes.newOrderConfirmOrderServices)
                      ? Constants.SideMenu.sOrderActive
                      : Constants.SideMenu.sOrder
                  }
                  alt={Constants.language.common_orders}
                />
                <p className="menuItems">
                  <FormattedMessage id="common_orders" />
                </p>
              </Link>
            </li>
            {(isSuperAdmin || isAdminSales || isAdminBackOffice) && (
              <li className={this.activeRouteIndex(Constants.routes.contracts)}>
                <Link to={Constants.routes.contracts}>
                  {' '}
                  <img
                    src={
                      this.activeRouteIndex(Constants.routes.contracts)
                        ? Constants.SideMenu.sSubscriptionActive
                        : Constants.SideMenu.sSubscription
                    }
                    alt={Constants.language.sidemenu_contracts}
                  />
                  <p className="menuItems">
                    <FormattedMessage id="sidemenu_contracts" />
                  </p>
                </Link>
              </li>
            )}
            {isReseller && (
              <li className={this.activeRouteIndex(Constants.routes.customers)}>
                <Link to={Constants.routes.customers}>
                  {' '}
                  <img
                    src={
                      this.activeRouteIndex(Constants.routes.customers)
                        ? Constants.SideMenu.sCustomerActive
                        : Constants.SideMenu.sCustomer
                    }
                    alt={Constants.language.sidemenu_all_customers}
                  />
                  <p className="menuItems">
                    <FormattedMessage id="sidemenu_all_customers" />
                  </p>
                </Link>
              </li>
            )}
            {(isSuperAdmin || isAdminSales || isAdminBackOffice) && (
              <li
                className={
                  this.activeRouteIndex(Constants.routes.customers) +
                  ' ' +
                  this.activeRouteIndex(Constants.routes.resellers)
                }
                onClick={() => {
                  sideMenuCollapse ? this.handleCustomerOpen() : this.handleSideSubMenuClick('customer');
                }}
              >
                {/*<Link to={Constants.routes.customers}>*/}
                <div className="expandMenu">
                  {' '}
                  <img
                    src={
                      this.activeRouteIndex(Constants.routes.customers) ||
                      this.activeRouteIndex(Constants.routes.resellers)
                        ? Constants.SideMenu.sCustomerActive
                        : Constants.SideMenu.sCustomer
                    }
                    alt={Constants.language.bc_customers}
                  />
                  <p className="menuItems">
                    <FormattedMessage id="bc_customers" />
                  </p>
                </div>
                <div className="expand">
                  <img
                    src={Constants.SideMenu.expandMore}
                    className={this.state.customerOpen ? 'rotate' : ''}
                    alt="expand"
                  />
                </div>
                <ul
                  className={
                    this.state.customerOpen ? 'sub-menu  pl-1 nav collapsed in ' : 'sub-menu  pl-1 nav collapse '
                  }
                >
                  <li className={this.activeRouteIndex(Constants.routes.customers) + ' border-left-0'}>
                    <Link to={Constants.routes.customers} className="p-0 text-left">
                      <p className="p-0 pl-2 ml-1">
                        <FormattedMessage id="sidemenu_all_customers" />
                      </p>
                    </Link>
                  </li>
                  {(isSuperAdmin || isAdminSales || isAdminBackOffice) && (
                    <li className={this.activeRouteIndex(Constants.routes.resellers) + ' border-left-0'}>
                      <Link to={Constants.routes.resellers} className="p-0 text-left">
                        <p className="p-0 pl-2 ml-1">
                          <FormattedMessage id="bc_resellers" />
                        </p>
                      </Link>
                    </li>
                  )}
                </ul>
              </li>
            )}
            <li
              className={this.activeRoute(Constants.routes.products) + ' ' + this.activeRoute(Constants.routes.service)}
              onClick={() => {
                sideMenuCollapse ? this.handleInventoryOpen() : this.handleSideSubMenuClick('inventory');
              }}
            >
              <div className="expandMenu">
                {/* <Link to={Constants.routes.products}> */}{' '}
                <img
                  src={
                    this.activeRoute(Constants.routes.products) || this.activeRoute(Constants.routes.service)
                      ? Constants.SideMenu.sInventoryActive
                      : Constants.SideMenu.sInventory
                  }
                  alt={Constants.language.sidemenu_inventory}
                />
                <p className="menuItems">
                  <FormattedMessage id="sidemenu_inventory" />
                </p>
                {/* </Link> */}
              </div>
              <div className="expand">
                <img
                  src={Constants.SideMenu.expandMore}
                  className={this.state.inventoryOpen ? 'rotate' : ''}
                  alt="expand"
                />
              </div>
              <ul
                className={this.state.inventoryOpen ? 'sub-menu nav pl-1 collapsed in ' : 'sub-menu nav pl-1 collapse '}
              >
                <li
                  className={this.activeRoute(Constants.routes.products) + ' border-left-0'}
                  onClick={() => this.handleInventoryOpen()}
                >
                  <Link to={Constants.routes.products} className="p-0 text-left">
                    {' '}
                    <p className="p-0 pl-2 ml-1">
                      <FormattedMessage id="all_products_header" />
                    </p>
                  </Link>
                </li>
                <li
                  className={this.activeRoute(Constants.routes.service) + ' border-left-0'}
                  onClick={() => this.handleInventoryOpen()}
                >
                  <Link to={Constants.routes.service} className="p-0 text-left">
                    <p className="p-0 pl-2 ml-1">
                      <FormattedMessage id="sidemenu_subscription" />
                    </p>
                  </Link>
                </li>
              </ul>
            </li>
            {/* <li className={this.activeRoute(Constants.routes.documents)}>
              <Link to={'/' + Constants.routes.documents}>
                {' '}
                <img
                  src={
                    this.activeRoute(Constants.routes.documents)
                      ? Constants.SideMenu.sDocumentsActive
                      : Constants.SideMenu.sDocuments
                  }
                  alt={Constants.language.create_documents}
                />
                <p  className="menuItems">
                  <FormattedMessage id="create_documents" />
                </p>
              </Link>
            </li> */}
            <li className={this.activeRoute(Constants.routes.users)}>
              <Link to={Constants.routes.users}>
                {' '}
                <img
                  src={
                    this.activeRoute(Constants.routes.users)
                      ? Constants.SideMenu.sUsersActive
                      : Constants.SideMenu.sUsers
                  }
                  alt={Constants.language.sidemenu_users}
                />
                <p className="menuItems">
                  <FormattedMessage id="sidemenu_users" />
                </p>
              </Link>
            </li>

            {isSuperAdmin && (
              <li>
                <a href={Config.backEndUrl + '/admin/customer'} target="_blank">
                  {' '}
                  <img src={Constants.SideMenu.sDatabaseAdmin} alt={Constants.language.sidemenu_database_Admin} />
                  <p className="menuItems">
                    <FormattedMessage id="sidemenu_database_Admin" />
                  </p>
                </a>
              </li>
            )}
          </ul>
        </div>
        <div className="sidebar-wrapper menuWrapper">
          <ul className="nav m-0">
            <li className="">
              <div className=" expandMenu opacity-50 cursor-context">
                {' '}
                <img src={Constants.icons.userLoginIcon} alt={'User'} />
                <p className="menuItems">
                  <span className="text-truncate mw-20 font-weight-normal">{this.userName}</span>
                </p>
              </div>
            </li>
            {this.token && pathname !== '/' && pathname !== '/forgot-password' && isSuperAdmin && (
              <li
                onClick={() => {
                  sideMenuCollapse ? this.handleLanguageOpen() : this.handleSideSubMenuClick('language');
                }}
                className=""
              >
                <div className=" expandMenu">
                  {' '}
                  <img src={Constants.icons.languagesIcon} alt={'language'} />
                  <p className="menuItems">
                    <span>
                      <FormattedMessage id="sidemenu_languages" />
                    </span>
                  </p>
                  {/*</Link>*/}
                </div>
                <div className="expand">
                  <img
                    src={Constants.SideMenu.expandMore}
                    className={this.state.languageOpen ? 'rotate' : ''}
                    alt="expand"
                  />
                </div>
                <ul
                  className={
                    this.state.languageOpen ? 'sub-menu  pl-1 nav collapsed in ' : 'sub-menu  pl-1 nav collapse '
                  }
                >
                  <li
                    className={(language === 'no' ? 'active' : '') + ' border-left-0'}
                    onClick={this.setLanguage.bind(this, 'no')}
                  >
                    <p className="menuItems p-0 pl-2 ml-1">
                      <FormattedMessage id="sidemenu_languages_norwegien" />
                    </p>
                  </li>
                  <li
                    className={(language === 'en' ? 'active' : '') + ' border-left-0'}
                    onClick={this.setLanguage.bind(this, 'en')}
                  >
                    <p className="menuItems p-0 pl-2 ml-1">
                      <FormattedMessage id="sidemenu_languages_english" />
                    </p>
                  </li>
                </ul>
              </li>
            )}
            {this.token && pathname !== '/' && pathname !== '/forgot-password' && (
              <li onClick={this.logOut} className=" cursor-pointer">
                <div className=" expandMenu">
                  <img src={Constants.icons.logoutIcon} alt={'logout'} />
                  <p className="menuItems">
                    <span className="sidemenu-text">
                      <FormattedMessage id="common_logout" />
                    </span>
                  </p>
                </div>
              </li>
            )}
          </ul>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  sideMenuCollapse: state.Utils.sideMenuCollapse,
});

export default connect(
  mapStateToProps,
  { updateSideMenu }
)(withRouter(SideMenu));
